import { Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import { MACHINE_TYPE_OPTIONS } from "constants/constants";
import moment from "moment";
import { RESULT_VALUE } from "./constants";

export default function PlayerManagementRow({ item }) {
  const textColor = useColorModeValue("#000000", "white");

  const convertTime = (time) => {
    return moment(time, "YYYY-MM-DD HH:mm:ss").format("DD MMM. YYYY HH:mm:ss");
  };

  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.user_name}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.booth_mac_address}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {RESULT_VALUE[item?.result]}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {
            MACHINE_TYPE_OPTIONS.find(
              (option) => option.value === item?.booth_being_played
            )?.label
          }
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {item?.start_play_time && convertTime(item?.start_play_time)}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.success_time && convertTime(item?.success_time)}
        </Text>
      </Td>
    </Tr>
  );
}
