import { Box, Flex, Icon, Text, Image, useToast } from "@chakra-ui/react";
import { Icon2Claws } from "components/Icons/Icon2Claws";
import { Icon3Claws } from "components/Icons/Icon3Claws";
import { IconCamera } from "components/Icons/IconCamera";
import { IconXCamera } from "components/Icons/IconXCamera";
import { NAIcon } from "components/Icons/NAIcon";
import SwitchType from "components/Switch";
import { DEDICATED_TO_OPTIONS, STATUS } from "constants/constants";
import { useCallback, useMemo } from "react";
import { renderDedicated } from "views/Machine/MachineSettings/help";
import {
  updateGameMode,
} from "api/machine.api";
import { Select } from "chakra-react-select";

export default function MachineMapItem({
  item,
  index,
  machine,
  handleOpenDetail,
  handleSwitchStatus,
  gamesModeOptions,
  getListMachine,
}) {
  const toast = useToast();
  const textNA = "N/A";
  const IconStatus = useCallback(() => {
    let bg = "#90FA87";
    let bgBorder = "#0DAA0A";
    if (item?.status === STATUS.DISCONNECT) {
      bg = "#FF003D";
      bgBorder = "#C30331";
    } else if (item?.status === STATUS.NO_ACTIVE) {
      bg = "#D2D2D2";
      bgBorder = "#A9ADA9";
    }
    return (
      <Box
        bg={bg}
        w="22px"
        h="22px"
        borderRadius="100%"
        border={`3px solid ${bgBorder}`}
      />
    );
  }, [item?.status]);

  const isStatusActive = useMemo(() => {
    return item?.status === STATUS.ACTIVE;
  }, [item?.status]);

  const isStateActive = useMemo(() => {
    return machine?.status === STATUS.ACTIVE;
  }, [machine?.status]);

  const renderBgItem = useMemo(() => {
    if (item?.status === STATUS.ACTIVE) {
      if (isStateActive) {
        return {
          bg: "linear-gradient(140deg, #99E177 3.67%, #3AC33A 96.33%)",
          color: "#2D377B",
        };
      }
      return {
        bg: "linear-gradient(139.88deg, #FFE455 3.6%, #FC9012 96.4%)",
        color: "#2D377B",
      };
    }
    return {
      bg: "linear-gradient(137deg, #EDE6E6 1.63%, #C0BDBD 98.37%)",
      color: "#2D377B",
    };
  }, [item?.status, isStateActive]);

  const is3Claws = useMemo(() => {
    return machine?.machine_type === STATUS.THREE_CLAWS;
  }, [machine?.machine_type]);

  const isCameraFOK = useMemo(() => {
    return item?.status_cam_front === STATUS.OK;
  }, [item?.status_cam_front]);

  const isCameraSOK = useMemo(() => {
    return item?.status_cam_back === STATUS.OK;
  }, [item?.status_cam_back]);

  const IconClaw = useMemo(() => {
    if (machine) {
      return is3Claws ? Icon3Claws : Icon2Claws;
    }
    return NAIcon;
  }, [machine]);

  const updateMode = async (gameModeId) => {
    try {
      const { data } = await updateGameMode({
        machine_id: item?.machine?.id,
        game_mode_id: gameModeId
      });
      if (data?.success) {
        getListMachine()
        toast({
          title: "Update success.",
          description: "Update success.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Box
      w={{
        base: "147px",
        xl: "182px",
        "2xl": "190px",
      }}
      h="250px"
      borderRadius="10px"
      boxShadow="0px 4px 4px 0px #00000040"
      p="10px"
      bg={renderBgItem.bg}
      pointerEvents={isStatusActive ? "auto" : "none"}
    >
      <Flex direction="column" gap={2}>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" gap={2}>
            <IconStatus />
            <Text
              fontSize={{
                base: "10px",
                "2xl": "18px",
              }}
              fontWeight="400"
            >
              {item?.mac?.slice(-4)} - {item?.booth_id}
              {index + 1}
            </Text>
          </Flex>
          <SwitchType
            color="#285239"
            colorThumb="#2EF7F7"
            isChecked={isStatusActive ? isStateActive : false}
            onChange={handleSwitchStatus}
          />
        </Flex>
        <Flex alignItems="center" gap={2}>
          <Text
            fontWeight="700"
            fontSize="12px"
            color={renderBgItem.color}
            bg=" #FFFFFF"
            w="fit-content"
            borderRadius="5px"
            p="1px 6px"
            opacity={isStatusActive ? 1 : "0.3"}
          >
            {machine
              ? DEDICATED_TO_OPTIONS.find(
                  (item) =>
                    item?.value ===
                    renderDedicated(
                      machine?.event_available,
                      machine?.freeplay_available
                    )
                )?.label
              : textNA}
          </Text>
          <Flex direction="column" opacity={isStatusActive ? 1 : "0.3"}>
            <Text
              fontWeight="700"
              fontSize={{
                base: "10px",
                xl: "13px",
              }}
            >
              Gameplay
            </Text>
            <Text
              fontWeight="400"
              fontSize={{
                base: "10px",
                xl: "13px",
              }}
            >
              {machine ? <>{is3Claws ? "3-claw" : "2-claw"} lottery</> : textNA}
            </Text>
          </Flex>
        </Flex>

        <Flex alignItems="center" gap={10}>
          <Icon fontSize="55px" as={IconClaw} />
          <Image
            borderRadius="100%"
            src={machine?.icon_url || "https://i.ibb.co/CmKmKfp/ENISHI.png"}
            w="55px"
            h="55px"
            alt={machine?.id}
          />
        </Flex>
        <Flex alignItems="center" justifyContent="center" gap={12}>
          <Box
            position="relative"
            cursor={isStatusActive ? "pointer" : "not-allowed"}
            opacity={isStatusActive ? 1 : "0.3"}
            onClick={() => {
              if (isStatusActive) {
                handleOpenDetail(STATUS.FRONT);
              }
            }}
          >
            {!isCameraFOK && (
              <Icon
                fontSize="8px"
                position="absolute"
                top="4px"
                left="4px"
                as={IconXCamera}
              />
            )}
            <Icon fontSize="36px" as={IconCamera} />
            <Text
              color="#2D377B"
              fontWeight="700"
              fontSize="13px"
              position="absolute"
              top="5px"
              left="10px"
            >
              F
            </Text>
          </Box>
          <Box
            position="relative"
            cursor={isStatusActive ? "pointer" : "not-allowed"}
            opacity={isStatusActive ? 1 : "0.3"}
            onClick={() => {
              if (isStatusActive) {
                handleOpenDetail(STATUS.BACK);
              }
            }}
          >
            {!isCameraSOK && (
              <Icon
                fontSize="8px"
                position="absolute"
                top="4px"
                left="4px"
                as={IconXCamera}
              />
            )}
            <Icon fontSize="36px" as={IconCamera} />
            <Text
              color="#2D377B"
              fontWeight="700"
              fontSize="13px"
              position="absolute"
              top="5px"
              left="10px"
            >
              S
            </Text>
          </Box>
        </Flex>
        {item?.status === STATUS.ACTIVE && (
          <Box 
            w="100%"
          >
            <Select
              w="100%"
              defaultValue={gamesModeOptions?.filter((mode) => mode?.value === item?.machine?.game_mode_id)}
              fontSize="sm"
              onChange={(e) => {
                updateMode(e.value);
              }}
              color="#000000"
              options={!is3Claws ? gamesModeOptions?.filter(mode => mode?.machine_type === "ALL" || mode?.machine_type === "TWO_CLAWS") : gamesModeOptions?.filter(mode => mode?.machine_type === "ALL" || mode?.machine_type === "THREE_CLAWS")}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </Box>
        )}
      </Flex>
    </Box>
  );
}
