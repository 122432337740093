import { Flex, Icon, Td, Text, Tr } from "@chakra-ui/react";
import { DeleteIcon } from "components/Icons/DeleteIcon";
import { EditIcon } from "components/Icons/EditIcon";
import { capitalize } from "lodash";
import { convertStringSnake } from "utils/string";

const LevelRequiredRow = ({
  item,
  handleOpenCreateOrUpdate,
  handleOpenConfirmDelete,
}) => {
  return (
    <Tr>
      <Td>
        <Text fontSize="sm" fontWeight={400} whiteSpace="nowrap">
          {convertStringSnake(item?.level_name)}
        </Text>
      </Td>
      <Td>
        <Text fontSize="sm" fontWeight={400} whiteSpace="nowrap">
          {capitalize(item?.title_skill_rank)}
        </Text>
      </Td>
      <Td>
        <Text fontSize="sm" fontWeight={400} whiteSpace="nowrap">
          {item?.required_experience_point}
        </Text>
      </Td>

      <Td>
        <Flex gap="10px">
          <Icon
            onClick={handleOpenCreateOrUpdate}
            fontSize="30px"
            borderRadius="8px"
            p="7px"
            cursor="pointer"
            bg="#1480FF"
            as={EditIcon}
          />
          {/* <Icon
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={DeleteIcon}
            onClick={handleOpenConfirmDelete}
          /> */}
        </Flex>
      </Td>
    </Tr>
  );
};

export default LevelRequiredRow;
