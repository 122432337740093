import { background } from "@chakra-ui/system";

const Modal = {
  baseStyle: {
    body: {
      "&::-webkit-scrollbar": {
        width: "3px",
      },
      "&::-webkit-scrollbar-track": {
        width: "3px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "rgba(138, 138, 160, 1)",
        borderRadius: "100px",
      },
    },
  },
};

export const ModalComponent = {
  components: {
    Modal,
  },
};
