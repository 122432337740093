import END_POINT from './constants'
import http from './http'

export function getRevenueSharings(params) {
  return http.get(`${END_POINT.GET_REVENUE_SHARINGS}?${params}`)
}

export function postRevenueSharings(data) {
  return http.post(END_POINT.POST_REVENUE_SHARING, data)
}

export function getRevenueSharingsById(id) {
  return http.get(`${END_POINT.GET_REVENUE_SHARING_BY_ID}/${id}`)
}

export function putRevenueSharing(data) {
  return http.put(`${END_POINT.PUT_REVENUE_SHARING_BY_ID}/${data?.id}`, data)
}

export function deleteRevenueSharing(id) {
  return http.delete(`${END_POINT.DELETE_REVENUE_SHARING_BY_ID}/${id}`)
}
