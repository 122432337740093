import { Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
// import dayjs from "dayjs";

export default function FreePlayHistoryRow({ item, fontSize = "sm" }) {
  const textColor = useColorModeValue("#000000", "white");
  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {item?.email}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {item?.name}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {item?.status}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {/* {dayjs(item?.update_time * 1000).format("DD MMM YYYY HH:mm:ss")} */}
        </Text>
      </Td>
    </Tr>
  );
}
