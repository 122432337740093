/* eslint-disable no-undef */
import * as yup from 'yup'
import i18next from 'I18n'

const schemaPrize = () =>
  yup.object().shape({
    name: yup.string()
      .trim()
      .required(i18next.t('prize:name_required'))
      .max(30, i18next.t('prize:validate_name_max')),
    description: yup
      .string()
      .trim()
      .notRequired()
      .max(500, i18next.t('prize:validate_description_max_500')),
  })

export default schemaPrize
