import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import ButtonType from "components/Button";
import DeleteModal from "components/Modal/deleteModal";
import { NUMBER } from "constants/enum";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { deletePrizeAllApi, deletePrizeByIdApi } from "stores/prize/action";
import { prizeSelector } from "stores/prize/selector";

export default function ActionPrizeSettings({
  handleOpenCreateOrUpdate,
  checkedIds,
  setCheckedIds,
  setParams,
  params,
}) {
  const { t } = useTranslation("prize");
  const dispatch = useDispatch();
  const toast = useToast();
  const { statusDelete } = useSelector(prizeSelector);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (statusDelete) {
      const newParams = {
        ...params,
        page: 1,
      };
      setParams(newParams);
      setCheckedIds([]);
      onClose();
    }
  }, [statusDelete]);

  const handleDelete = async () => {
    try {
      if (checkedIds?.length) {
        if (checkedIds?.length === NUMBER.ONE) {
          await dispatch(deletePrizeByIdApi(checkedIds));
        } else {
          await dispatch(deletePrizeAllApi(checkedIds));
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex gap={4} flexWrap="wrap" alignItems="flex-end">
      <Flex alignItems="flex-end" gap={6} mb="10px">
        <ButtonType
          text="NEW"
          fontSize="12px"
          iconType="plus"
          sizeIcon="8px"
          onClick={() => {
            handleOpenCreateOrUpdate();
          }}
        />
        <ButtonType
          text="REMOVE"
          fontSize="12px"
          sizeIcon="8px"
          btnType="dark-outline"
          disabled={checkedIds?.length === 0}
          onClick={onOpen}
        />
      </Flex>
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        handleDelete={handleDelete}
        title={t("delete_prize_title")}
        content={t("content_delete")}
        btnYes="OK"
        btnNo="Cancel"
      />
    </Flex>
  );
}
