import {
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateSkillRankApi } from "api/skillRank.api";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import { OPTIONS_SKILL_RANK } from "constants/constants";
import useWithToast from "hooks/useWithToast";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { schemaSkillRank } from "./schema";

export default function SkillRankCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const { showToastSuccess } = useWithToast();

  const defaultValues = {
    mode_type: "",
    base: null,
    bonus: null,
  };

  const form = useForm({
    resolver: yupResolver(schemaSkillRank()),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = form;

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  useEffect(() => {
    if (dataDetail) {
      reset({
        mode_type: dataDetail?.mode_type,
        base: dataDetail?.base,
        bonus: dataDetail?.bonus,
      });
    } else {
      reset();
    }
  }, [dataDetail]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      if (idEdit) {
        const res = await updateSkillRankApi(idEdit, {
          id: idEdit,
          ...data,
        });
        if (res?.data?.success) {
          showToastSuccess({
            title: "Successfully edited XP reward game mode.",
          });
          handleCreateOrUpdateSuccess();
        }
      } 
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <FormSelect
              label="Mode type"
              name="mode_type"
              options={OPTIONS_SKILL_RANK}
              isHiddenOption
              defaultValue=""
            />
            <FormInput type="number" label="Base" name="base" />
            <FormInput type="number" label="Win bonus" name="bonus" />
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="685px">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit XP Reward Game Mode" : "Add Skill Rank"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          display="flex"
          alignItems="center"
          gap="16px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            {idEdit ? "Save" : "Add "}
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
