import {
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import { ErrorIcon } from "components/Icons/ErrorIcon";
import { SuccessIcon } from "components/Icons/SuccessIcon";
import { useMemo } from "react";

export default function ModalSuccessAndFail({
  isOpen,
  onClose,
  title,
  description = "You have successfully uploaded the NFT!",
  textOk = "OK",
  type = "success",
  onSubmit,
}) {
  const titleDefault = useMemo(() => {
    return type === "success" ? "Success" : "Fail";
  }, [type]);
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="32px 16px">
        <ModalHeader
          w="100%"
          textAlign="center"
          margin="auto"
          fontSize="24px"
          fontWeight="700"
        >
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap={4}
          >
            <Icon
              as={type === "success" ? SuccessIcon : ErrorIcon}
              fontSize="94px"
            />
            <Text fontSize="24px" fontWeight="700">
              {title || titleDefault}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalBody
          textAlign="center"
          fontSize="16px"
          fontWeight="500"
          color="#48484A"
        >
          {description}
        </ModalBody>
        <ModalFooter p="0" w="100%" textAlign="center" margin="auto">
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap="16px"
            w="100%"
            mt="20px"
          >
            <ButtonType mr={3} text={textOk} onClick={onSubmit} w="100%" />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
