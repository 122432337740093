import { AspectRatio, Box, Flex, Image, Text } from "@chakra-ui/react";
import ButtonType from "components/Button";
import CheckBoxType from "components/Checkbox";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import IconRemoteClaw from "../../assets/img/icon-remote-claw.png";

export default function ItemNft({
  item,
  onSell,
  isClickAble = false,
  nftsChecked,
  setNFTsChecked,
  onCancelSell,
  type = "hunter",
  isSell = false,
}) {
  const history = useHistory();
  const isChecked = useMemo(() => {
    if (isClickAble) {
      return nftsChecked?.some((itemCheck) => itemCheck?.id === item?.id);
    }
    return false;
  }, [item?.id, nftsChecked, isClickAble]);

  const handleClickCheck = () => {
    if (isClickAble) {
      if (isChecked) {
        setNFTsChecked(
          nftsChecked.filter((itemCheck) => itemCheck?.id !== item?.id)
        );
      } else {
        setNFTsChecked([...nftsChecked, item]);
      }
    } else {
      return history.push(`/admin/${type}/nft-detail/${item?.id}`);
    }
  };

  return (
    <Flex
      direction="column"
      gap={2}
      w="100%"
      maxW="100%"
      p="10px"
      borderRadius="10px"
      boxShadow="0px 20px 27px 0px #0000000D"
      bg="#FFFFFF"
      cursor="pointer"
      onClick={handleClickCheck}
      position="relative"
    >
      {isChecked && (
        <Box
          position="absolute"
          borderRadius="10px"
          top={0}
          left={0}
          w="100%"
          h="100%"
          bg="rgba(0, 0, 0, 0.6)"
        >
          <CheckBoxType isChecked position="absolute" top="10px" left="10px" />
        </Box>
      )}
      <AspectRatio w="100%" ratio={184 / 142}>
        <Image
          src={item?.nft_information?.image_url}
          alt="image"
          objectFit="contain !important"
          borderRadius="8px"
        />
      </AspectRatio>

      <Text
        fontWeight="700"
        fontSize="14px"
        maxW="100%"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {item?.nft_information?.name}
      </Text>
      <Image
        src={item?.user_model?.avatar_url || IconRemoteClaw}
        width="28px"
        height="28px"
        borderRadius="100%"
        alt="icon"
      />
      <Flex direction="column">
        <Text
          fontWeight="400"
          fontSize="12px"
          maxW="100%"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          color="#969696"
        >
          Owned By
        </Text>
        <Text
          fontWeight="700"
          fontSize="14px"
          maxW="100%"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {item?.user_model?.name}
        </Text>
      </Flex>
      {item?.product_model?.price > 0 && (
        <Flex direction="column">
          <Text
            fontWeight="400"
            fontSize="12px"
            maxW="100%"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            color="#969696"
          >
            Price
          </Text>
          <Text
            fontWeight="700"
            fontSize="14px"
            maxW="100%"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {item?.product_model?.price} BTH
          </Text>
        </Flex>
      )}

      {!isClickAble && (
        <ButtonType
          fontSize="14px"
          fontWeight="700"
          borderRadius="30px"
          h="28px"
          btnType="primary-new"
          sizeIcon="16px"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (isSell) {
              onCancelSell(item);
            } else {
              onSell(item);
            }
          }}
        >
          {isSell ? "Cancel Sell" : "Sell"}
        </ButtonType>
      )}
    </Flex>
  );
}
