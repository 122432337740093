import { containSpecialRegex } from "utils/regex";
import * as yup from "yup";

const schemaSkillRank = () =>
  yup.object().shape({
    title_skill_rank: yup
      .string()
      .trim()
      // .matches(
      //   containSpecialRegex,
      //   "Title should not contain special characters"
      // )
      .required("Required"),
    // .max(255, "Maximum limit of 255 characters."),
    rank_from: yup
      .number()
      .min(0, "Must be greater than or equal to 0")
      .nullable(true)
      .transform((_, val) => {
        if (val || val === 0) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer")
      .required("Required"),
    rank_to: yup
      .number()
      .nullable(true)
      .transform((_, val) => {
        if (val || val === 0) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .required("Required")
      .positive("Must be greater than 0"),
  });

export default schemaSkillRank;
