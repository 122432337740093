/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

const PanelContainer = {
  baseStyle: {
    padding: '30px 15px',
    minHeight: 'calc(100vh - 123px)',
  },
}

export const PanelContainerComponent = {
  components: {
    PanelContainer,
  },
}
