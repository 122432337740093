import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { deleteWhiteListEvent, getDetailWhiteList } from "api/event.api";
import ModalConfirm from "components/Modal/ModalConfirm";
import FormInput from "components/form/FormInput";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { convertParams, omitParams } from "utils/object";
import RegisterUserEvent from "../RegisterUser";
import WhiteListTable from "./table";
import ButtonType from "components/Button";

export default function WhiteListEvent({ isOpen, onClose, idDetail }) {
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [checkedIds, setCheckedIds] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [whitelist, setWhitelist] = useState([]);

  const toast = useToast();

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  const {
    isOpen: isOpenRegisterUserEvent,
    onOpen: onOpenRegisterUserEvent,
    onClose: onCloseRegisterUserEvent,
  } = useDisclosure();

  const form = useForm();
  const { handleSubmit } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const newParams = {
        ...params,
        ...data,
        page: 1,
      };
      setParams(newParams);
    }
  };

  const getWhitelist = useCallback(
    async (newParams = {}) => {
      if (idDetail) {
        try {
          setCheckedIds([]);
          setIsLoading(true);
          const requestParams = convertParams(
            omitParams({
              ...params,
              ...newParams,
            })
          );
          const { data } = await getDetailWhiteList(idDetail, requestParams);
          if (data?.success) {
            const res = data?.data;
            setWhitelist(res?.records);
            setTotalPage(res?.total_page);
          }
        } catch (error) {
          toast({
            description: error?.message || error?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        } finally {
          setIsLoading(false);
        }
      }
    },
    [params, idDetail]
  );

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(whitelist);
    } else {
      setCheckedIds([]);
    }
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const isHaveCheckedIds = useMemo(() => {
    return checkedIds?.length > 0;
  }, [checkedIds]);

  const deleteItem = (id) => {
    return deleteWhiteListEvent(id);
  };

  const handleDelete = useCallback(async () => {
    if (isHaveCheckedIds) {
      const promise = checkedIds?.map((item) => {
        return deleteItem(item?.id);
      });
      const res = await Promise.all(promise);
      if (res && res[0]?.data?.success) {
        setCheckedIds([]);
        toast({
          title: "Delete success.",
          description: "Delete success.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        const newParams = {
          ...params,
          page: 1,
        };
        setParams(newParams);
        onCloseDelete();
      }
    }
  }, [isHaveCheckedIds, checkedIds]);

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const handleAddUserSuccess = () => {
    setParams({
      ...params,
      page: 1,
    });
  };

  useEffect(() => {
    getWhitelist();
  }, [getWhitelist]);

  const renderForm = () => {
    return (
      <Flex
        gap={4}
        flexWrap="wrap"
        justifyContent="space-between"
        w="100%"
        alignItems="flex-end"
      >
        <FormProvider {...form}>
          <form
            style={{
              maxWidth: "100%",
              width: "100%",
            }}
            id="whitelist-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Flex
              w="100%"
              flexWrap="wrap"
              alignItems="flex-start"
              gap={4}
              direction="column"
            >
              <Flex gap={8}>
                <FormInput
                  w="260px"
                  name="keyword"
                  label="Search"
                  defaultValue={""}
                  placeholder="Search by keywords"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(onSubmit)();
                    }
                  }}
                />
              </Flex>
              <Flex
                mr={4}
                alignItems="center"
                gap={6}
                mb="10px"
                alignSelf="flex-end"
              >
                <ButtonType
                  text="NEW"
                  fontSize="12px"
                  iconType="plus"
                  sizeIcon="8px"
                  onClick={() => {
                    onOpenRegisterUserEvent();
                  }}
                />

                <ButtonType
                  text="REMOVE"
                  fontSize="12px"
                  sizeIcon="8px"
                  btnType="dark-outline"
                  disabled={!isHaveCheckedIds}
                  onClick={onOpenDelete}
                />
              </Flex>
              <WhiteListTable
                data={whitelist}
                isLoading={isLoading}
                totalPage={totalPage}
                limit={params.limit}
                page={params?.page}
                checkedIds={checkedIds}
                setCheckedIds={setCheckedIds}
                onChangeChecked={onChangeChecked}
                onChangeLimit={onChangeLimit}
                handleChangePage={handleChangePage}
              />
            </Flex>
          </form>
        </FormProvider>
      </Flex>
    );
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="1339px">
          <ModalHeader
            borderBottom="1px solid #EEEFF2"
            w="100%"
            textAlign="center"
            margin="auto"
            color="#000000"
          >{`WHITE LIST`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="24px 120px">{renderForm()}</ModalBody>
          <ModalFooter
            borderTop="1px solid #EEEFF2"
            w="100%"
            textAlign="center"
            margin="auto"
          ></ModalFooter>
        </ModalContent>
      </Modal>
      <ModalConfirm
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        onSubmit={handleDelete}
      />
      {isOpenRegisterUserEvent && (
        <RegisterUserEvent
          isOpen={isOpenRegisterUserEvent}
          onClose={onCloseRegisterUserEvent}
          idDetail={idDetail}
          handleAddUserSuccess={handleAddUserSuccess}
        />
      )}
    </>
  );
}
