// import NoDataSvg from 'assets/svg/no-data.jpg'

const NoResultsTableRow = ({ numColumns = 1, loadingPage = false }) => {
  return <tr>
      <td
        colSpan={numColumns}
        style={{ padding: '100px 20px', textAlign: 'center', verticalAlign: 'middle' }}
      >
        {loadingPage ? '' : (
          ''
          // <img
          //   src={NoDataSvg}
          //   alt='No data'
          //   style={{
          //     width: 50,
          //     height: 50,
          //     display: 'inline-block'
          //   }}
          // />
        )}
      </td>
  </tr>
}

export default NoResultsTableRow