import END_POINT from "./constants";
import http from "./http";

export function getRoles() {
  return http.get(`${END_POINT.ROLE}`);
}

export function updateRoles(data) {
  return http.put(`${END_POINT.ROLE}/${data?.id}`, data);
}
