/* eslint-disable no-useless-catch */
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_REVENUE_SHARING_FETCH,
  DELETE_REVENUE_SHARING_BY_ID_FETCH,
  EDIT_REVENUE_SHARING_BY_ID_FETCH,
  GET_REVENUE_SHARING_BY_ID_FETCH,
  GET_REVENUE_SHARING_FETCH,
  REVENUE_SHARING_NAMESPACE,
} from "../constant";
import { createStandaloneToast } from "@chakra-ui/react";
import { convertParams } from "utils/object";
import {
  deleteRevenueSharing,
  getRevenueSharings,
  getRevenueSharingsById,
  postRevenueSharings,
  putRevenueSharing,
} from "api/revenueSharing.api";

const toast = createStandaloneToast();

export const getRevenueSharingApi = createAsyncThunk(
  `${REVENUE_SHARING_NAMESPACE}/${GET_REVENUE_SHARING_FETCH}`,
  async (request) => {
    try {
      const params = convertParams(request);
      const res = await getRevenueSharings(params);
      if (res?.data?.code === 200) {
        return res?.data?.data;
      } else {
        console.log("res ==========> ", res);
        if (res?.data?.message || res?.data?.messages[0]) {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      // if (error?.message || error?.messages[0]) {
      //   toast({
      //     description: error?.message || error?.messages[0],
      //     status: 'error',
      //     position: 'bottom-right',
      //   })
      // }
      throw error;
    }
  }
);

export const addRevenueSharingApi = createAsyncThunk(
  `${REVENUE_SHARING_NAMESPACE}/${ADD_REVENUE_SHARING_FETCH}`,
  async (data) => {
    try {
      const res = await postRevenueSharings(data);
      if (res?.status === 200) {
        toast({
          description: "Add revenue sharing successful.",
          status: "success",
          position: "bottom-right",
        });
        return res?.data;
      } else {
        console.log("res ==========> ", res);
        if (res?.data?.message || res?.data?.messages[0]) {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      // if (error?.message || error?.messages[0]) {
      //   toast({
      //     description: error?.message || error?.messages[0],
      //     status: 'error',
      //     position: 'bottom-right',
      //   })
      // }
      throw error;
    }
  }
);

export const getRevenueSharingByIdApi = createAsyncThunk(
  `${REVENUE_SHARING_NAMESPACE}/${GET_REVENUE_SHARING_BY_ID_FETCH}`,
  async (params) => {
    try {
      const res = await getRevenueSharingsById(params);
      if (res?.status === 200) {
        return res?.data;
      } else {
        console.log("res ==========> ", res);
        if (res?.data?.message || res?.data?.messages[0]) {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      // if (error?.message || error?.messages[0]) {
      //   toast({
      //     description: error?.message || error?.messages[0],
      //     status: 'error',
      //     position: 'bottom-right',
      //   })
      // }
      throw error;
    }
  }
);

export const editRevenueSharingByIdApi = createAsyncThunk(
  `${REVENUE_SHARING_NAMESPACE}/${EDIT_REVENUE_SHARING_BY_ID_FETCH}`,
  async (data) => {
    try {
      const res = await putRevenueSharing(data);
      if (res?.status === 200) {
        toast({
          description: "Update revenue sharing successful.",
          status: "success",
          position: "bottom-right",
        });
        return res?.data;
      } else {
        console.log("res ==========> ", res);
        if (res?.data?.message || res?.data?.messages[0]) {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      // if (error?.message || error?.messages[0]) {
      //   toast({
      //     description: error?.message || error?.messages[0],
      //     status: 'error',
      //     position: 'bottom-right',
      //   })
      // }
      throw error;
    }
  }
);

export const deleteRevenueSharingByIdApi = createAsyncThunk(
  `${REVENUE_SHARING_NAMESPACE}/${DELETE_REVENUE_SHARING_BY_ID_FETCH}`,
  async (params) => {
    try {
      const promise = params?.map(async (param) => {
        return deleteRevenueSharing(param);
      });

      const res = await Promise.all(promise);
      if (res && res[0]?.data?.success) {
        toast({
          description: `Delete revenue sharing successful.`,
          status: "success",
          position: "bottom-right",
        });
      }

      return true;
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
      throw error;
    }
  }
);
