import { Button } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import FreePlayHistoryRow from "./row";

export default function FreePlayHistoryTable({
  isLoading,
  isShowPagination = true,
  isShowBtnShowFull = false,
  fontSize = "sm",
  dataRow = [],
  ...rest
}) {
  const labels = ["Play Name", "Play Type", "Mode", "Obtained on"];

  return (
    <Card>
      <CardBody
        overflowX={{ sm: "scroll", md: "hidden" }}
        maxW="80%"
        m="auto"
        minH="200px"
        {...rest}
      >
        <TableCustom
          labels={labels}
          isLoading={isLoading}
          isCheck={false}
          dataRow={dataRow}
        >
          <>
            {dataRow?.map((item) => {
              return (
                <FreePlayHistoryRow
                  key={item?.id}
                  item={item}
                  fontSize={fontSize}
                />
              );
            })}
          </>
        </TableCustom>
      </CardBody>
      {isShowPagination && (
        <Paginate
          page={1}
          pageCount={2}
          limit={10}
          //   onChangeLimit={onChangeLimit}
          //   handleChangePage={handleChangePage}
        />
      )}
      {isShowBtnShowFull && (
        <Button
          mt={4}
          alignSelf="flex-end"
          w="117px"
          h="26px"
          p="7px 21px"
          fontWeight="400"
          fontSize="12px"
          borderRadius="3px"
          cursor="pointer"
          backgroundColor="#EDEEFC"
        >
          Show full history
        </Button>
      )}
    </Card>
  );
}
