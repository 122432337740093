import { Flex } from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import { FIRST_PAGE } from "constants/constants";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function ConditionRevenueSharing({ setParams, params }) {
  const { t } = useTranslation("revenueSharing");

  const form = useForm();
  const { handleSubmit } = form;

  const onSubmit = (data) => {
    const newParams = {
      ...params,
      ...data,
      page: FIRST_PAGE,
    };
    setParams(newParams);
  };

  return (
    <Flex gap={4} flexWrap="wrap" w="100%" alignItems="flex-end">
      <FormProvider {...form}>
        <form
          style={{
            maxWidth: "100%",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex w="100%" flexWrap="wrap" alignItems="flex-end" gap={4}>
            <FormInput
              w="270px"
              maxW="100%"
              name="keyword"
              label={t("search")}
              placeholder={t("placeholder_search")}
            />
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
}
