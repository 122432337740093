export const RESULT_OPTION = [
  {
    label: "Win",
    value: "WIN",
  },
  {
    label: "Lose",
    value: "LOSE",
  },
];

export const RESULT_VALUE = {
  WIN: "Win",
  LOSE: "Lose",
};
