/* eslint-disable no-undef */
import * as yup from 'yup'
import i18next from 'I18n'

const schemaFee = () =>
  yup.object().shape({
    value: yup.string()
      .trim()
      .required(i18next.t('playFee:value_required')),
      // .max(30, i18next.t('playFee:validate_name_max')),
    cp_exchange_rate: yup
      .string()
      .trim()
      .required(i18next.t('playFee:exchange_rate_required')),
  })

export default schemaFee
