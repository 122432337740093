import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { addUserIntoWhiteList, getUserWhiteListEvent } from "api/event.api";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import RegisterUserEventTable from "./table";

export default function RegisterUserEvent({
  isOpen,
  onClose,
  idDetail,
  handleAddUserSuccess,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [checkedIds, setCheckedIds] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [users, setUsers] = useState([]);
  const toast = useToast();

  const form = useForm();
  const { handleSubmit } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      setCheckedIds([]);
      const newParams = {
        ...params,
        ...data,
        page: 1,
      };
      setParams(newParams);
    }
  };

  const getUserWhitelist = useCallback(
    async (newParams = {}) => {
      if (idDetail) {
        try {
          setIsLoading(true);
          const requestParams = {
            ...params,
            ...newParams,
          };
          const { data } = await getUserWhiteListEvent(idDetail, requestParams);
          if (data?.success) {
            const res = data?.data;
            setUsers(res?.records);
            setTotalPage(res?.total_page);
          }
        } catch (error) {
          toast({
            description: error?.message || error?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        } finally {
          setIsLoading(false);
        }
      }
    },
    [params, idDetail]
  );

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(users);
    } else {
      setCheckedIds([]);
    }
  };

  const onChangeLimit = (limit) => {
    setCheckedIds([]);
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const handleChangePage = (page) => {
    setCheckedIds([]);
    setParams({
      ...params,
      page,
    });
  };

  useEffect(() => {
    getUserWhitelist();
  }, [getUserWhitelist]);

  const isHaveCheckedIds = useMemo(() => {
    return checkedIds?.length > 0;
  }, [checkedIds]);

  const addUser = (item) => {
    const data = {
      event_id: idDetail,
      email: item?.email,
      wallet: item?.wallet,
    };
    return addUserIntoWhiteList(data);
  };

  const handleAddUser = useCallback(async () => {
    try {
      if (isHaveCheckedIds) {
        const promise = checkedIds?.map((item) => {
          return addUser(item);
        });
        const res = await Promise.all(promise);
        if (res && res[0]?.data?.success) {
          setCheckedIds([]);
          toast({
            title: "Delete success.",
            description: "Delete success.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleAddUserSuccess();
          onClose();
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }, [isHaveCheckedIds, checkedIds]);

  const renderForm = () => {
    return (
      <Flex
        gap={4}
        flexWrap="wrap"
        justifyContent="space-between"
        w="100%"
        alignItems="flex-end"
      >
        <FormProvider {...form}>
          <form
            style={{
              maxWidth: "100%",
              width: "100%",
            }}
            id="register-user-event-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Flex
              w="100%"
              flexWrap="wrap"
              alignItems="flex-start"
              gap={4}
              direction="column"
            >
              <Flex gap={8}>
                <FormInput
                  w="260px"
                  name="keyword"
                  label="Search"
                  defaultValue={""}
                  placeholder="Search by keywords"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(onSubmit)();
                    }
                  }}
                />
              </Flex>
              <RegisterUserEventTable
                data={users}
                isLoading={isLoading}
                totalPage={totalPage}
                limit={params.limit}
                page={params?.page}
                checkedIds={checkedIds}
                setCheckedIds={setCheckedIds}
                onChangeChecked={onChangeChecked}
                onChangeLimit={onChangeLimit}
                handleChangePage={handleChangePage}
              />
            </Flex>
          </form>
        </FormProvider>
      </Flex>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="980px">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color="#000000"
        >{`REGISTERED USERS`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px 45px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        >
          <ButtonType
            mt={4}
            colorScheme="teal"
            m="auto"
            w="159px"
            h="46px"
            borderRadius="5px"
            onClick={handleAddUser}
            text="ADD"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
