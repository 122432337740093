/* eslint-disable no-fallthrough */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import FormTextAria from "components/form/FormTextAria";
import { FormProvider, useForm } from "react-hook-form";
import schemaPrize from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo, useState } from "react";
import { FormFileUpload } from "components/form/FormFileUpload";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { checkMaxImage } from "utils/uploadFile";
import {
  addProjectCategoryApi,
  editProjectCategoryByIdApi,
} from "stores/projectCategory/action";
import { postUploadImage } from "api/prize.api";
import { noSpecialCharRegex } from "utils/regex";
import { removeEmojis } from "utils/string";
import { NUMBER } from "constants/enum";
import ButtonType from "components/Button";

export default function ProjectCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
  handleViewClose,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const { t } = useTranslation("projectCategory");
  const toast = useToast();
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);
  const [nameImage, setNameImage] = useState("");

  const form = useForm({
    resolver: yupResolver(schemaPrize()),
    defaultValues: dataDetail,
  });

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
  } = form;

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const handleClose = () => {
    reset();
    handleViewClose();
    onClose();
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const bannerImage = selectedImage ? selectedImage : data?.banner_image;
    if (idEdit) {
      const request = {
        ...data,
        banner_image: bannerImage,
      };
      await dispatch(editProjectCategoryByIdApi({ ...request }));
    } else {
      const request = {
        ...data,
        banner_image: bannerImage,
      };
      await dispatch(addProjectCategoryApi({ ...request }));
    }
    reset();
    handleCreateOrUpdateSuccess();
  };

  const handleImageChange = async (event) => {
    const image = event.target.files[0];
    await uploadImageToServe(image);
  };

  const handleDragImage = async (file) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: t("image_greater_one"),
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    const image = file && file[0];

    await uploadImageToServe(image);
  };

  const uploadImageToServe = async (image) => {
    if (image && !image.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      toast({
        description: t("image_no_format"),
        status: "error",
        position: "bottom-right",
      });
      return false;
    } else {
      if (checkMaxImage(image)) {
        toast({
          description: t("image_size_max"),
          status: "error",
          position: "bottom-right",
        });
        return false;
      }
    }
    try {
      const request = new FormData();
      request.append("file", image);
      request.append("name", image?.name || t("label_image"));
      const { data } = await postUploadImage(request);
      if (data?.code === 200) {
        setNameImage(() => image?.name);
        return setSelectedImage(data?.data?.url);
      }
      return;
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleChangeName = (e) => {
    let result = "";
    const str = removeEmojis(e.target.value);
    str?.split("")?.map((char) => {
      if (!noSpecialCharRegex.test(char)) {
        return;
      }
      return (result += char);
    });

    setValue("name", result);
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={6} mb={6}>
            <GridItem colSpan={1}>
              <Grid templateRows="repeat(2, 1fr)">
                <FormInput
                  label="Name"
                  name="name"
                  maxLength={30}
                  isRequired
                  onKeyPress={(event) => {
                    if (!noSpecialCharRegex.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    setValue("name", e.target.value);
                    handleChangeName(e);
                  }}
                />

                <FormFileUpload
                  label={t("label_background")}
                  name="banner_image"
                  accept={"image/*"}
                  textButton={nameImage}
                  textDescription={t("note_upload_image")}
                  onChange={(e) => handleImageChange(e)}
                  handleDragImage={(val) => handleDragImage(val)}
                />
              </Grid>
            </GridItem>
            <GridItem colSpan={1}>
              <FormTextAria
                label={t("label_description")}
                name="description"
                maxLength={500}
                rows={7}
                h="150px"
              />
            </GridItem>
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="900px" overflowY="auto">
        <ModalHeader margin="auto" color={textColor}>
          {idEdit ? t("edit_project_title") : t("create_project_title")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody minW="500px">{renderForm()}</ModalBody>
        <ModalFooter margin="auto">
          <ButtonType
            mt={4}
            colorScheme="teal"
            type="submit"
            w="120px"
            h="46px"
            form="machine-form"
            isLoading={isSubmitting}
            onClick={() => {
              handleSubmit(onSubmit);
            }}
          >
            {t("btn_submit")}
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
