export const CopyIcon = (props) => (
  <svg
    width={13}
    height={16}
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="2.9375"
      y="0.5"
      width="9.5625"
      height={12}
      rx="1.5"
      stroke="#EEEFF2"
    />
    <rect
      x="0.5"
      y="2.9375"
      width="9.5625"
      height={12}
      rx="1.5"
      fill="white"
      stroke="#EEEFF2"
    />
  </svg>
);
