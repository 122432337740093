import React from 'react'
import { Box, Flex, Image } from '@chakra-ui/react';
import { graphic } from 'echarts';
import ReactECharts from 'echarts-for-react'
import Silver from 'assets/img/card/silver.png'
import Gold from 'assets/img/card/gold.png'
import Platinum from 'assets/img/card/platinum.png'
import Diamond from 'assets/img/card/diamond.png'
import Iron from 'assets/img/card/iron.png'

export default function ChartInfoNft() {
  const option = {
    title: {
      text: ""
    },
    tooltip: {
      trigger: 'axis'
    },
    // legend: {
    //   display: false,
    //   data: [{
    //     name: '주의',
    //     inactiveColor: '#ccc',
    //     itemStyle: {
    //       color: '#ccc',
    //     }
    //   },
    //   {
    //     name: '경계',
    //   }
    // ]
    // },
    radar: {
      // shape: 'circle',
      indicator: [
        { name: 'Treasure' },
        { name: 'Prize' },
        { name: 'Arena' },
        { name: 'Merchant' },
        { name: 'Mechanic' },
      ],
      axisLine: {
        lineStyle: {
          color: '#fff'
        }
      },
      splitArea: {
        show: false
      },
      axisName: {
        trigger: 'item',
        formatter: function (value, indicator) {
          return `${value}: 3`
        }
      },
      center: ['50%', '55%'],
      radius: ["0%", "50%"],
      splitNumber: 3,
      nameGap: 34,
    },
    series: [
      {
        type: 'radar',
        data: [
          {
            value: [3, 3, 2, 3, 1],
            name: '',
            symbol: 'none',
            showSymbol: false,
            tooltip: {
              trigger: 'item'
            },
            areaStyle: {
              color: new graphic.RadialGradient(0.1, 0.6, 1, [
                {
                  color: 'rgba(255, 121, 94, 0.5)',
                  offset: 0
                },
                {
                  color: 'rgba(239, 120, 41, 0.9)',

                  offset: 1
                }
              ])
            },
            lineStyle: {color: '#FF7B24'}
          },
        ]
      }
    ],
  };
 
  return (
    <Box
      border="2px solid #000000"
      background="#212B43"
      borderRadius="26px"
      w="400px"
      h="300px"
      display="flex"
      justifyContent="center"
      padding="24px"
    >
      <Box w="100%" h="100%" position="relative">
        <ReactECharts style={{ height: '250px', width: "100%" }} option={option} />
        <Box
          position="absolute"
          top="-10px"
          left="50%"
          transform="translateX(-50%)"
        >
          <Image w="40px" src={Silver} alt="icon"/>
        </Box>
        <Box
          position="absolute"
          top="60px"
          right="30px"
        >
          <Image w="40px" src={Gold} alt="icon"/>
        </Box>
        <Box
          position="absolute"
          top="60px"
          left="60px"
          transform="translateX(-50%)"
        >
          <Image w="40px" src={Iron} alt="icon"/>
        </Box>
        <Box
          position="absolute"
          bottom="45px"
          left="95px"
          transform="translateX(-50%)"
        >
          <Image w="40px" src={Diamond} alt="icon"/>
        </Box>
        <Box
          position="absolute"
          bottom="45px"
          right="40px"
          transform="translateX(-50%)"
        >
          <Image w="40px" src={Platinum} alt="icon"/>
        </Box>
      </Box>
    </Box>
  )
}
