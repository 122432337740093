import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getWalletsApi = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.WALLET}?${params}`);
};

export const createWalletApi = (data) => {
  return http.post(`${END_POINT.WALLET}`, data);
};
