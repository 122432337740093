export const GauntletIcon = (props) => (
  <svg
    width={14}
    height={21}
    viewBox="0 0 14 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 0.5C6.54695 0.5 6.17969 0.867266 6.17969 1.32031V2.14062H7.82031V1.32031C7.82031 0.867266 7.45305 0.5 7 0.5ZM6.17969 3.3125H7.82031V8.11719H6.17969V3.3125ZM12.625 4.01547C12.172 4.01547 11.8047 4.38273 11.8047 4.83578V5.65609H13.4453V4.83578C13.4453 4.38273 13.078 4.01547 12.625 4.01547ZM11.8047 6.82812H13.4453V8.11719H11.8047V6.82812ZM8.99219 4.48438H10.6328V8.11719H8.99219V4.48438ZM9.8125 1.67172C9.35945 1.67172 8.99219 2.03898 8.99219 2.49203V3.31234H10.6328V2.49203C10.6328 2.03898 10.2655 1.67172 9.8125 1.67172ZM4.1875 1.67172C3.73445 1.67172 3.36719 2.03898 3.36719 2.49203V3.31234H5.00781V2.49203C5.00781 2.03898 4.64055 1.67172 4.1875 1.67172ZM3.36719 4.48438H5.00781V8.11719H3.36719V4.48438ZM7 18.4688L1.72656 16.5343V18.5655L7 20.5L12.2734 18.5655V16.5343L7 18.4688Z"
      fill="#48484A"
    />
    <path
      d="M7 0.5C6.54695 0.5 6.17969 0.867266 6.17969 1.32031V2.14062H7.82031V1.32031C7.82031 0.867266 7.45305 0.5 7 0.5ZM6.17969 3.3125H7.82031V8.11719H6.17969V3.3125ZM12.625 4.01547C12.172 4.01547 11.8047 4.38273 11.8047 4.83578V5.65609H13.4453V4.83578C13.4453 4.38273 13.078 4.01547 12.625 4.01547ZM11.8047 6.82812H13.4453V8.11719H11.8047V6.82812ZM8.99219 4.48438H10.6328V8.11719H8.99219V4.48438ZM9.8125 1.67172C9.35945 1.67172 8.99219 2.03898 8.99219 2.49203V3.31234H10.6328V2.49203C10.6328 2.03898 10.2655 1.67172 9.8125 1.67172ZM4.1875 1.67172C3.73445 1.67172 3.36719 2.03898 3.36719 2.49203V3.31234H5.00781V2.49203C5.00781 2.03898 4.64055 1.67172 4.1875 1.67172ZM3.36719 4.48438H5.00781V8.11719H3.36719V4.48438ZM7 18.4688L1.72656 16.5343V18.5655L7 20.5L12.2734 18.5655V16.5343L7 18.4688Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M7 0.5C6.54695 0.5 6.17969 0.867266 6.17969 1.32031V2.14062H7.82031V1.32031C7.82031 0.867266 7.45305 0.5 7 0.5ZM6.17969 3.3125H7.82031V8.11719H6.17969V3.3125ZM12.625 4.01547C12.172 4.01547 11.8047 4.38273 11.8047 4.83578V5.65609H13.4453V4.83578C13.4453 4.38273 13.078 4.01547 12.625 4.01547ZM11.8047 6.82812H13.4453V8.11719H11.8047V6.82812ZM8.99219 4.48438H10.6328V8.11719H8.99219V4.48438ZM9.8125 1.67172C9.35945 1.67172 8.99219 2.03898 8.99219 2.49203V3.31234H10.6328V2.49203C10.6328 2.03898 10.2655 1.67172 9.8125 1.67172ZM4.1875 1.67172C3.73445 1.67172 3.36719 2.03898 3.36719 2.49203V3.31234H5.00781V2.49203C5.00781 2.03898 4.64055 1.67172 4.1875 1.67172ZM3.36719 4.48438H5.00781V8.11719H3.36719V4.48438ZM7 18.4688L1.72656 16.5343V18.5655L7 20.5L12.2734 18.5655V16.5343L7 18.4688Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M3.36719 9.28882V11.6326H0.554688C0.554688 12.9813 0.969258 14.2331 1.6775 15.268L7 17.2205L12.3225 15.268C13.0307 14.2331 13.4453 12.9813 13.4453 11.6326V9.28882H3.36719ZM6.17969 11.6326H5.00781V10.4607H6.17969V11.6326ZM8.99219 11.6326H7.82031V10.4607H8.99219V11.6326ZM11.8047 11.6326H10.6328V10.4607H11.8047V11.6326ZM1.375 5.57788C0.921953 5.57788 0.554688 5.94515 0.554688 6.39819V7.21851H2.19531V6.39819C2.19531 5.94515 1.82805 5.57788 1.375 5.57788Z"
      fill="#48484A"
    />
    <path
      d="M3.36719 9.28882V11.6326H0.554688C0.554688 12.9813 0.969258 14.2331 1.6775 15.268L7 17.2205L12.3225 15.268C13.0307 14.2331 13.4453 12.9813 13.4453 11.6326V9.28882H3.36719ZM6.17969 11.6326H5.00781V10.4607H6.17969V11.6326ZM8.99219 11.6326H7.82031V10.4607H8.99219V11.6326ZM11.8047 11.6326H10.6328V10.4607H11.8047V11.6326ZM1.375 5.57788C0.921953 5.57788 0.554688 5.94515 0.554688 6.39819V7.21851H2.19531V6.39819C2.19531 5.94515 1.82805 5.57788 1.375 5.57788Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M3.36719 9.28882V11.6326H0.554688C0.554688 12.9813 0.969258 14.2331 1.6775 15.268L7 17.2205L12.3225 15.268C13.0307 14.2331 13.4453 12.9813 13.4453 11.6326V9.28882H3.36719ZM6.17969 11.6326H5.00781V10.4607H6.17969V11.6326ZM8.99219 11.6326H7.82031V10.4607H8.99219V11.6326ZM11.8047 11.6326H10.6328V10.4607H11.8047V11.6326ZM1.375 5.57788C0.921953 5.57788 0.554688 5.94515 0.554688 6.39819V7.21851H2.19531V6.39819C2.19531 5.94515 1.82805 5.57788 1.375 5.57788Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M0.554688 8.39062H2.19531V10.4609H0.554688V8.39062Z"
      fill="#48484A"
    />
    <path
      d="M0.554688 8.39062H2.19531V10.4609H0.554688V8.39062Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M0.554688 8.39062H2.19531V10.4609H0.554688V8.39062Z"
      fill="black"
      fillOpacity="0.2"
    />
  </svg>
);
