import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getLevelRequiredApi = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.LEVEL_REQUIRED}?${params}`);
};

export const updateLevelRequiredApi = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.LEVEL_REQUIRED}/${idEdit}`, data, option);
};
