import { Flex, Icon, useColorModeValue } from "@chakra-ui/react";
import FormLabelComponent from "components/form/FormLabel";
import { MdOutlineAdd } from "react-icons/md";
import BoxFinalPrize from "./BoxFinalPrize";

export default function FinalPrizeList({
  appendFinalPrize,
  defaultDataFinalPrize,
  fieldFinalPrizes,
  removeFinalPrize,
  defaultDataReward,
  tickets,
  watch,
  transLabel,
  disableWhenJP,
  control,
}) {
  const textColor = useColorModeValue("#000000", "white");
  return (
    <Flex direction="column">
      <Flex>
        <FormLabelComponent
          mb="22px"
          color={textColor}
          title={transLabel("finalPrize")}
        />
        <Icon
          fontSize="26px"
          cursor={disableWhenJP ? "not-allowed" : "pointer"}
          onClick={() => {
            if (!disableWhenJP) {
              appendFinalPrize(defaultDataFinalPrize);
            }
          }}
          color={textColor}
          key={"add"}
          as={MdOutlineAdd}
        />
      </Flex>
      <Flex direction="column" gap={4}>
        {fieldFinalPrizes?.map((item, index) => {
          return (
            <BoxFinalPrize
              transLabel={transLabel}
              disableWhenJP={disableWhenJP}
              key={item?.id}
              control={control}
              indexItem={index}
              watch={watch}
              removeFinalPrize={removeFinalPrize}
              defaultDataReward={defaultDataReward}
              tickets={tickets}
            />
          );
        })}
      </Flex>
    </Flex>
  );
}
