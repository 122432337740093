export const EditIcon = (props) => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.83997 4.23332L3.36664 10.0266C3.15997 10.2466 2.95997 10.68 2.91997 10.98L2.67331 13.14C2.58664 13.92 3.14664 14.4533 3.91997 14.32L6.06664 13.9533C6.36664 13.9 6.78664 13.68 6.9933 13.4533L12.4666 7.65998C13.4133 6.65998 13.84 5.51998 12.3666 4.12665C10.9 2.74665 9.78664 3.23331 8.83997 4.23332Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.92667 5.19995C8.21333 7.03995 9.70667 8.44662 11.56 8.63328"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
