import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getUsersManagement, updateUser } from "api/userManagement";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import UserManagementRow from "components/Tables/user/userRow";
import { useCallback, useEffect, useState } from "react";
import ConditionUser from "./condition";
// import CreateUser from "./components/createUser";
import UserDetailComponent from "./components/detail";
import EditUser from "./components/editUser";
import FreePlayHistory from "./components/freePlay/freePlayHistory";
import HistoryPlayGame from "./components/historyPlay";
import PrizeHistory from "./components/prize/prizeHistory";
import ActionUser from "./action";

export default function UserManagement() {
  const labels = [
    "Email",
    "Wallet",
    "Name",
    "Status",
    "Level",
    "CP",
    "AP",
    "Skill rank",
    "Title",
    "Referrals",
    "Last Modified At",
    "Last Modified By",
    "Created At",
    "Created By",
    "",
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [userDetail, setUserDetail] = useState(null);
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();

  const {
    isOpen: isOpenDetailUser,
    onOpen: onOpenDetailUser,
    onClose: onCloseDetailUser,
  } = useDisclosure();

  // const {
  //   isOpen: isOpenCreateUser,
  //   onOpen: onOpenCreateUser,
  //   onClose: onCloseCreateUser,
  // } = useDisclosure();

  const {
    isOpen: isOpenEditUser,
    onOpen: onOpenEditUser,
    onClose: onCloseEditUser,
  } = useDisclosure();

  const {
    isOpen: isOpenPrizeHistory,
    onOpen: onOpenPrizeHistory,
    onClose: onClosePrizeHistory,
  } = useDisclosure();

  const {
    isOpen: isOpenHistoryPlay,
    onOpen: onOpenHistoryPlay,
    onClose: onCloseHistoryPlay,
  } = useDisclosure();

  const {
    isOpen: isOpenFreePlayHistory,
    onOpen: onOpenFreePlayHistory,
    onClose: onCloseFreePlayHistory,
  } = useDisclosure();

  const getUsers = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        setCheckedIds([]);
        const { data } = await getUsersManagement({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setUsers(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  // detail user modal

  const handleOpenDetailUserModal = (item) => {
    setUserDetail(item);
    onOpenDetailUser(true);
  };

  const handleCloseDetailUserModal = () => {
    setUserDetail(null);
    onCloseDetailUser();
  };

  // create modal

  // const handleOpenCreateModal = () => {
  //   onOpenCreateUser(true);
  // };

  // edit modal

  const handleOpenEditModal = (item) => {
    setUserDetail(item);
    onOpenEditUser(true);
  };

  const handleCloseEditModal = () => {
    setUserDetail(null);
    onCloseEditUser();
  };

  const handleUpdateSuccess = useCallback(() => {
    handleCloseEditModal();
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);
  //  history play game
  const handleOpenHistoryPlayGame = (item) => {
    setUserDetail(item);
    onOpenHistoryPlay(true);
  };

  const handleCloseHistoryPlayGame = () => {
    setUserDetail(null);
    onCloseHistoryPlay();
  };

  // prize history
  const handleOpenPrizeHistoryModal = (item) => {
    setUserDetail(item);
    onOpenPrizeHistory(true);
  };

  const handleClosePrizeHistoryModal = () => {
    setUserDetail(null);
    onClosePrizeHistory();
  };

  // free play history
  const handleOpenFreePlayHistoryModal = (item) => {
    setUserDetail(item);
    onOpenFreePlayHistory(true);
  };

  const handleCloseFreePlayHistoryModal = () => {
    setUserDetail(null);
    onCloseFreePlayHistory();
  };

  // ban and unban user
  const banAndUnbanUser = (user, statusUpdate) => {
    const userStatusChange = {
      status: statusUpdate,
    };
    return updateUser(user?.id, userStatusChange);
  };

  const handleBanAndUnBanListUser = useCallback(
    async (statusUpdate) => {
      try {
        setIsLoading(true);
        const promise = checkedIds?.map((item) => {
          return banAndUnbanUser(item, statusUpdate);
        });
        const res = await Promise.all(promise);
        if (res && res[0]?.data?.success) {
          getUsers();
          setCheckedIds([]);
          toast({
            title: "Update success.",
            description: "Update success.",
            status: "success",
            duration: 3000,
            position: "top",
          });
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    },
    [checkedIds]
  );

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(users);
    } else {
      setCheckedIds([]);
    }
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex
            w="100%"
            gap={4}
            direction={{
              base: "column",
              md: "row",
            }}
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignItems="flex-start"
          >
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              User Management
            </Text>
            <ActionUser
              checkedIds={checkedIds}
              handleBanAndUnBanListUser={handleBanAndUnBanListUser}
            />
          </Flex>
        </CardHeader>
        <CardHeader px="42px" mb="32px">
          <ConditionUser
            isLoading={isLoading}
            setParams={setParams}
            params={params}
          />
        </CardHeader>
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck
            isChecked={users?.length > 0 && checkedIds.length === users?.length}
            onChangeChecked={onChangeChecked}
            dataRow={users}
          >
            <>
              {users?.map((item) => {
                return (
                  <UserManagementRow
                    key={item?.id}
                    setCheckedIds={setCheckedIds}
                    checkedIds={checkedIds}
                    handleOpenEditModal={handleOpenEditModal}
                    handleOpenHistoryPlayGame={handleOpenHistoryPlayGame}
                    handleOpenPrizeHistoryModal={handleOpenPrizeHistoryModal}
                    handleOpenFreePlayHistoryModal={
                      handleOpenFreePlayHistoryModal
                    }
                    handleOpenDetailUserModal={handleOpenDetailUserModal}
                    item={item}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
      {isOpenDetailUser && (
        <UserDetailComponent
          user={userDetail}
          isOpen={isOpenDetailUser}
          onClose={handleCloseDetailUserModal}
        />
      )}
      {isOpenEditUser && (
        <EditUser
          user={userDetail}
          isOpen={isOpenEditUser}
          onClose={handleCloseEditModal}
          handleUpdateSuccess={handleUpdateSuccess}
        />
      )}
      {isOpenPrizeHistory && (
        <PrizeHistory
          user={userDetail}
          isOpen={isOpenPrizeHistory}
          onClose={handleClosePrizeHistoryModal}
        />
      )}
      {isOpenHistoryPlay && (
        <HistoryPlayGame
          user={userDetail}
          isOpen={isOpenHistoryPlay}
          onClose={handleCloseHistoryPlayGame}
        />
      )}
      {isOpenFreePlayHistory && (
        <FreePlayHistory
          user={userDetail}
          isOpen={isOpenFreePlayHistory}
          onClose={handleCloseFreePlayHistoryModal}
        />
      )}
    </Flex>
  );
}
