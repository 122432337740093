import * as yup from "yup";

export const schemaSkillRank = () =>
  yup.object().shape({
    mode_type: yup
      .string()
      .trim()
      .required("Required"),
    base: yup
      .number()
      .min(0, "Must be greater than or equal to 0")
      .nullable(true)
      .transform((_, val) => {
        if (val || val === 0) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer")
      .required("Required"),
    bonus: yup
      .number()
      .nullable(true)
      .transform((_, val) => {
        if (val || val === 0) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .required("Required")
      .positive("Must be greater than 0"),
  });

export const schemaSkillRankHunter = () =>
  yup.object().shape({
    rarity: yup
      .string()
      .trim()
      .required("Required"),
    trait: yup
      .string()
      .trim()
      .required("Required"),
    cases: yup
      .string()
      .trim()
      .required("Required"),
    value: yup
      .number()
      .nullable(true)
      .transform((_, val) => {
        if (val || val === 0) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .required("Required")
      .positive("Must be greater than 0"),
  });
