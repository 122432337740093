/*eslint no-invalid-regexp: "error"*/
import { Tooltip } from "@chakra-ui/react";

export function reverseString(string = "") {
  let arrayOfChar = string.split("");
  let strToArray = arrayOfChar.reverse();
  let newString = strToArray.join("");
  return newString;
}

export function insertSpace(string = "") {
  let formattedNumber = reverseString(
    string?.toString()?.replace(/[^\d]/g, "")
  );
  let numberSections = formattedNumber?.match(/\d{1,3}/g);
  if (numberSections !== null) {
    formattedNumber = numberSections?.join(",");
  }
  if (string !== formattedNumber) {
    string = formattedNumber;
  }
  return reverseString(string);
}

export function getText(html) {
  let divContainer = document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
}

export function formatMoney(amount, suffix = "￥") {
  return `${amount}${suffix}`;
}

export function camel2Text(key) {
  const result = key.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

export function trimCharacter(
  string,
  numberOfCharacter = 10,
  numberEndOfCharacter = 3
) {
  return (
    string && (
      <Tooltip label={string}>
        <span>{`${string.slice(0, numberOfCharacter)}...${string.slice(
          string.length - numberEndOfCharacter,
          string.length
        )}`}</span>
      </Tooltip>
    )
  );
}

export function splitEclipseCharacter(
  string,
  isWithTooltip = true,
  numberOfCharacter = 30
) {
  if (string && string?.length > 30) {
    if (isWithTooltip) {
      return (
        <Tooltip label={string}>
          <span>{`${string.slice(0, numberOfCharacter)}...`}</span>
        </Tooltip>
      );
    }
    if (Number(numberOfCharacter) >= string?.length) {
      return string;
    }
    return `${string.slice(0, numberOfCharacter)}...`;
  }
  return string;
}

export function removeEmojis(str = "") {
  // const emojiRE = /\p{Emoji_Modifier_Base}\p{Emoji_Modifier}?|\p{Emoji_Presentation}|\p{Emoji}|\p{Extended_Pictographic}\uFE0F/gu
  const emojiRE =
    /\p{Emoji_Modifier_Base}\p{Emoji_Modifier}?|\p{Emoji_Presentation}|\p{Extended_Pictographic}/gu;
  return str?.replace(emojiRE, "");
}

export function convertStringSnake(str = "") {
  const lowercased = str.toLowerCase();
  const words = lowercased.split("_");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return capitalizedWords.join(" ");
}
