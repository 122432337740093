import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getHunterNFTs = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.NFT_HUNTER}?${params}`);
};

export const getDetailHunterNFT = (id) => {
  return http.get(`${END_POINT.NFT_HUNTER}/${id}`);
};

export const importCSVHunterNFT = (id, data) => {
  return http.post(`${END_POINT.NFT_HUNTER}/${id}/import-csv`, data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
};


// hunter off chain
export const getHunterOffChainApi = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.HUNTER_OFF_CHAIN}?${params}`);
};

export const updateHunterOffChainApi = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.HUNTER_OFF_CHAIN}/${idEdit}`, data, option);
};
