import { useToast } from "@chakra-ui/react";
import { CopyIcon } from "components/Icons/CopyIcon";

export default function CopyComponent({
  dataCopy = "",
  isShowSuccess = true,
  ...rest
}) {
  const toast = useToast();
  const handleOnClick = () => {
    if (isShowSuccess) {
      toast({
        title: `Copy success`,
        status: "success",
        position: "bottom-right",
      });
    }
    navigator.clipboard.writeText(dataCopy);
  };
  return <CopyIcon cursor="pointer" onClick={handleOnClick} {...rest} />;
}
