import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import RegisterUserEventRow from "./row";

export default function RegisterUserEventTable({
  isLoading,
  totalPage,
  page,
  limit,
  data = [],
  fontSize = "sm",
  setCheckedIds,
  checkedIds = [],
  onChangeChecked,
  onChangeLimit,
  handleChangePage,
  ...rest
}) {
  const labels = ["Email", "Wallet", "Register at"];

  return (
    <Card p={0}>
      <CardBody
        overflowX={{ sm: "scroll", md: "hidden" }}
        m="auto"
        minH="200px"
        {...rest}
      >
        <TableCustom
          labels={labels}
          isLoading={isLoading}
          isCheck
          isChecked={data?.length > 0 && checkedIds.length === data?.length}
          onChangeChecked={onChangeChecked}
          dataRow={data}
        >
          <>
            {data?.map((item) => {
              return (
                <RegisterUserEventRow
                  key={item?.id}
                  item={item}
                  fontSize={fontSize}
                  setCheckedIds={setCheckedIds}
                  checkedIds={checkedIds}
                />
              );
            })}
          </>
        </TableCustom>
      </CardBody>
      <Paginate
        page={page}
        pageCount={totalPage}
        limit={limit}
        onChangeLimit={onChangeLimit}
        handleChangePage={handleChangePage}
        pageRangeDisplayed={1}
      />
    </Card>
  );
}
