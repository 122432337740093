import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { useController, useFormContext } from "react-hook-form";
import styled from "styled-components";

const RadioGroupStyled = styled(RadioGroup)`
  .chakra-radio {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    font-weight: 500;
    gap: 8px;
    &__label {
      margin-left: 0;
    }
    &__control {
      width: 18px;
      height: 18px;
      &[data-checked] {
        background: #ffcf3e;
        border-color: #ffcf3e;
        color: #000000;
        width: 18px;
        height: 18px;
      }
    }
  }
`;

const FormRadio = ({
  label,
  name,
  rules,
  isRequired,
  defaultValue = "",
  options = [],
  wrapperProps,
  ...rest
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  return (
    <FormControl w="auto" isInvalid={error?.message} {...wrapperProps}>
      {label && (
        <FormLabel whiteSpace="nowrap" color="#000000">
          {label} {isRequired && <span style={{ color: "red" }}>*</span>}
        </FormLabel>
      )}
      <RadioGroupStyled onChange={onChange} value={value} {...rest}>
        <Stack spacing="70px" direction="row">
          {options?.map((item) => {
            return (
              <Radio key={item?.value} value={item?.value}>
                {item?.label}
              </Radio>
            );
          })}
        </Stack>
      </RadioGroupStyled>
    </FormControl>
  );
};

export default FormRadio;
