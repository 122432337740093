import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import TableCustom from "components/Tables/CustomTable";

const RowItem = ({ textColor, restriction }) => {
  const dataLevel = restriction?.find(
    (item) => item?.entry_restriction === "LEVEL"
  );
  const dataSkillRank = restriction?.find(
    (item) => item?.entry_restriction === "SKILL_RANK"
  );
  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="12px" fontWeight={400}>
          {`${dataLevel?.min_value ?? ""}  ${dataLevel?.max_value ?? ""}`}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="12px" fontWeight={400}>
          {`${dataSkillRank?.min_value ?? ""}  ${
            dataSkillRank?.max_value ?? ""
          }`}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="12px" fontWeight={400}></Text>
      </Td>
    </Tr>
  );
};

export default function DetailGameMode({ isOpen, onClose, restriction }) {
  const textColor = useColorModeValue("#000000", "white");
  const labels = ["LEVEL", "SKILL TITLE", "ARENA HUNTER TRAIT"];
  const renderForm = () => {
    return (
      <TableCustom
        labels={labels}
        isCheck={false}
        dataRow={restriction?.slice(0, 1)}
      >
        <>
          {restriction?.slice(0, 1)?.map((item) => {
            return (
              <RowItem
                restriction={restriction}
                textColor={textColor}
                key={item?.id}
              />
            );
          })}
        </>
      </TableCustom>
    );
  };
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="685px">
        <ModalHeader
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Entry Restriction Type
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          w="100%"
          textAlign="center"
          margin="auto"
          display="flex"
          alignItems="center"
          gap="16px"
        >
          <ButtonType
            mt={4}
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            onClick={onClose}
          >
            Close
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
