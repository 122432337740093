import END_POINT from './constants'
import http from './http'
import { convertParams, omitParams } from "utils/object";

export function getMaintenance(p) {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.GET_MAINTENANCE}?${params}`)
}

export function postMaintenance(data) {
  return http.post(END_POINT.POST_MAINTENANCE, data)
}

export function getMaintenanceById(id) {
  return http.get(`${END_POINT.GET_MAINTENANCE_BY_ID}/${id}`)
}

export function putMaintenance(idEdit, data) {
  return http.put(`${END_POINT.PUT_MAINTENANCE_BY_ID}/${idEdit}`, data)
}

export function deleteMaintenance(id) {
  return http.delete(`${END_POINT.DELETE_MAINTENANCE_BY_ID}/${id}`)
}
