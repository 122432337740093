export const NoDataTableIcon = (props) => (
  <svg
    width={250}
    height={200}
    viewBox="0 0 250 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={250} height={200} fill="white" />
    <path
      d="M104.065 172.256H105.661V182H104.065L99.2632 174.734V182H97.6672V172.256H99.2632L104.065 179.508V172.256ZM115.107 178.136C115.107 180.558 113.315 182.126 111.103 182.126C108.905 182.126 107.239 180.558 107.239 178.136C107.239 175.714 108.975 174.16 111.173 174.16C113.371 174.16 115.107 175.714 115.107 178.136ZM108.863 178.136C108.863 179.886 109.899 180.74 111.103 180.74C112.293 180.74 113.469 179.886 113.469 178.136C113.469 176.386 112.335 175.546 111.145 175.546C109.941 175.546 108.863 176.386 108.863 178.136ZM123.499 172.27C126.621 172.27 128.665 174.16 128.665 177.17C128.665 180.166 126.621 182 123.499 182H120.321V172.27H123.499ZM121.917 180.698H123.499C125.795 180.698 127.027 179.382 127.027 177.17C127.027 174.944 125.795 173.572 123.499 173.572H121.917V180.698ZM129.715 178.108C129.715 175.728 131.325 174.16 133.355 174.16C134.657 174.16 135.553 174.776 136.029 175.406V174.286H137.639V182H136.029V180.852C135.539 181.51 134.615 182.126 133.327 182.126C131.325 182.126 129.715 180.488 129.715 178.108ZM136.029 178.136C136.029 176.484 134.895 175.546 133.691 175.546C132.501 175.546 131.353 176.442 131.353 178.108C131.353 179.774 132.501 180.74 133.691 180.74C134.895 180.74 136.029 179.802 136.029 178.136ZM139.988 179.858V175.588H139.078V174.286H139.988V172.368H141.598V174.286H143.474V175.588H141.598V179.858C141.598 180.432 141.822 180.67 142.494 180.67H143.474V182H142.214C140.842 182 139.988 181.426 139.988 179.858ZM144.413 178.108C144.413 175.728 146.023 174.16 148.053 174.16C149.355 174.16 150.251 174.776 150.727 175.406V174.286H152.337V182H150.727V180.852C150.237 181.51 149.313 182.126 148.025 182.126C146.023 182.126 144.413 180.488 144.413 178.108ZM150.727 178.136C150.727 176.484 149.593 175.546 148.389 175.546C147.199 175.546 146.051 176.442 146.051 178.108C146.051 179.774 147.199 180.74 148.389 180.74C149.593 180.74 150.727 179.802 150.727 178.136Z"
      fill="#969696"
    />
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63 134H154C154.515 134 155.017 133.944 155.5 133.839C155.983 133.944 156.485 134 157 134H209C212.866 134 216 130.866 216 127C216 123.134 212.866 120 209 120H203C199.134 120 196 116.866 196 113C196 109.134 199.134 106 203 106H222C225.866 106 229 102.866 229 99C229 95.134 225.866 92 222 92H200C203.866 92 207 88.866 207 85C207 81.134 203.866 78 200 78H136C139.866 78 143 74.866 143 71C143 67.134 139.866 64 136 64H79C75.134 64 72 67.134 72 71C72 74.866 75.134 78 79 78H39C35.134 78 32 81.134 32 85C32 88.866 35.134 92 39 92H64C67.866 92 71 95.134 71 99C71 102.866 67.866 106 64 106H24C20.134 106 17 109.134 17 113C17 116.866 20.134 120 24 120H63C59.134 120 56 123.134 56 127C56 130.866 59.134 134 63 134ZM226 134C229.866 134 233 130.866 233 127C233 123.134 229.866 120 226 120C222.134 120 219 123.134 219 127C219 130.866 222.134 134 226 134Z"
      fill="#FFCF3E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.119 112.307C113.04 112.86 113 113.425 113 114C113 120.627 118.373 126 125 126C131.627 126 137 120.627 137 114C137 113.425 136.96 112.86 136.881 112.307H166V139C166 140.657 164.657 142 163 142H87C85.3431 142 84 140.657 84 139V112.307H113.119Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138 112C138 119.18 132.18 125 125 125C117.82 125 112 119.18 112 112C112 111.767 112.006 111.536 112.018 111.307H84L93.5604 83.0389C93.9726 81.8202 95.1159 81 96.4023 81H153.598C154.884 81 156.027 81.8202 156.44 83.0389L166 111.307H137.982C137.994 111.536 138 111.767 138 112Z"
      fill="white"
    />
    <path
      d="M121.999 48C123.656 48 124.999 46.6569 124.999 45C124.999 43.3431 123.656 42 121.999 42C120.342 42 118.999 43.3431 118.999 45C118.999 46.6569 120.342 48 121.999 48Z"
      stroke="#FFCF3E"
      strokeWidth={2}
    />
    <path
      d="M153.497 72C153.497 73.3807 152.378 74.5 150.997 74.5C149.617 74.5 148.497 73.3807 148.497 72C148.497 70.6193 149.617 69.5 150.997 69.5C152.378 69.5 153.497 70.6193 153.497 72Z"
      fill="#FFCF3E"
      stroke="#FFCF3E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.484 59L128.125 67.3582L136.484 59ZM127.998 59L136.357 67.3582L127.998 59Z"
      fill="#FFCF3E"
    />
    <path
      d="M127.998 59L136.357 67.3582M136.484 59L128.125 67.3582L136.484 59Z"
      stroke="#FFCF3E"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100 68.5962L94 74.5962L100 68.5962ZM94 68.5962L100 74.5962L94 68.5962Z"
      fill="#FFCF3E"
    />
    <path
      d="M94 68.5962L100 74.5962M100 68.5962L94 74.5962L100 68.5962Z"
      stroke="#FFCF3E"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M110.096 59C110.096 60.3807 108.976 61.5 107.596 61.5C106.215 61.5 105.096 60.3807 105.096 59C105.096 57.6193 106.215 56.5 107.596 56.5C108.976 56.5 110.096 57.6193 110.096 59Z"
      fill="#FFCF3E"
      stroke="#FFCF3E"
    />
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.098 112.955C136.098 118.502 131.129 124 125 124C118.871 124 113.902 118.502 113.902 112.955C113.902 112.775 113.908 111.596 113.918 111.419H93L101.161 91.5755C101.513 90.6338 102.489 90 103.587 90H146.413C147.511 90 148.487 90.6338 148.839 91.5755L157 111.419H136.082C136.092 111.596 136.098 112.775 136.098 112.955Z"
      fill="#FFCF3E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.25 111.512V138C85.25 138.966 86.0335 139.75 87 139.75H163C163.966 139.75 164.75 138.966 164.75 138V111.512L155.255 83.4393C155.015 82.7285 154.348 82.25 153.598 82.25H96.4023C95.6519 82.25 94.985 82.7285 94.7446 83.4393L85.25 111.512Z"
      stroke="#FFCF3E"
      strokeWidth="2.5"
    />
    <path
      d="M86 111C89.9366 111 106.185 111 110.745 111C112.621 111 112.621 112.319 112.621 113C112.621 119.627 118.117 125 124.897 125C131.677 125 137.173 119.627 137.173 113C137.173 112.319 137.173 111 139.05 111H164"
      stroke="#FFCF3E"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
