import { Center, Flex, Icon, useColorModeValue } from "@chakra-ui/react";
import FormLabelComponent from "components/form/FormLabel";
import ItemEventPrize from "./ItemEventPrize";
import { MdOutlineAdd } from "react-icons/md";

export default function EventPrizeList({
  fieldEventPrizes,
  removeEventPrize,
  onOpenSelectPrize,
  transLabel,
  disableWhenJP,
}) {
  const textColor = useColorModeValue("#000000", "white");
  return (
    <Flex direction="column">
      <FormLabelComponent
        mb="22px"
        color={textColor}
        title={transLabel("eventPrize")}
      />
      <Center w={{ base: "100%", md: "72%" }} m="auto">
        <Flex direction="column" w="100%" gap={4} align="center">
          {fieldEventPrizes &&
            fieldEventPrizes?.length > 0 &&
            fieldEventPrizes?.map((item, index) => {
              return (
                <ItemEventPrize
                  key={item?.id}
                  item={item}
                  indexItem={index}
                  transLabel={transLabel}
                  removeEventPrize={removeEventPrize}
                  disableWhenJP={disableWhenJP}
                />
              );
            })}
        </Flex>
      </Center>
      <Center w="100%" mt="20px">
        <Icon
          fontSize="26px"
          cursor={disableWhenJP ? "not-allowed" : "pointer"}
          onClick={() => {
            if (!disableWhenJP) {
              onOpenSelectPrize();
            }
          }}
          color={textColor}
          key={"add"}
          as={MdOutlineAdd}
        />
      </Center>
    </Flex>
  );
}
