import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { importCSVHunterNFT } from "api/hunterNFTs.api";
import ButtonType from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import ModalSuccessAndFail from "components/Modal/ModalSuccessAndFail";
import FormSelect from "components/form/FormSelect";
import FormUpload from "components/form/FormUpload";
import useWithToast from "hooks/useWithToast";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import ItemCSV from "./itemCSV";
import { getContractsApi } from "api/contract.api";
import { NFT_CHAIN, TOKEN_TYPE } from "constants/constants";
import { downloadLocalCSV } from "utils/downloadCSV";
import CSVDownload from "assets/csv/hunter_nft .csv";
import ModalAddContract from "components/Modal/ModalAddContract";

export default function ImportNftHunterPage() {
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm();
  const [type, setType] = useState("success");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenAddContract,
    onOpen: onOpenAddContract,
    onClose: onCloseAddContract,
  } = useDisclosure();
  const { showToastFail } = useWithToast();
  const [addressContracts, setAddressContracts] = useState([]);
  const {
    getValues,
    setValue,
    control,
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const {
    fields: fieldCSV,
    append: appendCSV,
    remove: removeCSV,
  } = useFieldArray({
    control,
    name: "listCSV",
  });

  const isExitsListCSV = useMemo(() => {
    return fieldCSV && fieldCSV?.length > 0;
  }, [fieldCSV]);

  const isExitCSVFail = useMemo(() => {
    if (fieldCSV && fieldCSV?.length > 0) {
      const list = fieldCSV?.filter((item) => {
        if (item?.isError) {
          return item;
        }
      });
      return list?.length > 0;
    }
    return false;
  }, [fieldCSV]);

  const listNameCSVErrors = useCallback(
    (listError) => {
      if (fieldCSV && fieldCSV?.length > 0) {
        const list = fieldCSV
          ?.filter((item, index) => {
            if (listError[index]) {
              return item;
            }
          })
          .map((item) => item?.file?.name);
        return list?.join(", ");
      }
      return null;
    },
    [fieldCSV]
  );

  const importCSV = async (id, file) => {
    const formData = new FormData();
    formData.append("file", file);
    return importCSVHunterNFT(id, formData);
  };

  const clearListCSV = () => {
    reset({
      ...getValues(),
      listCSV: [],
    });
  };

  const onSubmit = useCallback(async () => {
    try {
      const idContract = getValues("contract");
      if (isExitsListCSV) {
        const promise = fieldCSV?.map((item) => {
          return importCSV(idContract, item?.file);
        });
        const res = await Promise.all(promise);
        if (res) {
          let arrError = [];
          let isError = false;
          for (let i = 0; i < res?.length; i++) {
            const err = !res[i]?.data?.success;
            if (err) {
              isError = true;
            }
            arrError = arrError.concat(err);
          }
          if (isError) {
            reset({
              ...getValues(),
              listCSV: fieldCSV?.map((item, index) => ({
                ...item,
                isError: arrError[index],
              })),
            });
            setType("error");
            const listNameError = listNameCSVErrors(arrError);
            if (listNameError) {
              showToastFail({
                title: `${listNameError}  file upload failed.`,
              });
            }
            onOpen();
          } else {
            setType("success");
            onOpen();
            clearListCSV();
          }
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }, [isExitsListCSV, listNameCSVErrors]);

  const handleOnChangeCSV = (files) => {
    if (files?.length > 0) {
      const listFile = files?.map((file) => {
        return { file };
      });
      if (listFile && listFile?.length > 0) {
        appendCSV(listFile);
      }
    }
  };

  const getContracts = async () => {
    try {
      const { data } = await getContractsApi({
        token_type: TOKEN_TYPE.HUNTER_NFT,
      });
      const list = data?.data?.records;
      if (list && list?.length > 0) {
        const address = list?.map((item) => ({
          label: `${item?.chain_name}_${item?.contract_address}`,
          value: item?.id,
        }));
        setAddressContracts(address);
        setValue("contract", address[0].value);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    getContracts();
  }, []);

  const renderForm = () => {
    return (
      <FormProvider {...form} w="100%">
        <form
          id="role-manager-form-id"
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: "100%",
            minWidth: "600px",
            overflowX: "auto",
          }}
        >
          <Flex direction="column" gap={4}>
            <Flex alignItems="flex-end" justifyContent="space-between" gap={4} w="100%" p="0px 2px">
              <FormSelect
                label="Contract address"
                name="contract"
                options={addressContracts}
                w="100%"
                lenSplitLabel={100}
                wrapperProps={{
                  width: "100%",
                }}
              />
              <ButtonType
                padding="16px"
                btnType="primary-new-outline"
                borderRadius="5px"
                onClick={onOpenAddContract}
              >
                Add Contract
              </ButtonType>
            </Flex>
            <Flex direction="column" gap={4} w="100%">
              <FormUpload
                name="csv"
                handleOnChange={handleOnChangeCSV}
                accept=".csv"
              />
            </Flex>
            <Flex
              direction="column"
              gap={2}
              w="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              {fieldCSV?.map((item, index) => {
                return (
                  <ItemCSV
                    key={item?.id}
                    name={item?.file?.name}
                    isError={item?.isError}
                    indexItem={index}
                    removeCSV={removeCSV}
                  />
                );
              })}
            </Flex>
            <Flex gap="16px" pl="2px" pb="2px">
              <ButtonType
                fontSize="16px"
                fontWeight="500"
                w="140px"
                h="56px"
                btnType="primary-new-outline"
                disabled={!isExitsListCSV}
                onClick={() => {
                  clearListCSV();
                }}
              >
                Clear
              </ButtonType>
              <ButtonType
                fontSize="16px"
                fontWeight="500"
                w="140px"
                h="56px"
                type="submit"
                btnType="primary-new"
                disabled={
                  !isExitsListCSV || isExitCSVFail || !watch("contract")
                }
                isLoading={isSubmitting}
              >
                Save
              </ButtonType>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  const downloadTemplate = async () => {
    try {
      const fileName = "hunter_nft.csv";
      const csv = CSVDownload;
      await downloadLocalCSV(csv, fileName);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        <CardHeader>
          <Flex
            w="100%"
            gap={4}
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Import NFT Hunter
            </Text>
            <ButtonType
              fontSize="16px"
              fontWeight="500"
              btnType="primary-new"
              onClick={downloadTemplate}
            >
              Download Template
            </ButtonType>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto" pt="24px">
          {renderForm()}
        </CardBody>
      </Card>
      <ModalSuccessAndFail
        isOpen={isOpen}
        type={type}
        onClose={onClose}
        onSubmit={onClose}
        description={
          type
            ? "You have successfully uploaded the NFT!"
            : "An error occurred. Please try again later!"
        }
      />
      {isOpenAddContract && (
        <ModalAddContract
          isOpen={isOpenAddContract}
          onClose={onCloseAddContract}
          tokenType="HUNTER_NFT"
          chainList={NFT_CHAIN}
        />
      )}
    </Flex>
  );
}
