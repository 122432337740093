export const ErrorIcon = (props) => (
  <svg
    width={94}
    height={94}
    viewBox="0 0 94 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M73.9107 46.8961C73.9107 61.3507 62.028 73.0717 47.3791 73.0717C32.725 73.0717 20.8462 61.3507 20.8462 46.8961C20.8462 32.4441 32.7263 20.7244 47.3791 20.7244C62.028 20.7244 73.9107 32.4441 73.9107 46.8961Z"
      fill="#FFF4F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.4698 29.0964C63.3308 26.5144 58.4415 25.0225 53.2037 25.0225C38.2969 25.0225 26.2125 37.1069 26.2125 52.0137C26.2125 59.0177 28.8802 65.3985 33.2549 70.1957C25.614 65.4291 20.5298 56.9475 20.5298 47.2784C20.5298 32.3715 32.6142 20.2871 47.521 20.2871C55.424 20.2871 62.5336 23.6836 67.4698 29.0964Z"
      fill="#FF8383"
    />
    <path
      d="M47.379 12C27.8402 12 12 27.6258 12 46.896C12 66.1728 27.8402 81.7973 47.379 81.7973C66.9165 81.7973 82.7567 66.1741 82.7567 46.896C82.7567 27.6258 66.9165 12 47.379 12ZM47.379 73.0717C32.7249 73.0717 20.8461 61.3507 20.8461 46.896C20.8461 32.444 32.7262 20.7243 47.379 20.7243C62.0279 20.7243 73.9106 32.444 73.9106 46.896C73.9106 61.3507 62.0279 73.0717 47.379 73.0717Z"
      fill="#E50000"
    />
    <path
      d="M61.2452 59.2452C60.2388 60.2516 58.5613 60.2516 57.5548 59.2452L49 50.6904L40.4452 59.2452C39.4387 60.2516 37.7612 60.2516 36.7548 59.2452C35.7484 58.2388 35.7484 56.5613 36.7548 55.5548L45.3096 47L36.7548 38.4452C35.7484 37.4387 35.7484 35.7612 36.7548 34.7548C37.7612 33.7484 39.4387 33.7484 40.4452 34.7548L49 43.3096L57.5548 34.7548C58.5613 33.7484 60.2388 33.7484 61.2452 34.7548C62.2516 35.7612 62.2516 37.4387 61.2452 38.4452L52.6904 47L61.2452 55.5548C62.2516 56.5613 62.2516 58.2387 61.2452 59.2452Z"
      fill="#E50000"
    />
  </svg>
);
