import { STATUS } from "constants/constants";

export const STATUS_OPTIONS = [
  { label: "Active", value: STATUS.ACTIVE },
  { label: "No Active", value: STATUS.NO_ACTIVE },
  { label: "Disconnect", value: STATUS.DISCONNECT },
];

export const STATUS_CAMERA_OPTIONS = [
  { label: "OK", value: STATUS.OK },
  { label: "Fail", value: STATUS.FAIL },
  { label: "Missing", value: STATUS.MISSING },
  { label: "Close", value: STATUS.CLOSE },
];

export const STATUS_MACHINE = {
  ACTIVE: "Active",
  NO_ACTIVE: "No Active",
  DISCONNECT: "Disconnect",
};

export const DIRECTION_MACHINE = {
  LEFT: "Left",
  RIGHT: "Right",
};
