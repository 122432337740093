import { containSpecialRegex } from "utils/regex";
import * as yup from "yup";

const schemaSkillRank = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required("Required"),
    recovery_value: yup
      .number()
      .min(0, "Must be greater than or equal to 0")
      .nullable(true)
      .transform((_, val) => {
        if (val || val === 0) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer")
      .required("Required"),
  });

export default schemaSkillRank;
