/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Paginate from "components/Paginate";
import NoResultsTableRow from "components/Tables/NoResultsTableRow";
import { ACTION_PAGE, FIRST_PAGE, LIMIT_PAGE } from "constants/constants";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// import { PrizeIcon } from 'components/Icons/PrizeIcon'
import CheckBoxType from "components/Checkbox";
import ProjectCategoryRow from "components/Tables/projectCategory/ProjectCategory";
import {
  getProjectCategoryApi,
  getProjectCategoryByIdApi,
} from "stores/projectCategory/action";
import { projectCategorySelector } from "stores/projectCategory/selector";
import ProjectCreateAndUpdate from "./components/createAndUpdate";
import ProjectCategoryDetail from "./components/detail";
import ConditionProjectCategory from "./condition";
import ActionProjectCategory from "./action";

export default function ProjectCategory() {
  const { t } = useTranslation("projectCategory");
  const dispatch = useDispatch();
  const textColor = useColorModeValue("#000000", "white");
  const [checkedIds, setCheckedIds] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const actionPageRef = useRef(ACTION_PAGE.VIEW);
  const [params, setParams] = useState({
    page: FIRST_PAGE,
    limit: LIMIT_PAGE,
  });
  const { listProjectCategories, loadingProjectCategory } = useSelector(
    projectCategorySelector
  );

  const [dataTable, setDataTable] = useState([]);
  const [total, setTotal] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();

  const handleClose = () => {
    onClose();
    setDataDetail(null);
  };

  const handleDetailClose = () => {
    onCloseDetail();
  };

  const getListProjectCategory = () => {
    dispatch(getProjectCategoryApi(params));
  };

  useEffect(() => {
    getListProjectCategory();
  }, [params]);

  useEffect(() => {
    if (listProjectCategories) {
      setDataTable(() => listProjectCategories?.records || []);
      setTotal(listProjectCategories?.total_page || 0);
    }
  }, [listProjectCategories]);

  const handleOpenCreateOrUpdate = (data = null) => {
    setDataDetail(data);
    onOpen();
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    handleClose();
    setDataDetail(null);
    setParams({
      ...params,
      page: FIRST_PAGE,
    });
  }, [params]);

  const labels = [
    {
      title: t("label_image"),
      minWidth: "60px",
      maxWidth: "",
      textAlign: "left",
    },
    {
      title: t("label_name"),
      minWidth: "200px",
      maxWidth: "200px",
      textAlign: "left",
    },
    {
      title: t("label_description"),
      minWidth: "350px",
      maxWidth: "350px",
      textAlign: "left",
    },
    {
      title: t("label_last_modified_at"),
      minWidth: "200px",
      maxWidth: "",
      textAlign: "left",
    },
    {
      title: t("label_last_modified_by"),
      minWidth: "150px",
      maxWidth: "",
      textAlign: "left",
    },
    {
      title: t("label_create_at"),
      minWidth: "200px",
      maxWidth: "",
      textAlign: "left",
    },
    {
      title: t("label_ceateed_by"),
      minWidth: "150px",
      maxWidth: "",
      textAlign: "left",
    },
    { title: "", minWidth: "100px", maxWidth: "100px", textAlign: "left" },
  ];

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const handleSetDataDetail = (id = "") => {
    dispatch(getProjectCategoryByIdApi(id));
    onOpenDetail();
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: FIRST_PAGE,
      limit,
    });
  };

  return (
    <>
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <Card px="0px">
          <CardHeader px="22px" mb="12px">
            <Flex
              w="100%"
              gap={4}
              direction={{
                base: "column",
                md: "row",
              }}
              justifyContent={{
                base: "flex-start",
                md: "space-between",
              }}
              alignItems="flex-start"
            >
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                {t("project_title")}
              </Text>
              <ActionProjectCategory
                handleOpenCreateOrUpdate={() => handleOpenCreateOrUpdate()}
                checkedIds={checkedIds}
                setCheckedIds={setCheckedIds}
                setParams={setParams}
                params={params}
              />
            </Flex>
          </CardHeader>
          <CardHeader px="42px" mb="32px">
            <ConditionProjectCategory
              isLoading={loadingProjectCategory}
              setParams={setParams}
              params={params}
            />
          </CardHeader>

          <Box position="relative">
            <TableContainer w={"100%"}>
              <Table variant="simple" overflow={"auto"}>
                <Thead>
                  <Tr>
                    <Th width="10px">
                      <CheckBoxType
                        isChecked={
                          dataTable?.length > 0 &&
                          checkedIds.length === dataTable?.length
                        }
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          if (isChecked) {
                            setCheckedIds(
                              dataTable.map((machine) => machine.id)
                            );
                          } else {
                            setCheckedIds([]);
                          }
                        }}
                      ></CheckBoxType>
                    </Th>
                    {labels.map((label) => {
                      return (
                        <Th
                          key={label.title}
                          whiteSpace="nowrap"
                          color="#8392ab"
                          fontWeight="700"
                          fontSize="sm"
                          minW={label.minWidth}
                          maxW={label.maxWidth}
                          textAlign={label.textAlign}
                        >
                          {label.title}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {!dataTable?.length ? (
                    <NoResultsTableRow
                      numColumns={labels?.length}
                      loadingPage={loadingProjectCategory}
                    />
                  ) : (
                    dataTable.map((project, i) => {
                      return (
                        <ProjectCategoryRow
                          key={i}
                          setCheckedIds={setCheckedIds}
                          checkedIds={checkedIds}
                          handleSetDataDetail={handleSetDataDetail}
                          handleOpenCreateOrUpdate={() => {
                            handleOpenCreateOrUpdate(project);
                          }}
                          listProjectCategory={project}
                        />
                      );
                    })
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            {loadingProjectCategory ? (
              <Box
                position="absolute"
                top={0}
                left={0}
                bottom={0}
                w="100%"
                h="100%"
              >
                <LoadingSpinner />
              </Box>
            ) : null}
          </Box>

          {(total && (
            <Paginate
              page={params?.page}
              pageCount={total}
              limit={params.limit}
              onChangeLimit={onChangeLimit}
              handleChangePage={handleChangePage}
            />
          )) ||
            ""}
        </Card>

        {isOpenDetail && (
          <ProjectCategoryDetail
            isOpen={isOpenDetail}
            onClose={handleDetailClose}
          />
        )}

        {isOpen && (
          <ProjectCreateAndUpdate
            isOpen={isOpen}
            onClose={handleClose}
            dataDetail={dataDetail}
            handle={actionPageRef.current}
            handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
            handleViewClose={handleClose}
          />
        )}
      </Flex>
    </>
  );
}
