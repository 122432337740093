import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useCallback, useRef, useState } from "react";
import ModalSellBatchNFTHunter from "./modalSellBatch";
import TabPriceHunterNFT from "./tabPrice";

export default function ListNftGenesisPage() {
  const textColor = useColorModeValue("#000000", "white");
  const [tabIndex, setTabIndex] = useState(0);

  const {
    isOpen: isOpenSellBatch,
    onOpen: onOpenSellBatch,
    onClose: onCloseSellBatch,
  } = useDisclosure();

  const refTabImmutable = useRef();
  const refTabDm2c = useRef();
  const refTabPolygon = useRef();

  const handleSellSuccess = useCallback(
    (list = [], status = "SALE") => {
      if (tabIndex === 0) {
        refTabImmutable.current.handleSellBatchSuccess(list, status);
      } else if (tabIndex === 1) {
        refTabDm2c.current.handleSellBatchSuccess(list, status);
      }else {
        refTabPolygon.current.handleSellBatchSuccess(list, status);
      }
      onCloseSellBatch();
    },
    [tabIndex]
  );

  const renderForm = () => {
    return (
      <Flex w="100%" direction="column" alignItems="center" gap="24px" pb="4px">
        <Tabs
          onChange={(index) => {
            setTabIndex(index);
          }}
          variant="unstyled"
          w="100%"
        >
          <TabList gap={2} borderBottom="1px solid #DBDBDB">
            <Tab
              fontSize="14px"
              fontWeight="400"
              color={textColor}
              w="100%"
              _selected={{
                borderBottom: "1px solid #FFCF3E",
                fontWeight: "400",
                color: "#FFCF3E"
              }}
              _focus={{
                outline: "none",
              }}
            >
              Immutable Chain
            </Tab>
            <Tab
              fontSize="14px"
              fontWeight="400"
              color={textColor}
              w="100%"
              _selected={{
                borderBottom: "1px solid #FFCF3E",
                fontWeight: "400",
                color: "#FFCF3E"
              }}
              _focus={{
                outline: "none",
              }}
            >
              DM2C Chain
            </Tab>
            <Tab
              fontSize="14px"
              fontWeight="400"
              color={textColor}
              w="100%"
              _selected={{
                borderBottom: "1px solid #FFCF3E",
                fontWeight: "400",
                color: "#FFCF3E"
              }}
              _focus={{
                outline: "none",
              }}
            >
              Polygon Chain
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel padding="24px 0">
              <TabPriceHunterNFT
                isActive={tabIndex === 0}
                type="13473"
                ref={refTabImmutable}
              />
            </TabPanel>
            <TabPanel padding="24px 0">
              <TabPriceHunterNFT
                isActive={tabIndex === 1}
                type="68775"
                ref={refTabDm2c}
              />
            </TabPanel>
            <TabPanel padding="24px 0">
              <TabPriceHunterNFT
                isActive={tabIndex === 2}
                type="80002"
                ref={refTabPolygon}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    );
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        <CardHeader>
          <Flex
            w="100%"
            gap={4}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              NFTs Collection
            </Text>
            <ButtonType
              fontSize="16px"
              fontWeight="500"
              h="44px"
              btnType="primary-new"
              onClick={() => {
                onOpenSellBatch();
              }}
            >
              Sell batch NFTs
            </ButtonType>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto" pt="24px">
          {renderForm()}
        </CardBody>
      </Card>

      <ModalSellBatchNFTHunter
        isOpen={isOpenSellBatch}
        onClose={onCloseSellBatch}
        handleSellSuccess={handleSellSuccess}
      />
    </Flex>
  );
}
