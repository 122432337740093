import { Grid } from "@chakra-ui/react";
import ItemNft from "components/ItemNft";

export default function ListBountyBall({
  data = [],
  onOpenSell,
  onOpenCancelSell,
  isSell,
}) {
  return (
    <Grid
      w="100%"
      rowGap="24px"
      columnGap="12px"
      templateColumns={{
        base: "repeat(1, minmax(0, 1fr))",
        lg: "repeat(3, minmax(0, 1fr))",
        xl: "repeat(4, minmax(0, 1fr))",
      }}
    >
      {data?.map((item, index) => {
        return (
          <ItemNft
            isSell={isSell}
            item={item}
            key={index}
            onSell={onOpenSell}
            onCancelSell={onOpenCancelSell}
            type="bounty-ball"
          />
        );
      })}
    </Grid>
  );
}
