import { BoldTextWhiteWithShadow } from "components/common/text";
import styled from "styled-components";
import ICON_HEART from 'assets/img/card/icon_heart.png'

export const WrapPercent = styled.div`
  width: 100%;
  max-width: 120px;
  margin-left: 6px;
  .group-percent {
    position: relative;
    width: 100%;
    min-width: 110px;
    height: 17px;
    background: #000;
    border-radius: 4px;
    display: flex;
    align-items: center;
    .icon-heart {
      position: absolute;
      left: -7px;
      top: -5px;
      width: auto;
      height: 25px;
    }
    .pc-have {
      width: ${props => `${(props?.pcCurrent/props?.pcMax)*100}%` || '100%'};
      height: 15px;
      margin-right: 1px;
      border: 1px solid transparent;
      background: "linear-gradient(0deg, #00a5f0 30%, #00c9fd 70%)";
      border-radius: 4px;
    }
    .text-percent {
      position: absolute;
      left: 40%;
      top: -5px;
      >span {
        font-size: 10px;
      }
    }
  }
`

export const ShowPercent = ({ currentGauge = 0, maxGauge = 0 }) => {

  return (
    <WrapPercent pcMax={maxGauge} pcCurrent={currentGauge}>
      <div className='group-percent'>
        <img className="icon-heart" src={ICON_HEART} alt="icon" />
        <div className='pc-have'></div>
        <div className='text-percent'>
          <BoldTextWhiteWithShadow>
            {`${currentGauge}/${maxGauge}`}
          </BoldTextWhiteWithShadow>
        </div>
      </div>
    </WrapPercent>
  );
}
