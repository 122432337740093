/* eslint-disable no-useless-catch */
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  GAME_PLAY_TYPE_NAMESPACE,
  GET_GAME_PLAY_TYPE_FETCH,
} from '../constant'
import { getGamePlayType } from 'api/gameplayType.api'
import { createStandaloneToast } from '@chakra-ui/react'
// import { convertParams } from 'utils/object'

const toast = createStandaloneToast()

export const getGamePlayTypeApi = createAsyncThunk(
  `${GAME_PLAY_TYPE_NAMESPACE}/${GET_GAME_PLAY_TYPE_FETCH}`,
  async () => {
    try {
      const res = await getGamePlayType()
      if (res?.data?.code === 200) {
        return res?.data?.data?.records || []
      } else {
        console.log("res ==========> ", res);
        if (res?.data?.message || res?.data?.messages[0]) {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: 'error',
            position: 'bottom-right',
          })
        }
      }
    } catch (error) {
      // if (error?.message || error?.messages[0]) {
      //   toast({
      //     description: error?.message || error?.messages[0],
      //     status: 'error',
      //     position: 'bottom-right',
      //   })
      // }
      throw error
    }
  },
)
