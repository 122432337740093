import { Grid } from "@chakra-ui/react";
import ItemNft from "components/ItemNft";

export default function ListBatchSellBountyBall({
  data = [],
  nftsChecked,
  setNFTsChecked,
}) {
  return (
    <Grid
      w="100%"
      rowGap="24px"
      columnGap="12px"
      templateColumns={{
        base: "repeat(1, minmax(0, 1fr))",
        lg: "repeat(3, minmax(0, 1fr))",
      }}
    >
      {data?.map((item, index) => {
        return (
          <ItemNft
            item={item}
            key={index}
            nftsChecked={nftsChecked}
            setNFTsChecked={setNFTsChecked}
            type="bounty-ball"
            isClickAble
          />
        );
      })}
    </Grid>
  );
}
