import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { deleteGameMode } from "api/gameMode.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import ModalConfirm from "components/Modal/ModalConfirm";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import useWithToast from "hooks/useWithToast";
import { useCallback, useEffect, useState } from "react";
import HunterOffChainCreateAndUpdate from "./components/createAndUpdate";
import HunterOffChainRow from "./row";
import { getHunterOffChainApi } from "api/hunterNFTs.api";

export default function HunterOffChainPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState(null);

  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const toast = useToast();
  const { showToastSuccess } = useWithToast();
  const [hunterOffChain, setHunterOffChain] = useState([]);

  const labels = ["#", "NAME", "IMAGE", "LIFE GAUGE BONUS", "RARITY", "ACTION"];
  const textColor = useColorModeValue("#000000", "white");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenConfirmDelete,
    onOpen: onOpenConfirmDelete,
    onClose: onCloseConfirmDelete,
  } = useDisclosure();

  const handleClose = () => {
    onClose();
    setDataDetail(null);
  };

  const handleOpenConfirmDelete = (data = null) => {
    setDataDetail(data);
    onOpenConfirmDelete();
  };

  const handleOpenCreateOrUpdate = (data = null) => {
    setDataDetail(data);
    onOpen();
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    handleClose();
    setDataDetail(null);
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  const getListHunterOffChain = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getHunterOffChainApi({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setHunterOffChain(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  useEffect(() => {
    getListHunterOffChain();
  }, [getListHunterOffChain]);

  const handleDelete = async () => {
    try {
      const res = await deleteGameMode(dataDetail?.id);
      if (res) {
        showToastSuccess({
          title: "Delete Success",
        });
        onCloseConfirmDelete();
        setParams({
          ...params,
          page: 1,
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="20px" w="100%">
          <Flex
            w="100%"
            gap={4}
            direction={{
              base: "column",
              md: "row",
            }}
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignItems="flex-start"
          >
            <Text
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              whiteSpace="nowrap"
            >
              Hunter Off-chain
            </Text>
            {/* <ActionGameMode
              handleOpenCreateOrUpdate={handleOpenCreateOrUpdate}
            /> */}
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck={false}
            dataRow={hunterOffChain}
          >
            <>
              {hunterOffChain?.map((item, index) => {
                return (
                  <HunterOffChainRow
                    key={item?.id}
                    index={(params?.page - 1) * params?.limit + index + 1}
                    handleOpenCreateOrUpdate={() => {
                      handleOpenCreateOrUpdate(item);
                    }}
                    handleOpenConfirmDelete={() => {
                      handleOpenConfirmDelete(item);
                    }}
                    skill={item}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
      {isOpen && (
        <HunterOffChainCreateAndUpdate
          isOpen={isOpen}
          onClose={handleClose}
          dataDetail={dataDetail}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
        />
      )}
      {isOpenConfirmDelete && (
        <ModalConfirm
          isOpen={isOpenConfirmDelete}
          onClose={onCloseConfirmDelete}
          onSubmit={handleDelete}
        />
      )}
    </Flex>
  );
}
