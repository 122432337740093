import { Flex } from "@chakra-ui/react";
import ButtonType from "components/Button";

export default function ActionWalletManagement({
  handleCreateOrUpdateSuccess,
  handleOpenCreateOrUpdate,
}) {
  return (
    <Flex gap={4} flexWrap="wrap" alignItems="flex-end">
      <Flex alignItems="center" gap={4} mb="10px">
        <ButtonType
          text="Create"
          w="120px"
          fontSize="16px"
          fontWeight="700"
          onClick={() => {
            handleOpenCreateOrUpdate();
          }}
        />
        <ButtonType
          text="Reload"
          w="120px"
          fontSize="16px"
          fontWeight="700"
          btnType="primary-new-outline"
          onClick={handleCreateOrUpdateSuccess}
        />
      </Flex>
    </Flex>
  );
}
