/* eslint-disable no-useless-escape */
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

// eslint-disable-next-line no-irregular-whitespace
export const containSpecialUserNameRegex = /^[0-9０-９a-zA-Zぁ-んァ-ンぁ-んァ-ンｧ-ﾝﾞﾟ一-龥_ 　]*$/;

// eslint-disable-next-line no-irregular-whitespace
export const containSpecialRegex = /^[0-9０-９a-zA-Zぁ-んァ-ンぁ-んァ-ンｧ-ﾝﾞﾟ一-龥 　]*$/;

export const noSpecialCharRegex =
  /[^\u2700-\u27BF\uE000-\uF8FF\uDD10-\uDDFF\u2011-\u26FF\uDC00-\uDFFF\uDC00-\uDFFF\u005D\u007C@~`!@#$%^&*()_=+[{}"\\';:"\/?>.<,-]/;

export const regexUpperCaseAndUnderSource = /^[A-Z]+(_[A-Z]+)*$/;

export const passwordRegex = /^.{8,}$/;
