import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getListRealCraneMachines } from "api/machine.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { useCallback, useEffect, useState } from "react";
import MachineStateRow from "./Row";
import MachineDetail from "./components/detail";
import ConditionMachine from "./condition";

export default function MachineState() {
  const [isLoading, setIsLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState(null);

  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const toast = useToast();
  const [machines, setMachines] = useState([]);

  const labels = [
    "Mac address",
    "Status",
    "Front Cam ID",
    "Front Cam Stat",
    "Side Cam ID",
    "Side Cam Stat",
    "Ctrl Svr  ID",
    "Port",
    "Front Cam Link",
    "Side Cam Link",
    "Last Modified At",
    "Last Modified By",
    "Created At",
    "Created By",
    "",
  ];
  const textColor = useColorModeValue("#000000", "white");

  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();

  const handleSetDataDetail = (data) => {
    setDataDetail(data);
    onOpenDetail();
  };

  const handleClose = () => {
    onCloseDetail();
    setDataDetail(null);
  };

  const getListMachine = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getListRealCraneMachines({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setMachines(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  useEffect(() => {
    getListMachine();
  }, [getListMachine]);

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="32px">
          <Flex gap={4}>
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Machines State
            </Text>
          </Flex>
        </CardHeader>
        <CardHeader px="42px" mb="32px">
          <ConditionMachine
            isLoading={isLoading}
            setParams={setParams}
            params={params}
          />
        </CardHeader>
        <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck={false}
            dataRow={machines}
          >
            <>
              {machines?.map((item) => {
                return (
                  <MachineStateRow
                    key={item?.id}
                    handleSetDataDetail={() => {
                      handleSetDataDetail(item);
                    }}
                    machine={item}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
      <MachineDetail
        isOpen={isOpenDetail}
        onClose={handleClose}
        dataDetail={dataDetail}
      />
    </Flex>
  );
}
