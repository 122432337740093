import { Flex, useToast } from "@chakra-ui/react";
import ButtonType from "components/Button";
import { useMemo } from "react";
import { downloadCSV } from "utils/downloadCSV";
import { STATUS_USER } from "./constant";

export default function ActionUser({ checkedIds, handleBanAndUnBanListUser }) {
  const toast = useToast();
  const isHaveCheckedIds = useMemo(() => {
    return checkedIds?.length > 0;
  }, [checkedIds]);

  const downloadPlayer = async () => {
    try {
      const baseUrl = `users/pre-registration-csv`;
      await downloadCSV(baseUrl);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex gap={4} flexWrap="wrap" alignItems="flex-end">
      <Flex alignItems="flex-end" gap={6} mb="10px">
        <ButtonType
          text="Download CSV"
          fontSize="12px"
          sizeIcon="8px"
          btnType="dark"
          onClick={downloadPlayer}
        />
        <ButtonType
          text="BAN"
          fontSize="12px"
          btnType="danger"
          iconType="ban"
          sizeIcon="16px"
          disabled={!isHaveCheckedIds}
          onClick={() => {
            handleBanAndUnBanListUser(STATUS_USER.BANNED);
          }}
        />
        <ButtonType
          text="UNBAN"
          fontSize="12px"
          btnType="success"
          iconType="unban"
          sizeIcon="16px"
          disabled={!isHaveCheckedIds}
          onClick={() => {
            handleBanAndUnBanListUser(STATUS_USER.ACTIVE);
          }}
        />
      </Flex>
    </Flex>
  );
}
