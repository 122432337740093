import { Flex } from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { gamePlaySelector } from "stores/gamePlay/selector";
import { prizeSelector } from "stores/prize/selector";
import { MACHINE_TYPE_OPTIONS, TYPE_PRIZE } from "../../constants/constants";

export default function ConditionMachine({ isLoading, setParams, params }) {
  const { t } = useTranslation("prize");
  const { listPrizeType } = useSelector(prizeSelector);
  const { listGamePlay } = useSelector(gamePlaySelector);
  const [listGamePlayType, setListGamePlayType] = useState(() => []);
  const [indexGamePlay, setIndexGamePlay] = useState(() => "");
  const [indexBoothType, setIndexBoothType] = useState(() => "");
  const [prizeTypes, setListPrizeTypes] = useState(() => "");
  const [indexPrizeType, setIndexPrizeType] = useState(() => "");
  const form = useForm();
  const { setValue, getValues, handleSubmit } = form;

  useEffect(() => {
    if (listPrizeType?.length) {
      let arr = [{ label: "All", value: "" }];
      listPrizeType?.map((item) => {
        return (arr = [...arr, { label: TYPE_PRIZE[item], value: item }]);
      });
      setListPrizeTypes(() => arr);
    }
  }, [listPrizeType]);

  useEffect(() => {
    if (listGamePlay?.length) {
      const listType = listGamePlay?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));
      setListGamePlayType(listType);
      getIndexGamePlay(listType);
    }
  }, [listGamePlay]);

  const getIndexGamePlay = (listType = []) => {
    if (!getValues("gameplay_type_id")) {
      setIndexGamePlay(() => "");
    } else {
      const index = listType?.findIndex(
        (x) => x?.gameplay_type?.id === getValues("gameplay_type_id")
      );
      setIndexGamePlay(() => index || "");
    }
  };

  const getIndexBoothType = () => {
    if (!getValues("booth_type")) {
      setIndexBoothType(() => "");
    } else {
      const index = MACHINE_TYPE_OPTIONS?.findIndex(
        (x) => x?.value === getValues("booth_type")
      );
      setIndexBoothType(() => MACHINE_TYPE_OPTIONS[index] || "");
    }
  };

  const getIndexPrizeType = () => {
    if (!getValues("prize_type")) {
      setIndexPrizeType(() => "");
    } else {
      const index = prizeTypes?.findIndex(
        (x) => x?.value === getValues("prize_type")
      );
      setIndexPrizeType(() => prizeTypes[index] || "");
    }
  };

  const onSubmit = (data) => {
    const newParams = {
      ...params,
      ...data,
      page: 1,
    };
    setParams(newParams);
  };

  return (
    <Flex gap={4} flexWrap="wrap" w="100%" alignItems="flex-end">
      <FormProvider {...form}>
        <form
          style={{
            maxWidth: "100%",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex w="100%" flexWrap="wrap" alignItems="flex-end" gap={4}>
            <FormInput
              w="270px"
              maxW="100%"
              name="keyword"
              label={t("search")}
              placeholder={t("placeholder_search")}
            />
            <FormSelect
              name="prize_type"
              label={t("filter_prize_type")}
              value={indexPrizeType ? prizeTypes[indexPrizeType]?.value : ""}
              w="156px"
              options={[...prizeTypes]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("prize_type", e.target.value);
                  getIndexPrizeType();
                  handleSubmit(onSubmit)();
                }
              }}
            />

            <FormSelect
              name="gameplay_type_id"
              label={t("filter_gameplay_type")}
              value={
                indexGamePlay ? listGamePlayType[indexGamePlay]?.value : ""
              }
              w="180px"
              options={[{ label: "All", value: "" }, ...listGamePlayType]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("gameplay_type_id", e.target.value);
                  getIndexGamePlay(listGamePlayType);
                  handleSubmit(onSubmit)();
                }
              }}
            />

            <FormSelect
              name="booth_type"
              label={t("filter_booth_type")}
              value={
                indexBoothType
                  ? MACHINE_TYPE_OPTIONS[indexBoothType]?.value
                  : ""
              }
              w="156px"
              options={[{ label: "All", value: "" }, ...MACHINE_TYPE_OPTIONS]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("booth_type", e.target.value);
                  getIndexBoothType();
                  handleSubmit(onSubmit)();
                }
              }}
            />
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
}
