import * as yup from "yup";

const schemaMachine = () =>
  yup.object().shape({
    machine_id: yup
      .string()
      .trim()
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    status: yup.string().trim().required("Required"),
    machine_type: yup.string().trim().required("Required"),
    gameplay_type_id: yup.string().trim().required("Required"),
    game_mode_id: yup.string().trim().required("Required"),
    icon_url: yup.string().trim().required("Required"),
  });

export default schemaMachine;
