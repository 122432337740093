export const EyesOffIcon = (props) => (
  <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 3.5L21 21.5"
      stroke="#969696"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.584 11.087C10.2087 11.4621 9.99775 11.9708 9.99756 12.5013C9.99737 13.0319 10.2079 13.5408 10.583 13.916C10.958 14.2913 11.4667 14.5023 11.9973 14.5024C12.5278 14.5026 13.0367 14.2921 13.412 13.917"
      stroke="#969696"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.363 5.86506C10.2204 5.61978 11.1082 5.4969 12 5.50006C16 5.50006 19.333 7.83306 22 12.5001C21.222 13.8611 20.388 15.0241 19.497 15.9881M17.357 17.8491C15.726 18.9491 13.942 19.5001 12 19.5001C8 19.5001 4.667 17.1671 2 12.5001C3.369 10.1051 4.913 8.32506 6.632 7.15906"
      stroke="#969696"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
