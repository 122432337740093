import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import ButtonType from "components/Button";

export default function ModalConfirm({
  isOpen,
  onClose,
  title = "Delete",
  description = "Do you want delete ?",
  textCancel = "Cancel",
  textOk = "OK",
  onSubmit,
  isLoadingSubmit,
}) {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          w="100%"
          textAlign="center"
          margin="auto"
          fontSize="24px"
          fontWeight="700"
        >
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px" textAlign="center" fontSize="16px" fontWeight="500">
          {description}
        </ModalBody>

        <ModalFooter w="100%" textAlign="center" margin="auto">
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap="16px"
            w="100%"
          >
            <ButtonType
              onClick={onClose}
              mr={3}
              text={textCancel}
              btnType="primary-new-outline"
              w="100%"
            />
            <ButtonType
              isLoading={isLoadingSubmit}
              mr={3}
              text={textOk}
              onClick={onSubmit}
              w="100%"
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
