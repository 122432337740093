export const ManagementHunterIcon = (props) => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.4141 9.91406H18.7594C18.4686 5.53547 14.9645 2.03137 10.5859 1.74063V1.08594C10.5859 0.76207 10.3239 0.5 10 0.5C9.67613 0.5 9.41406 0.76207 9.41406 1.08594V1.74063C5.03547 2.03137 1.53137 5.53547 1.24063 9.91406H0.585938C0.26207 9.91406 0 10.1761 0 10.5C0 10.8239 0.26207 11.0859 0.585938 11.0859H1.24063C1.53137 15.4645 5.03547 18.9686 9.41406 19.2594V19.9141C9.41406 20.2379 9.67613 20.5 10 20.5C10.3239 20.5 10.5859 20.2379 10.5859 19.9141V19.2594C14.9645 18.9686 18.4686 15.4645 18.7594 11.0859H19.4141C19.7379 11.0859 20 10.8239 20 10.5C20 10.1761 19.7379 9.91406 19.4141 9.91406ZM10.5859 18.0875V17.5312C10.5859 17.2074 10.3239 16.9453 10 16.9453C9.67613 16.9453 9.41406 17.2074 9.41406 17.5312V18.0875C5.68172 17.8013 2.69875 14.8183 2.4125 11.0859H2.96875C3.29262 11.0859 3.55469 10.8239 3.55469 10.5C3.55469 10.1761 3.29262 9.91406 2.96875 9.91406H2.4125C2.69875 6.18172 5.68172 3.19875 9.41406 2.9125V3.46875C9.41406 3.79262 9.67613 4.05469 10 4.05469C10.3239 4.05469 10.5859 3.79262 10.5859 3.46875V2.9125C14.3183 3.19875 17.3013 6.18172 17.5875 9.91406H17.0312C16.7074 9.91406 16.4453 10.1761 16.4453 10.5C16.4453 10.8239 16.7074 11.0859 17.0312 11.0859H17.5875C17.3013 14.8183 14.3183 17.8013 10.5859 18.0875Z"
      fill="black"
    />
    <path
      d="M9.99996 4.6665C6.78343 4.6665 4.16663 7.28335 4.16663 10.4998C4.16663 13.7163 6.78347 16.3332 9.99996 16.3332C13.2164 16.3332 15.8333 13.7163 15.8333 10.4998C15.8333 7.28335 13.2164 4.6665 9.99996 4.6665ZM8.39073 11.7067C8.39073 12.0821 7.9197 12.2525 7.67934 11.9643L6.37921 10.4041V11.7067C6.37921 11.9289 6.19906 12.109 5.97691 12.109C5.75476 12.109 5.57461 11.9289 5.57461 11.7067V9.29294C5.57461 8.91764 6.04557 8.74702 6.286 9.03542L7.58614 10.5956V9.29294C7.58614 9.07079 7.76628 8.89065 7.98843 8.89065C8.21059 8.89065 8.39073 9.07079 8.39073 9.29294V11.7067ZM10.8046 10.4998C11.0267 10.4998 11.2069 10.68 11.2069 10.9021C11.2069 11.1243 11.0267 11.3044 10.8046 11.3044H9.99996V11.7067C9.99996 11.9289 9.81981 12.109 9.59766 12.109C9.37551 12.109 9.19536 11.9289 9.19536 11.7067V9.29294C9.19536 9.07079 9.37551 8.89065 9.59766 8.89065H10.8046C11.0267 8.89065 11.2069 9.07079 11.2069 9.29294C11.2069 9.5151 11.0267 9.69524 10.8046 9.69524H9.99996V10.4998H10.8046ZM14.4252 9.29294C14.4252 9.5151 14.2451 9.69524 14.0229 9.69524H13.6206V11.7067C13.6206 11.9289 13.4405 12.109 13.2183 12.109C12.9962 12.109 12.816 11.9289 12.816 11.7067V9.69524H12.4137C12.1916 9.69524 12.0114 9.5151 12.0114 9.29294C12.0114 9.07079 12.1916 8.89065 12.4137 8.89065H14.0229C14.2451 8.89065 14.4252 9.07079 14.4252 9.29294Z"
      fill="black"
    />
  </svg>
);
