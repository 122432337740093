/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { MAINTENANCE_NAMESPACE } from 'stores/constant'
import {
  addMaintenanceApi,
  deleteMaintenanceByIdApi,
  editMaintenanceByIdApi,
  getMaintenanceApi,
  getMaintenanceByIdApi,
} from './action'

const name = MAINTENANCE_NAMESPACE
const initialState = createInitialState()
const reducers = createReducers()
const extraReducers = createExtraReducers()
const maintenanceSlice = createSlice({ name, initialState, reducers, extraReducers })

export const maintenanceSliceReducer = maintenanceSlice.reducer

function createInitialState() {
  return {
    loadingMaintenance: false,
    errorMaintenance: '',
    listMaintenances: [],
    MaintenanceById: null,
    statusDelete: false,
  }
}

function createReducers() {
  return {}
}

function createExtraReducers() {
  return (builder) => {
    listMaintenances()
    addMaintenance()
    getMaintenanceById()
    editMaintenance()
    deleteMaintenanceById()

    function listMaintenances() {
      builder
        .addCase(getMaintenanceApi.pending, (state) => {
          state.loadingMaintenance = true
        })
        .addCase(getMaintenanceApi.fulfilled, (state, action) => {
          state.loadingMaintenance = false
          state.errorMaintenance = ''
          if (action.payload) {
            state.listMaintenances = action.payload
          }
        })
        .addCase(getMaintenanceApi.rejected, (state, action) => {
          state.loadingMaintenance = false
          state.errorMaintenance = action.payload
        })
    }

    function addMaintenance() {
      builder
        .addCase(addMaintenanceApi.pending, (state) => {
          state.loadingMaintenance = true
        })
        .addCase(addMaintenanceApi.fulfilled, (state, action) => {
          state.loadingMaintenance = false
          state.errorMaintenance = ''
        })
        .addCase(addMaintenanceApi.rejected, (state, action) => {
          state.loadingMaintenance = false
          state.errorMaintenance = action.payload
        })
    }

    function getMaintenanceById() {
      builder
        .addCase(getMaintenanceByIdApi.pending, (state) => {
          state.loadingMaintenance = true
          state.MaintenanceById = null
        })
        .addCase(getMaintenanceByIdApi.fulfilled, (state, action) => {
          state.loadingMaintenance = false
          state.errorMaintenance = ''
          if (action.payload) {
            state.MaintenanceById = action.payload?.data
          }
        })
        .addCase(getMaintenanceByIdApi.rejected, (state, action) => {
          state.loadingMaintenance = false
          state.errorMaintenance = action.payload
        })
    }

    function editMaintenance() {
      builder
        .addCase(editMaintenanceByIdApi.pending, (state) => {
          state.loadingMaintenance = true
        })
        .addCase(editMaintenanceByIdApi.fulfilled, (state, action) => {
          state.loadingMaintenance = false
          state.errorMaintenance = ''
        })
        .addCase(editMaintenanceByIdApi.rejected, (state, action) => {
          state.loadingMaintenance = false
          state.errorMaintenance = action.payload
        })
    }

    function deleteMaintenanceById() {
      builder
        .addCase(deleteMaintenanceByIdApi.pending, (state, action) => {
          state.statusDelete = true
        })
        .addCase(deleteMaintenanceByIdApi.fulfilled, (state, action) => {
          state.statusDelete = false
          state.errorMaintenance = ''
        })
        .addCase(deleteMaintenanceByIdApi.rejected, (state, action) => {
          state.statusDelete = false
          state.errorMaintenance = action.payload
        })
    }
  }
}
