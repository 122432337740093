import END_POINT from "./constants";
import http from "./http";

export function getRateApi() {
  return http.get(`${END_POINT.EXCHANGE_RATE}`);
}

export function updateRateApi(id, data) {
  return http.put(`${END_POINT.EXCHANGE_RATE}/${id}`, data);
}

export function updateExchangeRate(data) {
  return http.post(`${END_POINT.EXCHANGE_RATE}`, data);
}

export function getExchangeRateCurrency() {
  return http.get(`${END_POINT.EXCHANGE_RATE_CURRENCY}`);
}
