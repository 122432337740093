import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getGenesisNFTs = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.NFT_GENESIS}?${params}`);
};

export const getDetailGenesisNFT = (id) => {
  return http.get(`${END_POINT.NFT_GENESIS}/${id}`);
};

export const importCSVGenesisNFT = (id, data) => {
  return http.post(`${END_POINT.NFT_GENESIS}/${id}/import-csv`, data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
};
