/* eslint-disable no-fallthrough */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import FormTextAria from "components/form/FormTextAria";
import { FormProvider, useForm } from "react-hook-form";
import schemaPrize from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  editProjectCategoryByIdApi,
} from "stores/projectCategory/action";
import FormLabelComponent from "components/form/FormLabel";
import FormDatePicker from "components/form/FormDatePicker";
import { InfoIcon } from "@chakra-ui/icons";
import FormSelect from "components/form/FormSelect";
import ButtonType from "components/Button";
import FormCheckbox from "components/form/FormCheckbox";
import { CODE_MAINTENANCE } from "constants/constants";
import { postMaintenance, putMaintenance } from "api/maintenance.api";
import useWithToast from "hooks/useWithToast";
import moment from "moment";
import { addDays, addHours, endOfDay, isBefore, startOfHour } from 'date-fns';

export default function MantenanceCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
  handleViewClose,
}) {
  const { t } = useTranslation("mantenance");
  const textColor = useColorModeValue("#000000", "white");
  const { showToastSuccess, showToastFail } = useWithToast();
  const [selectedDate, setSelectedDate] = useState(null);

  const defaultValues = {
    code: CODE_MAINTENANCE[0].value,
    title: "",
    start_time: "",
    end_time: "",
    notification_flg: false,
    notification_time: "",
    message: "",
    content: "",
  };

  const form = useForm({
    resolver: yupResolver(schemaPrize()),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isSubmitting },
  } = form;

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  useEffect(() => {
    if (dataDetail) {
      reset({
        code: dataDetail?.code,
        title: dataDetail?.title,
        start_time: new Date(dataDetail?.start_time*1000)?.toISOString(),
        end_time: new Date(dataDetail?.end_time*1000)?.toISOString(),
        notification_flg: dataDetail.notification_flg,
        notification_time: dataDetail?.notification_time ? new Date(dataDetail?.notification_time*1000)?.toISOString() : "",
        message: dataDetail?.message,
        content: dataDetail?.content,
      });
    } else {
      reset();
    }
  }, [dataDetail]);

  const handleClose = () => {
    reset();
    handleViewClose();
    onClose();
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const params = {
      code: data.code,
      title: data.title,
      start_time: new Date(data.start_time).toISOString(),
      end_time: data.end_time ? new Date(data.end_time).toISOString() : "",
      notification_flg: data.notification_flg,
      notification_time: data.notification_time ? new Date(data.notification_time).toISOString() : "",
    }
    try {
      if (idEdit) {
        const res = await putMaintenance(idEdit, { 
          ...params 
        });
        if (res) {
          showToastSuccess({
            title: "Maintenance updated successfully.",
          });
          reset();
          handleCreateOrUpdateSuccess();
        }
      } else {
        const res = await postMaintenance({ ...params });
        if (res?.success) {
          showToastSuccess({
            title: "Maintenance added successfully.",
          });
          reset();
          handleCreateOrUpdateSuccess();
        }
      }
    } catch (error) {
      console.log("error", error);
      showToastFail({
        title: error?.message[0],
      })
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="create-user-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <FormSelect
              label={t("input_maintenance_code")}
              name="code"
              maxLength={30}
              isRequired
              defaultValue={CODE_MAINTENANCE[0]}
              options={CODE_MAINTENANCE}
              onChange={(e) => {
                setValue("code", e.target.value);
              }}
            />
            <FormInput
              label={t("input_maintenance_title")}
              name="title"
              maxLength={300}
              isRequired
              placeholder="Enter maintenance title"
            />
            <Flex flexDirection={"column"} w="100%">
              <FormLabelComponent title={t("input_start_time")} isRequired />
              <FormDatePicker
                classNames="custom-date"
                w="100%"
                name="start_time"
                dateFormat="MM-dd-YYYY HH:mm:ss"
                showTimeSelect
                placeholderText="MM/DD/YYYY"
                selected={selectedDate}
                onChange={(date) => {
                  setSelectedDate(date)
                  setValue("start_time", date)
                }}
                minDate={new Date()} // Disable past dates and times plus 1 hour
                minTime={addHours(startOfHour(new Date()), 2)} // Ensure time is also considered
                maxTime={endOfDay(addHours(startOfHour(new Date()), 24))} // Ensure time is also considered
                timeIntervals={15}
              />
            </Flex>
            <Flex alignItems="center">
              <FormCheckbox
                size="lg"
                name="lockStatus"
              />
              <FormLabelComponent marginBottom="0px" title="Schedule end time" />
            </Flex>
            <Flex flexDirection={"column"}>
              <FormLabelComponent title={t("input_end_time")} isRequired/>
              <FormDatePicker
                classNames="custom-date"
                w="100%"
                name="end_time"
                dateFormat="MM-dd-YYYY HH:mm:ss"
                showTimeSelect
                placeholderText="MM/DD/YYYY"
                minDate={addDays(selectedDate, 1)} // Disable past dates and times plus 1 hour
                disabled={!selectedDate}
              />
            </Flex>
            <Flex alignItems="center">
              <FormCheckbox
                size="lg"
                name="notification_flg"
              />
              <FormLabelComponent marginBottom="0px" title="Notify" />
            </Flex>
            <Flex
              alignItems={"center"}
              gap="16px"
            >
              <GridItem colSpan={3} width="100%">
                <FormSelect
                  name="system_coin"
                  disabled={!watch("notification_flg")}
                  // defaultValue={dataDetail?.system_coin ? dataDetail?.system_coin : UMIT_OPTIONS[0].value}
                  // options={}
                />
              </GridItem>
              <GridItem colSpan={1}>
                <Flex
                  justifyContent={"space-evenly"}
                  height={"34px"}
                  lineHeight={"36px"}
                  color={textColor}
                >
                  {t("input_or")}
                </Flex>
              </GridItem>
              <GridItem colSpan={3} width="100%">
                <FormInput
                  name="notification_time"
                  maxLength={30}
                  W={"100%"}
                  placeholder={t("placeholder_specify")}
                  disabled={!watch("notification_flg")}
                />
              </GridItem>
            </Flex>
            <Grid templateColumns="repeat(3, 1fr)" gap={4} mt={6}>
              <GridItem colSpan={3}>
                <Flex flexDirection={"column"}>
                  <Flex>
                    <FormLabelComponent title={t("input_notification_message")} />
                    <Icon as={InfoIcon} />
                  </Flex>
                  <FormTextAria placeholder="Maintenance will start at {startdate}" name="message" maxLength={300} rows={5} />
                </Flex>
              </GridItem>
            </Grid>
            <Grid templateColumns="repeat(3, 1fr)" gap={4} mt={6}>
              <GridItem colSpan={3}>
                <Flex flexDirection={"column"}>
                  <Flex>
                    <FormLabelComponent
                      title={t("input_notification_site_content")}
                    />
                    <Icon as={InfoIcon} />
                  </Flex>
                  <FormTextAria placeholder="Input maintenance site content" name="content" maxLength={300} rows={8} />
                </Flex>
              </GridItem>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="685px">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit
            ? t("edit_maintenance_setting_title")
            : t("create_maintenance_setting_title")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          display="flex"
          alignItems="center"
          gap="16px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="create-user-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="create-user-form"
            isLoading={isSubmitting}
          >
            {t("btn_submit")}
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
