/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Chakra imports
// import { ViewIcon } from '@chakra-ui/icons'
import {
  Box,
  Checkbox,
  // Flex,
  // Icon,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react'
import { getDateUTCLLLL } from 'utils/time'
// import { EditIcon } from 'components/Icons/EditIcon'
import { UMIT_OPTIONS } from 'constants/constants'

const PlayFeeRow = ({
  checkedIds,
  setCheckedIds,
  // handleOpenCreateOrUpdate,
  listPlayFee,
}) => {
  const textColor = useColorModeValue('#000000', 'white')

  const handleCheckboxChange = () => {
    if (checkedIds.includes(listPlayFee?.id)) {
      setCheckedIds(checkedIds.filter((checkedId) => checkedId !== listPlayFee?.id))
    } else {
      setCheckedIds([...checkedIds, listPlayFee?.id])
    }
  }

  const filterSystemCoin = (systemCoin = 'C_COIN_1') => {
    return UMIT_OPTIONS?.find((unit) => unit.value === systemCoin)?.label
  }

  return (
    <Tr key={listPlayFee?.id}>
      {/* <Td width='10px'>
        <Checkbox
          isChecked={checkedIds.includes(listPlayFee?.id)}
          onChange={() => handleCheckboxChange()}
          colorScheme='teal'
        />
      </Td> */}
      <Td minW='150px' maxW='150px'>
        <Text color={textColor} fontSize='sm' fontWeight={400}>
          {listPlayFee?.value}
        </Text>
      </Td>
      <Td minW='150px' maxW='150px'>
        <Text color={textColor} fontSize='sm' fontWeight={400}>
          {filterSystemCoin(listPlayFee?.system_coin) || '---'}
        </Text>
      </Td>
      <Td minW='150px' maxW='150px'>
        <Text color={textColor} fontSize='sm' fontWeight={400}>
          {listPlayFee?.cp_exchange_rate}
        </Text>
      </Td>
      <Td minW='200px'>
        <Text color={textColor} fontSize='sm' fontWeight={400}>
          {listPlayFee?.update_time
            ? getDateUTCLLLL(listPlayFee?.update_time * 1000)
            : '---'}
        </Text>
      </Td>
      <Td minW='150px'>
        <Text color={textColor} fontSize='sm' fontWeight={400}>
          {listPlayFee?.last_modified_by || 'Admin'}
        </Text>
      </Td>
      <Td minW='200px'>
        <Text color={textColor} fontSize='sm' fontWeight={400}>
          {listPlayFee?.create_time
            ? getDateUTCLLLL(listPlayFee?.create_time * 1000)
            : '---'}
        </Text>
      </Td>
      <Td minW='150px'>
        <Text color={textColor} fontSize='sm' fontWeight={400}>
          {listPlayFee?.create_modified_by || 'Admin'}
        </Text>
      </Td>
      {/* <Td>
        <Flex gap={6} align="center">
          <EditIcon
            onClick={() => handleOpenCreateOrUpdate()}
            width="12px"
            height="12px"
            cursor='pointer'
          />
        </Flex>
      </Td> */}
    </Tr>
  )
}

export default PlayFeeRow
