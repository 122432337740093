/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { PLAY_FEE_NAMESPACE } from 'stores/constant'
import {
  addPlayFeeApi,
  deletePlayFeeByIdApi,
  editPlayFeeByIdApi,
  getPlayFeeApi,
  getPlayFeeByIdApi,
} from './action'

const name = PLAY_FEE_NAMESPACE
const initialState = createInitialState()
const reducers = createReducers()
const extraReducers = createExtraReducers()
const playFeeSlice = createSlice({ name, initialState, reducers, extraReducers })

export const playFeeSliceReducer = playFeeSlice.reducer

function createInitialState() {
  return {
    loadingPlayFee: false,
    errorPlayFee: '',
    listPlayFees: [],
    playFeeById: null,
    statusDelete: false,
  }
}

function createReducers() {
  return {}
}

function createExtraReducers() {
  return (builder) => {
    listPlayFees()
    addPlayFee()
    getPlayFeeById()
    editPlayFee()
    deletePlayFeeById()

    function listPlayFees() {
      builder
        .addCase(getPlayFeeApi.pending, (state) => {
          state.loadingPlayFee = true
        })
        .addCase(getPlayFeeApi.fulfilled, (state, action) => {
          state.loadingPlayFee = false
          state.errorPlayFee = ''
          if (action.payload) {
            state.listPlayFees = action.payload
          }
        })
        .addCase(getPlayFeeApi.rejected, (state, action) => {
          state.loadingPlayFee = false
          state.errorPlayFee = action.payload
        })
    }

    function addPlayFee() {
      builder
        .addCase(addPlayFeeApi.pending, (state) => {
          state.loadingPlayFee = true
        })
        .addCase(addPlayFeeApi.fulfilled, (state, action) => {
          state.loadingPlayFee = false
          state.errorPlayFee = ''
        })
        .addCase(addPlayFeeApi.rejected, (state, action) => {
          state.loadingPlayFee = false
          state.errorPlayFee = action.payload
        })
    }

    function getPlayFeeById() {
      builder
        .addCase(getPlayFeeByIdApi.pending, (state) => {
          state.loadingPlayFee = true
          state.PlayFee = null
        })
        .addCase(getPlayFeeByIdApi.fulfilled, (state, action) => {
          state.loadingPlayFee = false
          state.errorPlayFee = ''
          if (action.payload) {
            state.playFeeById = action.payload?.data
          }
        })
        .addCase(getPlayFeeByIdApi.rejected, (state, action) => {
          state.loadingPlayFee = false
          state.errorPlayFee = action.payload
        })
    }

    function editPlayFee() {
      builder
        .addCase(editPlayFeeByIdApi.pending, (state) => {
          state.loadingPlayFee = true
        })
        .addCase(editPlayFeeByIdApi.fulfilled, (state, action) => {
          state.loadingPlayFee = false
          state.errorPlayFee = ''
        })
        .addCase(editPlayFeeByIdApi.rejected, (state, action) => {
          state.loadingPlayFee = false
          state.errorPlayFee = action.payload
        })
    }

    function deletePlayFeeById() {
      builder
        .addCase(deletePlayFeeByIdApi.pending, (state, action) => {
          state.statusDelete = true
        })
        .addCase(deletePlayFeeByIdApi.fulfilled, (state, action) => {
          state.statusDelete = false
          state.errorPlayFee = ''
        })
        .addCase(deletePlayFeeByIdApi.rejected, (state, action) => {
          state.statusDelete = false
          state.errorPlayFee = action.payload
        })
    }
  }
}
