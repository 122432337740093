import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import FormDatePicker from "components/form/FormDatePicker";
import FormLabelComponent from "components/form/FormLabel";
import moment from "moment";

export default function SelectDateCreateEvent({
  watch,
  setValue,
  transLabel,
  disableWhenJP,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const dateFormat = "dd MMM. YYYY HH:mm:ss";
  const dateFormatMoment = "DD MMM. YYYY HH:mm:ss";
  const formatResult = "YYYY-MM-DD HH:mm:ss";
  const watchRegisterStartTime = watch("register_start_time");
  const watchWhiteListStartTime = watch("whitelist_start_time");
  const watchEventStartTime = watch("event_start_time");
  const watchResultStartTime = watch("result_start_time");

  return (
    <Flex direction="column" gap={4}>
      <Flex rowGap={4} columnGap={16}>
        <Flex flex={1} direction="column" gap="8px">
          <FormLabelComponent
            m="0px"
            title={transLabel("preStart")}
            isRequired
          />
          <FormDatePicker
            disabled={disableWhenJP}
            name="pre_register_start_time"
            showTimeSelect
            dateFormat={dateFormat}
            h="40px"
            // onChange={() => {}}
          />
        </Flex>
        <Flex flex={1} direction="column" gap="6px">
          <FormLabelComponent m="0px" title={transLabel("preEnd")} isRequired />
          <FormDatePicker
            name="pre_register_end_time"
            showTimeSelect
            dateFormat={dateFormat}
            disabled={disableWhenJP}
            h="40px"
            onChange={(e) => {
              const time = moment(e).format(formatResult);
              setValue("pre_register_end_time", time);
              setValue("register_start_time", time);
            }}
          />
        </Flex>
      </Flex>
      <Flex rowGap={4} columnGap={16}>
        <Flex flex={1} direction="column" gap="8px">
          <FormLabelComponent
            m="0px"
            title={transLabel("regisStart")}
            isRequired
          />
          <Text w="239px" mt="4px" pl={4} color={textColor}>
            {moment(watchRegisterStartTime).format(dateFormatMoment)}
          </Text>
        </Flex>
        <Flex flex={1} direction="column" gap="6px">
          <FormLabelComponent
            m="0px"
            title={transLabel("regisEnd")}
            isRequired
          />
          <FormDatePicker
            disabled={disableWhenJP}
            name="register_end_time"
            showTimeSelect
            dateFormat={dateFormat}
            h="40px"
            onChange={(e) => {
              const time = moment(e).format(formatResult);
              setValue("register_end_time", time);
              setValue("whitelist_start_time", time);
            }}
          />
        </Flex>
      </Flex>
      <Flex rowGap={4} columnGap={16}>
        <Flex flex={1} direction="column" gap="8px">
          <FormLabelComponent
            m="0px"
            title={transLabel("whiteListStart")}
            isRequired
          />
          <Text w="239px" mt="4px" pl={4} color={textColor}>
            {moment(watchWhiteListStartTime).format(dateFormatMoment)}
          </Text>
        </Flex>
        <Flex flex={1} direction="column" gap="6px">
          <FormLabelComponent
            m="0px"
            title={transLabel("WhiteListEnd")}
            isRequired
          />
          <FormDatePicker
            disabled={disableWhenJP}
            name="whitelist_end_time"
            showTimeSelect
            dateFormat={dateFormat}
            h="40px"
            onChange={(e) => {
              const time = moment(e).format(formatResult);
              setValue("whitelist_end_time", time);
              setValue("event_start_time", time);
            }}
          />
        </Flex>
      </Flex>
      <Flex rowGap={4} columnGap={16}>
        <Flex flex={1} direction="column" gap="8px">
          <FormLabelComponent
            m="0px"
            title={transLabel("eventStart")}
            isRequired
          />
          <Text w="239px" mt="4px" pl={4} color={textColor}>
            {moment(watchEventStartTime).format(dateFormatMoment)}
          </Text>
        </Flex>
        <Flex flex={1} direction="column" gap="6px">
          <FormLabelComponent
            m="0px"
            title={transLabel("eventEnd")}
            isRequired
          />
          <FormDatePicker
            disabled={disableWhenJP}
            name="event_end_time"
            showTimeSelect
            dateFormat={dateFormat}
            h="40px"
            onChange={(e) => {
              const time = moment(e).format(formatResult);
              setValue("event_end_time", time);
              setValue("result_start_time", time);
            }}
          />
        </Flex>
      </Flex>
      <Flex rowGap={4} columnGap={16}>
        <Flex flex={1} direction="column" gap="8px">
          <FormLabelComponent
            m="0px"
            title={transLabel("resultStart")}
            isRequired
          />
          <Text w="239px" mt="4px" pl={4} color={textColor}>
            {moment(watchResultStartTime).format(dateFormatMoment)}
          </Text>
        </Flex>
        <Flex flex={1} direction="column" gap="6px">
          <FormLabelComponent
            m="0px"
            title={transLabel("resultEnd")}
            isRequired
          />
          <FormDatePicker
            disabled={disableWhenJP}
            name="result_end_time"
            showTimeSelect
            dateFormat={dateFormat}
            h="40px"
            onChange={(e) => {
              const time = moment(e).format(formatResult);
              setValue("result_end_time", time);
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
