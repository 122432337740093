import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createNewEvent,
  editEvent,
  getDetailEventManagement,
  getRulesEvent,
} from "api/event.api";
import { getSystemTicket } from "api/system";
import ButtonType from "components/Button";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { OPTIONS_REWARDS } from "views/Event/constant";
import {
  convertToIsoString,
  convertToUTCTime,
  dateAfterNow,
} from "views/Event/help";
import SelectPrize from "./SelectPrize";
import schemaCreateEvent from "./schema";
import EventTabPanel from "./tabPanel";

export default function CreateEvent({
  isOpen,
  onClose,
  dataDetail,
  handleUpdateSuccess,
}) {
  const [rules, setRules] = useState([]);
  const [detailEvent, setDetailEvent] = useState();
  const [tickets, setTickets] = useState([]);

  const [tabIndex, setTabIndex] = useState(0);
  const [trans, setTrans] = useState("en");

  const textColor = useColorModeValue("#000000", "white");
  const {
    isOpen: isOpenSelectPrize,
    onOpen: onOpenSelectPrize,
    onClose: onCloseSelectPrize,
  } = useDisclosure();

  const toast = useToast();

  const defaultDataReward = {
    item: OPTIONS_REWARDS[0].value,
    amount: null,
  };
  const defaultDataFinalPrize = {
    ranking_from: null,
    ranking_to: null,
    rewards: [defaultDataReward],
  };
  const form = useForm({
    resolver: yupResolver(schemaCreateEvent()),
    defaultValues: {
      ranking_from_to: "ranking",
      eventPrizes: [],
      finalPrizes: [],
    },
  });

  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { isSubmitting },
  } = form;

  const {
    fields: fieldEventPrizes,
    append: appendEventPrize,
    remove: removeEventPrize,
  } = useFieldArray({
    control,
    name: "eventPrizes",
  });

  const {
    fields: fieldFinalPrizes,
    append: appendFinalPrize,
    remove: removeFinalPrize,
  } = useFieldArray({
    control,
    name: "finalPrizes",
  });

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const getRules = async () => {
    try {
      const { data } = await getRulesEvent({
        page: 1,
        limit: 999999,
      });
      if (data?.success) {
        let rulesEvent = [];
        if (data?.data?.records && data?.data?.records?.length > 0) {
          rulesEvent = data?.data?.records?.map((item) => ({
            label: item?.name,
            value: item?.id,
          }));
        }
        setRules(rulesEvent);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const getDetailEvent = useCallback(async () => {
    try {
      if (idEdit) {
        const { data } = await getDetailEventManagement(idEdit);
        if (data?.success) {
          const event = data?.data;
          setDetailEvent(event);
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }, [idEdit]);

  useEffect(() => {
    getRules();
  }, []);

  useEffect(() => {
    getDetailEvent();
  }, [getDetailEvent]);

  const getTicket = async () => {
    try {
      const { data } = await getSystemTicket({
        limit: 999999,
      });
      if (data?.success) {
        const list = data?.data?.records?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));
        setTickets(list);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    getTicket();
  }, []);

  useEffect(() => {
    if (detailEvent?.id) {
      let eventPrizes = [];
      let finalPrizes = [];
      if (detailEvent?.event_prizes && detailEvent?.event_prizes?.length > 0) {
        eventPrizes = detailEvent?.event_prizes?.map((item) => {
          return {
            event_id: item?.event_id,
            ...item?.prize,
          };
        });
      }
      if (
        detailEvent?.event_rewards &&
        detailEvent?.event_rewards?.length > 0
      ) {
        finalPrizes = detailEvent?.event_rewards?.map((item) => {
          let itemReward = [];
          if (
            item?.reward?.reward_items &&
            item?.reward?.reward_items?.length > 0
          ) {
            itemReward = item?.reward?.reward_items?.map((itemReward) => {
              if (itemReward?.type === "TICKET") {
                return {
                  idReward: item?.reward?.id,
                  idItem: itemReward?.id,
                  item: itemReward?.type,
                  type: itemReward?.item_type_id,
                  amount: itemReward?.value,
                };
              }
              if (itemReward?.type?.includes("COIN")) {
                return {
                  idReward: item?.reward?.id,
                  idItem: itemReward?.id,
                  item: itemReward?.item_type_id,
                  type: itemReward?.item_type_id,
                  amount: itemReward?.value,
                };
              }
            });
          }
          return {
            id: item?.id,
            ranking_from: item?.ranking_from,
            ranking_to: item?.ranking_to,
            rewards: itemReward,
          };
        });
      }
      const itemJP = detailEvent?.translations?.find(
        (item) => item?.lang_key === "JP"
      );
      reset({
        pre_register_start_time: detailEvent?.pre_register_start_time
          ? convertToUTCTime(detailEvent?.pre_register_start_time)
          : dateAfterNow(0),
        pre_register_end_time: detailEvent?.pre_register_end_time
          ? convertToUTCTime(detailEvent?.pre_register_end_time)
          : dateAfterNow(1),
        register_start_time: detailEvent?.register_start_time
          ? convertToUTCTime(detailEvent?.register_start_time)
          : dateAfterNow(1),
        register_end_time: detailEvent?.register_end_time
          ? convertToUTCTime(detailEvent?.register_end_time)
          : dateAfterNow(2),
        whitelist_start_time: detailEvent?.whitelist_start_time
          ? convertToUTCTime(detailEvent?.whitelist_start_time)
          : dateAfterNow(2),
        whitelist_end_time: detailEvent?.whitelist_end_time
          ? convertToUTCTime(detailEvent?.whitelist_end_time)
          : dateAfterNow(3),
        event_start_time: detailEvent?.event_start_time
          ? convertToUTCTime(detailEvent?.event_start_time)
          : dateAfterNow(3),
        event_end_time: detailEvent?.event_end_time
          ? convertToUTCTime(detailEvent?.event_end_time)
          : dateAfterNow(4),
        result_start_time: detailEvent?.result_start_time
          ? convertToUTCTime(detailEvent?.result_start_time)
          : dateAfterNow(4),
        result_end_time: detailEvent?.result_end_time
          ? convertToUTCTime(detailEvent?.result_end_time)
          : dateAfterNow(5),
        name: detailEvent?.name,
        name_jp: itemJP?.name,
        description: detailEvent?.description,
        description_jp: itemJP?.description,
        translations: detailEvent?.translations,
        finalPrizes,
        eventPrizes,
        rule_id: detailEvent?.rule_id,
        status: detailEvent?.status,
        ranking_from_to: "ranking",
      });
    } else {
      reset({
        pre_register_start_time: dateAfterNow(0),
        pre_register_end_time: dateAfterNow(1),
        register_start_time: dateAfterNow(1),
        register_end_time: dateAfterNow(2),
        whitelist_start_time: dateAfterNow(2),
        whitelist_end_time: dateAfterNow(3),
        event_start_time: dateAfterNow(3),
        event_end_time: dateAfterNow(4),
        result_start_time: dateAfterNow(4),
        result_end_time: dateAfterNow(5),
        eventPrizes: [],
        finalPrizes: [],
        rule_id: rules[0] ? rules[0].value : "",
        ranking_from_to: "ranking",
        status: "OPEN",
      });
    }
  }, [reset, rules, detailEvent]);

  const onSubmit = async (data) => {
    let event_prizes = [];
    let event_rewards = [];
    let translations = [];
    if (idEdit) {
      const itemJP = data?.translations?.find(
        (item) => item?.lang_key === "JP"
      );
      const itemEN = data?.translations?.find(
        (item) => item?.lang_key === "EN"
      );
      translations = [
        {
          id: itemEN?.id,
          event_id: itemEN?.event_id,
          lang_key: "EN",
          name: data?.name,
          description: data?.description,
        },
        {
          id: itemJP?.id,
          event_id: itemJP?.event_id,
          lang_key: "JP",
          name: data?.name_jp,
          description: data?.description_jp,
        },
      ];
    } else {
      translations = [
        {
          id: null,
          event_id: null,
          lang_key: "EN",
          name: data?.name,
          description: data?.description || "",
        },
        {
          id: null,
          event_id: null,
          lang_key: "JP",
          name: data?.name_jp,
          description: data?.description_jp || "",
        },
      ];
    }

    if (data?.eventPrizes?.length > 0) {
      event_prizes = data?.eventPrizes?.map((item) => ({
        prize_id: item?.id,
      }));
    }
    if (data?.finalPrizes?.length > 0) {
      event_rewards = data?.finalPrizes?.map((item) => {
        let reward_items = [];
        if (item?.rewards && item?.rewards?.length > 0) {
          reward_items = item?.rewards?.map((itemReward) => {
            if (itemReward?.item?.includes("COIN")) {
              return {
                idReward: itemReward?.idReward,
                id: itemReward?.idItem,
                type: "COIN",
                item_type_id: itemReward?.item,
                value: itemReward?.amount,
              };
            } else if (itemReward?.item?.includes("TICKET")) {
              return {
                idReward: itemReward?.idReward,
                id: itemReward?.idItem,
                type: "TICKET",
                item_type_id: itemReward?.type,
                value: itemReward?.amount,
              };
            }
          });
        }
        return {
          id: item?.id,
          ranking_from: item?.ranking_from,
          ranking_to: item?.ranking_to,
          reward: {
            name: `Event Reward - Top ${
              item?.ranking_from === item?.ranking_to
                ? item?.ranking_from
                : `${item?.ranking_from} - ${item?.ranking_to}`
            }`,
            id: reward_items?.length > 0 && reward_items[0].idReward,
            type: "EVENT",
            reward_items,
          },
        };
      });
    }
    const dataSubmit = {
      pre_register_start_time: convertToIsoString(
        data?.pre_register_start_time
      ),
      pre_register_end_time: convertToIsoString(data?.pre_register_end_time),
      register_start_time: convertToIsoString(data?.register_start_time),
      register_end_time: convertToIsoString(data?.register_end_time),
      whitelist_start_time: convertToIsoString(data?.whitelist_start_time),
      whitelist_end_time: convertToIsoString(data?.whitelist_end_time),
      event_start_time: convertToIsoString(data?.event_start_time),
      event_end_time: convertToIsoString(data?.event_end_time),
      result_start_time: convertToIsoString(data?.result_start_time),
      result_end_time: convertToIsoString(data?.result_end_time),
      name: data?.name,
      description: data?.description || "",
      rule_id: data?.rule_id,
      status: data?.status,
      event_prizes,
      event_rewards,
      translations,
    };

    try {
      if (idEdit) {
        const { data } = await editEvent(idEdit, dataSubmit);
        if (data?.success) {
          toast({
            title: "Event updated.",
            description: "Event updated.",
            status: "success",
            duration: 3000,
            position: "top",
          });
          handleUpdateSuccess();
        }
      } else {
        const { data } = await createNewEvent(dataSubmit);
        if (data?.success) {
          toast({
            title: "Event created.",
            description: "Event created.",
            status: "success",
            duration: 3000,
            position: "top",
          });
          handleUpdateSuccess();
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleTabsChange = (index) => {
    setTabIndex(index);
    if (index === 1) {
      setTrans("jp");
    } else {
      setTrans("en");
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="create-event-form" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            mb={4}
            index={tabIndex}
            variant="unstyled"
            onChange={handleTabsChange}
          >
            <TabList gap={2}>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                English
              </Tab>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                Japanese
              </Tab>
            </TabList>
          </Tabs>
          <EventTabPanel
            trans={trans}
            rules={rules}
            watch={watch}
            setValue={setValue}
            fieldEventPrizes={fieldEventPrizes}
            removeEventPrize={removeEventPrize}
            onOpenSelectPrize={onOpenSelectPrize}
            appendFinalPrize={appendFinalPrize}
            defaultDataFinalPrize={defaultDataFinalPrize}
            fieldFinalPrizes={fieldFinalPrizes}
            defaultDataReward={defaultDataReward}
            removeFinalPrize={removeFinalPrize}
            tickets={tickets}
            control={control}
          />
        </form>
      </FormProvider>
    );
  };

  const checkDuplicateItem = (arr1, arr2) => {
    let idSet = new Set(arr2.map((item) => item.id));
    for (let item of arr1) {
      if (idSet.has(item?.id)) {
        return true;
      }
    }

    return false;
  };

  const onAddPrize = (listItem) => {
    if (listItem && listItem?.length > 0) {
      if (fieldEventPrizes && fieldEventPrizes?.length > 0) {
        const isDup = checkDuplicateItem(listItem, fieldEventPrizes);
        if (isDup) {
          toast({
            description: "Duplicate Item",
            status: "error",
            position: "bottom-right",
          });
          return;
        }
      }
      appendEventPrize(listItem);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent
          maxW={{
            base: "100%",
            md: "95%",
            xl: "1389px",
          }}
          overflowY="auto"
        >
          <ModalHeader
            borderBottom="1px solid #EEEFF2"
            w="100%"
            textAlign="center"
            margin="auto"
            color={textColor}
          >
            {idEdit ? "EDIT EVENT" : "NEW EVENT"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            p={{
              base: "24px 40px",
              md: "24px 80px",
            }}
            minW="620px"
          >
            {renderForm()}
          </ModalBody>
          <ModalFooter
            borderTop="1px solid #EEEFF2"
            w="100%"
            textAlign="center"
            margin="auto"
          >
            <ButtonType
              mt={4}
              type="submit"
              m="auto"
              w="120px"
              h="46px"
              borderRadius="5px"
              form="create-event-form"
              isLoading={isSubmitting}
              text="SAVE"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isOpenSelectPrize && (
        <SelectPrize
          isOpen={isOpenSelectPrize}
          onClose={onCloseSelectPrize}
          onAddPrize={onAddPrize}
        />
      )}
    </>
  );
}
