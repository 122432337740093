export const IconCamera = (props) => (
  <svg
    width={35}
    height={26}
    viewBox="0 0 35 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_3517_141)">
      <rect x={4} width={19} height={18} rx={3} fill="white" />
      <path d="M31 17.5V0.5L22.5 6.5V11.5L31 17.5Z" fill="white" />
    </g>
    <defs>
      <filter
        id="filter0_d_3517_141"
        x={0}
        y={0}
        width={35}
        height={26}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3517_141"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3517_141"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
