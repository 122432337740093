import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createAdminApi, updateAdminApi } from "api/admin.api";
import { getRoles } from "api/role.api";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import FormRadio from "components/form/FormRadio";
import { ROLE_OPTIONS } from "constants/constants";
import useWithToast from "hooks/useWithToast";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { schemaAdminManagement, schemaAdminManagementEdit } from "./schema";

export default function AdminManagementCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const { showToastSuccess } = useWithToast();

  const [roles, setRoles] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);

  const filterRoles = ["SUPPER_ADMIN", "USER"];

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const form = useForm({
    resolver: yupResolver(
      idEdit ? schemaAdminManagementEdit() : schemaAdminManagement()
    ),
    defaultValues: {
      username: "",
      password: "",
      role: null,
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = form;

  const getRoleData = async () => {
    try {
      const { data } = await getRoles();
      const list = data?.data?.records;
      if (list?.length > 0) {
        setRoles(list);
        setRoleOptions(
          list
            ?.filter((item) => !filterRoles.includes(item?.name))
            ?.map((item) => ({
              label: ROLE_OPTIONS[item?.name],
              value: item?.id,
            }))
        );
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    getRoleData();
    if (dataDetail) {
      reset({
        username: dataDetail?.username,
        role: dataDetail?.roles?.[0]?.id,
      });
    } else {
      reset();
    }
  }, [dataDetail]);

  const onSubmit = async (data) => {
    const role = roles?.find((item) => item?.id === data?.role);
    const dataSubmit = {
      username: data?.username,
      email: data?.email,
      rules: role?.rules,
      roles: [
        {
          id: role?.id,
          name: role?.name,
          orderBy: role?.orderBy,
          rules: role?.rules,
        },
      ],
    };
    try {
      if (idEdit) {
        const res = await updateAdminApi({
          id: idEdit,
          ...dataSubmit,
        });
        if (res?.data?.success) {
          showToastSuccess({
            title: "Successfully edited",
          });
          handleCreateOrUpdateSuccess();
        }
      } else {
        const res = await createAdminApi({
          password: data?.password,
          ...dataSubmit,
        });
        if (res?.data?.success) {
          showToastSuccess({
            title: "Successfully created admin",
          });
          handleCreateOrUpdateSuccess();
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column" gap={4}>
            <FormInput
              label="Email"
              name="email"
              placeholder="Enter username"
            />
            <FormInput
              label="Username"
              name="username"
              placeholder="Enter username"
            />
            {!idEdit && (
              <FormInput
                label="Password"
                name="password"
                placeholder="Enter password"
                autocomplete="new-password"
                type="password"
                renderRight
              />
            )}

            <Flex direction="column" alignItems="flex-start" gap="8px">
              <Text fontSize="16px" fontWeight="500">
                Role
              </Text>
              <Flex alignItems="center" gap="70px">
                <FormRadio name="role" options={roleOptions} />
              </Flex>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="685px" overflowY="auto">
        <ModalHeader
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit" : "Create Admin"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px" minW="500px">
          {renderForm()}
        </ModalBody>
        <ModalFooter w="100%" textAlign="center" margin="auto">
          <ButtonType
            mt={4}
            btnType="primary"
            type="submit"
            m="auto"
            w="120px"
            h="46px"
            fontSize="16px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
