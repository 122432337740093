import { STATUS } from "constants/constants";

export const MACHINE_TYPE_OPTIONS = [
  { label: "2 Claws", value: "TWO_CLAWS" },
  { label: "3 Claws", value: "THREE_CLAWS" },
];

export const STATE_OPTIONS = [
  { label: "Maintenance", value: "NO_ACTIVE" },
  { label: "Available", value: "ACTIVE" },
];

export const DEDICATED_TO = {
  [STATUS.FREE]: "Free play",
  [STATUS.PRIZE]: "Get Prize",
  [STATUS.EVENT]: "Event",
};

export const DIRECTION = {
  [STATUS.LEFT]: "Left",
  [STATUS.RIGHT]: "Right",
};

export const DEDICATED_TO_OPTIONS = [
  { label: "Free play", value: STATUS.FREE },
  { label: "Get Prize", value: STATUS.PRIZE },
  { label: "Event", value: STATUS.EVENT },
];
