import { Icon, ViewIcon } from "@chakra-ui/icons";
import { Flex, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { PlayHistoryIcon } from "components/Icons/PlayHistoryIcon";
import { PlayPrizeIcon } from "components/Icons/PlayPrizeIcon";
import moment from "moment";
import { useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { trimCharacter } from "utils/string";
import { STATUS_USER_OPTIONS } from "views/Users/UserManagement/constant";

export default function UserManagementRow({
  item,
  checkedIds,
  setCheckedIds,
  handleOpenEditModal,
  handleOpenPrizeHistoryModal,
  handleOpenHistoryPlayGame,
  handleOpenDetailUserModal,
}) {
  const textColor = useColorModeValue("#000000", "white");

  const isChecked = useMemo(() => {
    return checkedIds.some((itemCheck) => itemCheck?.id === item?.id);
  }, [item?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isChecked) {
      setCheckedIds(
        checkedIds.filter((itemCheck) => itemCheck?.id !== item?.id)
      );
    } else {
      setCheckedIds([...checkedIds, item]);
    }
  };

  const holderName = (item) => {
    const address = item?.public_address
      ? `_${item?.public_address?.slice(-2)}`
      : "";
    return `player_${item?.id?.slice(-4)}${address}`;
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isChecked}
          onChange={handleCheckboxChange}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.email}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {trimCharacter(item?.public_address, 6)}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.username || holderName(item)}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {
            STATUS_USER_OPTIONS.find((option) => option.value === item?.status)
              ?.label
          }
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.level}
          {/* level */}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.user_contribution_point?.current_cp}
          {/* cp */}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.user_attribute_point?.current_ap}
          {/* ap */}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.ranking}
          {/* skill ranking */}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.title}
          {/* title */}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.referral}
          {/* REFERRALS */}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {moment(item?.update_time * 1000)
            .utc()
            .format("DD MMM YYYY HH:mm:ss")}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.update_by}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {moment(item?.create_time * 1000)
            .utc()
            .format("DD MMM YYYY HH:mm:ss")}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.create_by}
        </Text>
      </Td>
      <Td>
        <Flex gap={6}>
          <Icon
            cursor="pointer"
            alt="off"
            fontSize="20px"
            onClick={() => {
              handleOpenHistoryPlayGame(item);
            }}
            color={textColor}
            as={PlayHistoryIcon}
          />
          <Icon
            cursor="pointer"
            alt="off"
            fontSize="20px"
            onClick={() => {
              handleOpenPrizeHistoryModal(item);
            }}
            color={textColor}
            as={PlayPrizeIcon}
          />
          <Icon
            onClick={() => handleOpenDetailUserModal(item)}
            fontSize="16px"
            cursor="pointer"
            color={textColor}
            as={ViewIcon}
          />
          <Icon
            onClick={() => handleOpenEditModal(item)}
            fontSize="16px"
            cursor="pointer"
            color={textColor}
            as={MdEdit}
          />
          <Icon
            cursor="pointer"
            alt="off"
            fontSize="16px"
            color={textColor}
            as={BsThreeDotsVertical}
          />
        </Flex>
      </Td>
    </Tr>
  );
}
