/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { mode } from '@chakra-ui/theme-tools'

export const globalStyles = {
  colors: {
    gray: {
      700: '#1f2733',
    },
    teal: {
      500: 'rgb(79, 209, 197)',
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg:
          document.documentElement.layout === 'auth'
            ? mode('white', 'gray.800')(props)
            : mode('gray.50', 'gray.800')(props),
        fontFamily: "'Roboto', sans-serif",
      },
      html: {
        fontFamily: "'Roboto', sans-serif",
      },
    }),
  },
}
