export const SwitchIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#0F172A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m1.5 5 4-4 4 4m-4-4v14m14-4-4 4-4-4m4 4V1"
    />
  </svg>
);
