import { Flex, Icon, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import { DeleteIcon } from "components/Icons/DeleteIcon";
import { EditIcon } from "components/Icons/EditIcon";
import { ViewIcon } from "components/Icons/ViewIcon";
import { GAME_MODE_OPTIONS, TYPE_PRIZE } from "constants/constants";
import { convertStringSnake } from "utils/string";

const TYPE_OPTIONS = {
  ALL: "All",
  TWO_CLAWS: "2 Claws",
  THREE_CLAWS: "3 Claws",
};

const GameModeRow = ({
  game,
  handleOpenCreateOrUpdate,
  handleOpenDetail,
  handleOpenConfirmDelete,
  index,
}) => {
  const textColor = useColorModeValue("#000000", "white");

  return (
    <Tr>
      <Td width="10px">{index}</Td>
      <Td>
        <Text
          color="#1480FF"
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {game?.mode_name}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {
            GAME_MODE_OPTIONS?.find((item) => item?.value === game?.mode_type)
              ?.label
          }
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {convertStringSnake(game?.play_fee?.system_ticket_id || "")}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {TYPE_OPTIONS[game?.machine_type]}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {game?.gameplay_type?.name}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {TYPE_PRIZE[game?.prize_type]}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {game?.life_gauge_consumption}
        </Text>
      </Td>
      <Td>
        <Flex gap="10px">
          <Icon
            onClick={handleOpenDetail}
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={ViewIcon}
          />
          <Icon
            onClick={handleOpenCreateOrUpdate}
            fontSize="30px"
            borderRadius="8px"
            p="7px"
            cursor="pointer"
            bg="#1480FF"
            as={EditIcon}
          />
          {/* <Icon
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={DeleteIcon}
            onClick={handleOpenConfirmDelete}
          /> */}
        </Flex>
      </Td>
    </Tr>
  );
};

export default GameModeRow;
