/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Chakra imports
import { ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Icon,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import ImageDefault from "assets/img/empty-image.png";
import CheckBoxType from "components/Checkbox";
import { MACHINE_TYPE_OPTIONS, TYPE_PRIZE } from "constants/constants";
import { MdEdit } from "react-icons/md";
import { getDateUTCLLLL } from "utils/time";

const PrizeRow = ({
  checkedIds,
  setCheckedIds,
  handleSetDataDetail,
  handleOpenCreateOrUpdate,
  listPrize,
}) => {
  const textColor = useColorModeValue("#000000", "white");

  const handleCheckboxChange = () => {
    if (checkedIds.includes(listPrize?.id)) {
      setCheckedIds(
        checkedIds.filter((checkedId) => checkedId !== listPrize?.id)
      );
    } else {
      setCheckedIds([...checkedIds, listPrize?.id]);
    }
  };

  const filterBoothType = (machines) => {
    let arrBooth = [];
    machines?.map((machine) => {
      const machineType = MACHINE_TYPE_OPTIONS.find(
        (item) => item.value === machine
      )?.label;
      return arrBooth.push(machineType);
    });
    return [...new Set(arrBooth)];
  };

  return (
    <Tr key={listPrize?.id}>
      <Td width="10px">
        <CheckBoxType
          isChecked={checkedIds.includes(listPrize?.id)}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td p="2" minW="60px" padding={"0 28px"}>
        <Box
          bgImage={listPrize?.image ? listPrize?.image : ImageDefault}
          w="40px"
          h="40px"
          backgroundSize="cover"
        />
      </Td>
      <Td minW="180px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.name || "---"}
        </Text>
      </Td>
      <Td minW="150px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.prize_type
            ? TYPE_PRIZE[listPrize?.prize_type]
            : "---"}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.game_mode?.mode_name || "---"}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.play_fee?.value || "---"}
        </Text>
      </Td>
      {/* <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.estimated_value}
        </Text>
      </Td> */}
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.gameplay_type?.name || "---"}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.machine_types?.length
            ? filterBoothType(listPrize?.machine_types)?.map((booth, i) => {
                return (
                  <span key={i} style={{ display: "block" }}>
                    {booth}
                  </span>
                );
              })
            : "---"}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.inv_count || "1"}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.revenue_sharing?.payee || "None"}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.revenue_sharing?.sharing_percentage}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.bounty_ball || "No"}
        </Text>
      </Td>
      <Td minW="200px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.update_time
            ? getDateUTCLLLL(listPrize?.update_time * 1000)
            : "---"}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.update_by || "Admin"}
        </Text>
      </Td>
      <Td minW="200px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.create_time
            ? getDateUTCLLLL(listPrize?.create_time * 1000)
            : "---"}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.create_by || "Admin"}
        </Text>
      </Td>
      <Td>
        <Flex gap={6} align="center">
          <Icon
            onClick={() => handleSetDataDetail(listPrize?.id)}
            cursor="pointer"
            fontSize="16px"
            color={textColor}
            as={ViewIcon}
          />
          <Icon
            onClick={() => handleOpenCreateOrUpdate()}
            fontSize="16px"
            cursor="pointer"
            color={textColor}
            as={MdEdit}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default PrizeRow;
