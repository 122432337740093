import { Flex, Grid } from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import FormTextAria from "components/form/FormTextAria";
import EventPrizeList from "./EventPrize";
import FinalPrizeList from "./FinalPrize";
import SelectDateCreateEvent from "./SelectDate";
import { useMemo } from "react";

const TRANS_LABEL = {
  en: {
    name: "Event Name",
    description: "Description",
    rule: "Rule",
    preStart: "Pre-registration Start",
    preEnd: "Pre-registration End",
    regisStart: "Registration Start",
    regisEnd: "Registration End",
    whiteListStart: "Whitelist Start",
    WhiteListEnd: "Whitelist End",
    eventStart: "Event Start",
    eventEnd: "Event End",
    resultStart: "Result Start",
    resultEnd: "Result End",
    eventPrize: "Event Prize",
    finalPrize: "Final Prize",
    prizeType: "Prize Type",
    playFee: "Play Fee",
    estimatedValue: "Estimated Value",
    ranking: "Ranking",
    from: "From",
    to: "To",
    rewards: "Rewards",
    item: "Item",
    amount: "Amount",
    type: "Type",
  },
  jp: {
    name: "イベント名",
    description: "説明",
    rule: "ルール",
    preStart: "事前登録開始",
    preEnd: "事前登録終了",
    regisStart: "登録開始",
    regisEnd: "登録終了",
    whiteListStart: "ホワイトリストの開始",
    WhiteListEnd: "ホワイトリストの終了",
    eventStart: "イベント開始",
    eventEnd: "イベント終了",
    resultStart: "結果開始",
    resultEnd: "結果終了",
    eventPrize: "イベント賞品",
    finalPrize: "最終賞",
    prizeType: "賞品の種類",
    playFee: "プレイ料金",
    estimatedValue: "推定値",
    ranking: "ランキング",
    from: "から",
    to: "に",
    rewards: "報酬",
    item: "アイテム",
    amount: "金額",
    type: "タイプ",
  },
};

export default function EventTabPanel({
  trans = "en",
  rules,
  watch,
  setValue,
  fieldEventPrizes,
  removeEventPrize,
  onOpenSelectPrize,
  appendFinalPrize,
  defaultDataFinalPrize,
  fieldFinalPrizes,
  removeFinalPrize,
  defaultDataReward,
  tickets,
  control,
}) {
  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };

  const disableWhenJP = useMemo(() => {
    return trans === "jp";
  }, [trans]);

  return (
    <Flex direction="column" w="100%" gap={4}>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(1, 1fr)",
          xl: "repeat(2, 1fr)",
        }}
        rowGap={4}
        columnGap={16}
      >
        <Flex direction="column" gap={4}>
          {trans === "en" ? (
            <>
              <FormInput
                maxW="315px"
                name="name"
                label={transLabel("name")}
                isRequired
              />
              <FormTextAria
                label={transLabel("description")}
                name="description"
                h="220px"
              />
            </>
          ) : (
            <Flex direction="column" gap={4}>
              <FormInput
                maxW="315px"
                name="name_jp"
                label={transLabel("name")}
                isRequired
              />
              <FormTextAria
                label={transLabel("description")}
                name="description_jp"
                h="220px"
              />
            </Flex>
          )}
          <FormSelect
            label={transLabel("rule")}
            isDisabled={disableWhenJP}
            name="rule_id"
            maxW="315px"
            defaultValue={rules[0]?.value}
            options={rules}
            isRequired
          />
        </Flex>
        <SelectDateCreateEvent
          disableWhenJP={disableWhenJP}
          transLabel={transLabel}
          watch={watch}
          setValue={setValue}
        />
      </Grid>
      <hr
        style={{
          marginTop: "35px",
          marginBottom: "22px",
        }}
      />
      <EventPrizeList
        disableWhenJP={disableWhenJP}
        transLabel={transLabel}
        fieldEventPrizes={fieldEventPrizes}
        removeEventPrize={removeEventPrize}
        onOpenSelectPrize={onOpenSelectPrize}
      />
      <hr
        style={{
          marginTop: "35px",
          marginBottom: "22px",
        }}
      />
      <FinalPrizeList
        disableWhenJP={disableWhenJP}
        transLabel={transLabel}
        appendFinalPrize={appendFinalPrize}
        defaultDataFinalPrize={defaultDataFinalPrize}
        fieldFinalPrizes={fieldFinalPrizes}
        removeFinalPrize={removeFinalPrize}
        defaultDataReward={defaultDataReward}
        tickets={tickets}
        control={control}
        watch={watch}
      />
    </Flex>
  );
}
