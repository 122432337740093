import { Button } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import PrizeHistoryRow from "./row";

export default function PrizeHistoryTable({
  isLoading,
  isShowPagination = true,
  isShowBtnShowFull = false,
  fontSize = "sm",
  textNoData,
  handleClickShowFull,
  ...rest
}) {
  const labels = ["Prize Name", "Prize Type", "Mode", "Obtained on"];
  const fakeData = [
    {
      id: 1,
      name: " name",
    },
  ];
  return (
    <Card>
      <CardBody overflowX="auto" maxW="80%" m="auto" minH="200px" {...rest}>
        <TableCustom
          labels={labels}
          isLoading={isLoading}
          isCheck={false}
          dataRow={fakeData}
          textNoData={textNoData}
        >
          <>
            {fakeData?.map((item) => {
              return (
                <PrizeHistoryRow
                  key={item?.id}
                  item={item}
                  fontSize={fontSize}
                />
              );
            })}
          </>
        </TableCustom>
      </CardBody>
      {isShowPagination && (
        <Paginate
          page={1}
          pageCount={2}
          limit={10}
          //   onChangeLimit={onChangeLimit}
          //   handleChangePage={handleChangePage}
        />
      )}
      {isShowBtnShowFull && (
        <Button
          mt={4}
          alignSelf="flex-end"
          w="117px"
          h="26px"
          p="7px 21px"
          fontWeight="400"
          fontSize="12px"
          borderRadius="3px"
          cursor="pointer"
          backgroundColor="#EDEEFC"
          onClick={() => {
            handleClickShowFull && handleClickShowFull();
          }}
        >
          Show full history
        </Button>
      )}
    </Card>
  );
}
