export const CancelIcon = (props) => (
  <svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.1 5.8165L14.6834 2.39984C14.2834 1.99984 13.4834 1.6665 12.9167 1.6665H8.08335C7.51669 1.6665 6.71669 1.99984 6.31669 2.39984L2.90002 5.8165C2.50002 6.2165 2.16669 7.0165 2.16669 7.58317V12.4165C2.16669 12.9832 2.50002 13.7832 2.90002 14.1832L6.31669 17.5998C6.71669 17.9998 7.51669 18.3332 8.08335 18.3332H12.9167C13.4834 18.3332 14.2834 17.9998 14.6834 17.5998L18.1 14.1832C18.5 13.7832 18.8334 12.9832 18.8334 12.4165V7.58317C18.8334 7.0165 18.5 6.2165 18.1 5.8165ZM13.8584 12.4748C14.1 12.7165 14.1 13.1165 13.8584 13.3582C13.7334 13.4832 13.575 13.5415 13.4167 13.5415C13.2584 13.5415 13.1 13.4832 12.975 13.3582L10.5 10.8832L8.02502 13.3582C7.90002 13.4832 7.74169 13.5415 7.58335 13.5415C7.42502 13.5415 7.26669 13.4832 7.14169 13.3582C6.90002 13.1165 6.90002 12.7165 7.14169 12.4748L9.61669 9.99984L7.14169 7.52484C6.90002 7.28317 6.90002 6.88317 7.14169 6.6415C7.38335 6.39984 7.78335 6.39984 8.02502 6.6415L10.5 9.1165L12.975 6.6415C13.2167 6.39984 13.6167 6.39984 13.8584 6.6415C14.1 6.88317 14.1 7.28317 13.8584 7.52484L11.3834 9.99984L13.8584 12.4748Z"
      fill="white"
    />
  </svg>
);
