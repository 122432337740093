import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import ButtonType from "components/Button";

export default function DeleteModal({
  isOpen,
  onClose,
  handleDelete,
  title = "",
  content = "",
  btnYes = "",
  btnNo = "",
}) {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent textAlign="center">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{content}</ModalBody>
        <ModalFooter w="100%" textAlign="center" margin="auto">
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap="16px"
            w="100%"
          >
            {btnNo && (
              <ButtonType
                btnType="primary-new-outline"
                onClick={onClose}
                mr={3}
                w="100%"
              >
                {btnNo}
              </ButtonType>
            )}
            {btnYes && (
              <ButtonType w="100%" onClick={handleDelete}>
                {btnYes}
              </ButtonType>
            )}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
