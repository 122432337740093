/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { CABINET_TYPE_NAMESPACE } from 'stores/constant'
import {
  getCabinetTypeApi,
} from './action'

const name = CABINET_TYPE_NAMESPACE
const initialState = createInitialState()
const reducers = createReducers()
const extraReducers = createExtraReducers()
const cabinetTypeSlice = createSlice({ name, initialState, reducers, extraReducers })

export const cabinetTypeSliceReducer = cabinetTypeSlice.reducer

function createInitialState() {
  return {
    loadingCabinetType: false,
    errorCabinetType: '',
    listCabinetTypes: [],
  }
}

function createReducers() {
  return {}
}

function createExtraReducers() {
  return (builder) => {
    listCabinetTypes()

    function listCabinetTypes() {
      builder
        .addCase(getCabinetTypeApi.pending, (state) => {
          state.loadingCabinetType = true
        })
        .addCase(getCabinetTypeApi.fulfilled, (state, action) => {
          state.loadingCabinetType = false
          state.errorCabinetType = ''
          if (action.payload) {
            state.listCabinetTypes = action.payload
          }
        })
        .addCase(getCabinetTypeApi.rejected, (state, action) => {
          state.loadingCabinetType = false
          state.errorCabinetType = action.payload
        })
    }
  }
}
