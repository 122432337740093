import {
  Box,
  Flex,
  FormLabel,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import CopyComponent from "components/Copy";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { STATUS_CAMERA_OPTIONS, STATUS_MACHINE } from "../constants";

export default function MachineDetail({ isOpen, onClose, dataDetail }) {
  const textColor = useColorModeValue("#000000", "white");
  const form = useForm({
    defaultValues: {
      ...dataDetail,
    },
  });

  const { reset } = form;
  useEffect(() => {
    reset({
      ...dataDetail,
    });
  }, [dataDetail, reset]);

  const renderForm = () => {
    return (
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          xl: "repeat(2, 1fr)",
        }}
        gap={8}
      >
        <Flex direction="column" gap={8}>
          <Flex gap={8}>
            <Flex direction="column">
              <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                {"Mac Address"}
              </FormLabel>
              <Text color={textColor} fontSize="16px" fontWeight="400">
                {dataDetail?.mac}
              </Text>
            </Flex>
            <Flex direction="column">
              <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                {"Machine Status"}
              </FormLabel>
              <Text color={textColor} fontSize="16px" fontWeight="400">
                {STATUS_MACHINE[dataDetail?.status]}
              </Text>
            </Flex>
          </Flex>
          <div>
            <FormLabel color={textColor} fontWeight="700" fontSize="16px">
              {"Front Camera "}
            </FormLabel>
            <Box
              border="1px solid #EEEFF2"
              borderRadius="4px"
              p={{
                base: "20px",
                md: "35px 45px",
              }}
            >
              <Flex direction="column" gap={4}>
                <Flex direction="column">
                  <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                    {"ID"}
                  </FormLabel>
                  <Text color={textColor} fontSize="16px" fontWeight="400">
                    {dataDetail?.web_link_cam_front &&
                      dataDetail?.web_link_cam_front?.split("camId=")[1]}
                  </Text>
                </Flex>
                <Flex direction="column">
                  <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                    {"Status"}
                  </FormLabel>
                  <Text color={textColor} fontSize="16px" fontWeight="400">
                    {
                      STATUS_CAMERA_OPTIONS?.find(
                        (item) => item?.value === dataDetail?.status_cam_front
                      )?.label
                    }
                  </Text>
                </Flex>
                <Flex direction="column">
                  <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                    {"Link"}
                  </FormLabel>
                  <Flex align="center" gap={4}>
                    <Text color={textColor} fontSize="16px" fontWeight={400}>
                      {dataDetail?.web_link_cam_front}
                    </Text>
                    <CopyComponent dataCopy={dataDetail?.web_link_cam_front} />
                  </Flex>
                  <Flex align="center" gap={4}>
                    <Text color={textColor} fontSize="16px" fontWeight={400}>
                      {dataDetail?.stream_link_cam_front}
                    </Text>
                    <CopyComponent
                      dataCopy={dataDetail?.stream_link_cam_front}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </div>
        </Flex>
        <Flex direction="column" gap={8}>
          <Flex gap={8}>
            <Flex direction="column">
              <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                {"Control Server ID"}
              </FormLabel>
              <Text color={textColor} fontSize="16px" fontWeight="400">
                {dataDetail?.control_server_ip}
              </Text>
            </Flex>
            <Flex direction="column">
              <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                {"Port"}
              </FormLabel>
              <Text color={textColor} fontSize="16px" fontWeight="400">
                {dataDetail?.control_server_port}&nbsp;
              </Text>
            </Flex>
          </Flex>
          <div>
            <FormLabel color={textColor} fontWeight="700" fontSize="16px">
              {"Side Camera "}
            </FormLabel>
            <Box
              border="1px solid #EEEFF2"
              borderRadius="4px"
              p={{
                base: "20px",
                md: "35px 45px",
              }}
            >
              <Flex direction="column" gap={4}>
                <Flex direction="column">
                  <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                    {"ID"}
                  </FormLabel>
                  <Text color={textColor} fontSize="16px" fontWeight="400">
                    {dataDetail?.web_link_cam_back &&
                      dataDetail?.web_link_cam_back?.split("camId=")[1]}
                  </Text>
                </Flex>
                <Flex direction="column">
                  <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                    {"Status"}
                  </FormLabel>
                  <Text color={textColor} fontSize="16px" fontWeight="400">
                    {
                      STATUS_CAMERA_OPTIONS?.find(
                        (item) => item?.value === dataDetail?.status_cam_back
                      )?.label
                    }
                  </Text>
                </Flex>
                <Flex direction="column">
                  <FormLabel color={textColor} fontWeight="700" fontSize="16px">
                    {"Link"}
                  </FormLabel>
                  <Flex align="center" gap={4}>
                    <Text color={textColor} fontSize="16px" fontWeight={400}>
                      {dataDetail?.web_link_cam_back}
                    </Text>
                    <CopyComponent dataCopy={dataDetail?.web_link_cam_back} />
                  </Flex>
                  <Flex align="center" gap={4}>
                    <Text color={textColor} fontSize="16px" fontWeight={400}>
                      {dataDetail?.stream_link_cam_back}
                    </Text>
                    <CopyComponent
                      dataCopy={dataDetail?.stream_link_cam_back}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </div>
        </Flex>
      </Grid>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        maxW={{
          base: "100%",
          md: "95%",
          xl: "1500px",
        }}
        overflowY="auto"
      >
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >{`MACHINE #${dataDetail?.mac}`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p="32px 64px">
          {renderForm()}
        </ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        ></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
