/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { CalendarIcon } from "@chakra-ui/icons";
import { FormControl, Icon, FormErrorMessage } from "@chakra-ui/react";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { useController, useFormContext } from "react-hook-form";
import styled from "styled-components";

const FormControlStyled = styled(FormControl)`
  .react-datepicker {
    display: flex;
  }
  svg {
    position: absolute;
    right: 0;
    top: 4px;
    color: #000000;
  }
  input {
    padding: 6px 25px 5px 10px;
    color: #000000;
  }
  .react-datepicker__triangle {
    display: none;
  }
`;

const DatePickStyled = styled(ReactDatePicker)`
  border: 1px solid #eeeff2;
  border-radius: 5px;
  width: 100%;
  height: 40px;
`;

const FormDatePicker = ({
  // label,
  name,
  rules,
  defaultValue = "",
  wrapperProps,
  // wrapperStyles,
  // forceError,
  // hideError,
  // useDate,
  classNames,
  onChange,
  dateFormat = "YYYY-MM-dd",
  timeFormat = "HH:mm:ss",
  ...rest
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange: onChangeField, value },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  return (
    <FormControlStyled
      maxW="100%"
      w="100%"
      className={classNames}
      // w="auto"
      isInvalid={error?.message}
      {...wrapperProps}
    >
      <DatePickStyled
        w="100%"
        selected={value ? moment(value).toDate() : null}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          } else {
            onChangeField(e);
          }
        }}
        dateFormat={dateFormat}
        showIcon
        timeFormat={timeFormat}
        timeIntervals={1}
        timeCaption="time"
        onKeyDown={(e) => e.preventDefault()}
        icon={<Icon as={CalendarIcon} />}
        {...rest}
      />
      {error?.message && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControlStyled>
  );
};

export default FormDatePicker;
