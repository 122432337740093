import { STATUS } from "constants/constants";

export const renderDedicated = (event, free) => {
  if (event) {
    return STATUS.EVENT;
  }
  if (free) {
    return STATUS.FREE;
  }
  return STATUS.PRIZE;
};
