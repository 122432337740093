import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getEventManagements } from "api/event.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { useCallback, useEffect, useState } from "react";
import CreateEvent from "./CreateEvent";
import WhiteListEvent from "./WhiteList";
import ConditionEventManagement from "./condition";
import EventDetailComponent from "./detail";
import EventManagementRow from "./row";
import ActionEventManagement from "./action";
import ImportWhiteList from "./ImportWhiteList";

export default function EventManagement() {
  const labels = [
    "Event Name",
    "Status",
    "Event period",
    "Pre-registration period ",
    "Registration period",
    "Whitelist period",
    "Result period",
    "Last Modified At",
    "Created At",
    "",
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [dataDetail, setDataDetail] = useState(null);
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();

  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();

  const {
    isOpen: isOpenCreateAndEdit,
    onOpen: onOpenCreateAndEdit,
    onClose: onCloseCreateAndEdit,
  } = useDisclosure();

  const {
    isOpen: isOpenWhiteList,
    onOpen: onOpenWhiteList,
    onClose: onCloseWhiteList,
  } = useDisclosure();

  const {
    isOpen: isOpenImportWhiteList,
    onOpen: onOpenImportWhiteList,
    onClose: onCloseImportWhiteList,
  } = useDisclosure();

  const getEvent = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        setCheckedIds([]);
        const { data } = await getEventManagements({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setEvents(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  // whitelist
  const handleOpenWhiteModal = (item) => {
    setDataDetail(item);
    onOpenWhiteList(true);
  };

  const handleCloseWhitelistModal = () => {
    setDataDetail(null);
    onCloseWhiteList();
  };

  const handleOpenImportWhiteModal = (item) => {
    setDataDetail(item);
    onOpenImportWhiteList(true);
  };

  const handleCloseImportWhitelistModal = () => {
    setDataDetail(null);
    onCloseImportWhiteList();
  };

  // detail user modal

  const handleOpenDetailUserModal = (item) => {
    setDataDetail(item);
    onOpenDetail(true);
  };

  const handleCloseDetailUserModal = () => {
    setDataDetail(null);
    onCloseDetail();
  };

  // create and update modal

  const handleOpenEditModal = (item) => {
    setDataDetail(item);
    onOpenCreateAndEdit(true);
  };

  const handleCloseEditModal = () => {
    setDataDetail(null);
    onCloseCreateAndEdit();
  };

  const handleUpdateSuccess = useCallback(() => {
    handleCloseEditModal();
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(events);
    } else {
      setCheckedIds([]);
    }
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex
            w="100%"
            gap={4}
            direction={{
              base: "column",
              md: "row",
            }}
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignItems="flex-start"
          >
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Events Management
            </Text>
            <ActionEventManagement
              handleOpenCreate={() => {
                setDataDetail(null);
                onOpenCreateAndEdit();
              }}
              checkedIds={checkedIds}
              setCheckedIds={setCheckedIds}
              setParams={setParams}
              params={params}
            />
          </Flex>
        </CardHeader>
        <CardHeader px="42px" mb="32px">
          <ConditionEventManagement
            isLoading={isLoading}
            setParams={setParams}
            params={params}
          />
        </CardHeader>
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck
            isChecked={
              events?.length > 0 && checkedIds.length === events?.length
            }
            onChangeChecked={onChangeChecked}
            dataRow={events}
          >
            <>
              {events?.map((item) => {
                return (
                  <EventManagementRow
                    key={item?.id}
                    setCheckedIds={setCheckedIds}
                    checkedIds={checkedIds}
                    handleOpenEditModal={handleOpenEditModal}
                    handleOpenDetailUserModal={handleOpenDetailUserModal}
                    handleOpenWhiteModal={handleOpenWhiteModal}
                    handleOpenImportWhiteModal={handleOpenImportWhiteModal}
                    item={item}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
      {isOpenDetail && (
        <EventDetailComponent
          dataDetail={dataDetail}
          isOpen={isOpenDetail}
          onClose={handleCloseDetailUserModal}
        />
      )}
      {isOpenCreateAndEdit && (
        <CreateEvent
          isOpen={isOpenCreateAndEdit}
          onClose={onCloseCreateAndEdit}
          handleUpdateSuccess={handleUpdateSuccess}
          dataDetail={dataDetail}
        />
      )}
      {isOpenWhiteList && (
        <WhiteListEvent
          isOpen={isOpenWhiteList}
          onClose={handleCloseWhitelistModal}
          idDetail={dataDetail?.id}
        />
      )}
      {isOpenImportWhiteList && (
        <ImportWhiteList
          isOpen={isOpenImportWhiteList}
          onClose={handleCloseImportWhitelistModal}
          idDetail={dataDetail?.id}
        />
      )}
    </Flex>
  );
}
