import { Icon } from "@chakra-ui/icons";
import {
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { updateBlockAdminApi } from "api/admin.api";
import { EditIcon } from "components/Icons/EditIcon";
import { LockIcon } from "components/Icons/LockIcon";
import { ROLE_OPTIONS, STATUS } from "constants/constants";
import useWithToast from "hooks/useWithToast";

export default function AdminManagementRow({
  item,
  handleOpenEditModal,
  index,
  getAdmins,
}) {
  const toast = useToast();
  const { showToastSuccess } = useWithToast();
  const textColor = useColorModeValue("#000000", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleLock = async () => {
    try {
      if (item?.status === STATUS.ACTIVE || item?.status === STATUS.BLOCKED) {
        const status =
          item?.status === STATUS.ACTIVE ? STATUS.BLOCKED : STATUS.ACTIVE;
        const res = await updateBlockAdminApi(item?.id, {
          status,
        });
        if (res) {
          showToastSuccess({
            title: `Successfully ${
              status === STATUS.BLOCKED ? "blocked" : "unblocked"
            }`,
          });
          getAdmins();
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <>
      <Tr>
        <Td w="50px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {index}
          </Text>
        </Td>
        <Td w="60%">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.username}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {ROLE_OPTIONS[item?.roles?.[0]?.name]}
          </Text>
        </Td>
        <Td>
          <Flex gap="10px">
            <Icon
              onClick={() => {
                handleOpenEditModal(item);
              }}
              fontSize="30px"
              borderRadius="8px"
              p="7px"
              cursor="pointer"
              bg="#1480FF"
              as={EditIcon}
            />
            <Icon
              fontSize="30px"
              borderRadius="8px"
              p="7px"
              cursor="pointer"
              bg={item?.status === STATUS.BLOCKED ? "#E50000" : "#07CF84"}
              as={LockIcon}
              onClick={() => {
                handleLock();
              }}
            />
          </Flex>
        </Td>
      </Tr>
      {/* {isOpen && (
        <ModalConfirm
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={handleLock}
          title="Cancel Sell"
          description={"Are you sure you want to cancel sell this NFT?"}
        />
      )} */}
    </>
  );
}
