import {
  FormLabel,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createMachineSetting,
  getListCraneMachinesUnUsed,
  updateMachineSetting,
} from "api/machine.api";
import ButtonType from "components/Button";
import FormSelect from "components/form/FormSelect";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MACHINE_TYPE_OPTIONS, STATE_OPTIONS } from "../constants";
import schemaMachine from "./schema";
import { useTranslation } from "react-i18next";
import { NUMBER } from "constants/enum";
import { checkMaxImage } from "utils/uploadFile";
import { postUploadImage } from "api/prize.api";
import FormUpload from "components/form/FormUpload";
import FormInput from "components/form/FormInput";

export default function MachineCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
  listGamePlayType,
  gamesModeOptions,
}) {
  const { t } = useTranslation("prize");
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const [machinesOptions, setMachinesOptions] = useState([]);
  const [listModeByClaw, setListModeByClaw] = useState([]);
  const [nameImage, setNameImage] = useState("");
  const form = useForm({
    resolver: yupResolver(schemaMachine()),
    defaultValues: {
      machine_type: dataDetail ? dataDetail?.machine_type : MACHINE_TYPE_OPTIONS[0].value
    }
  });

  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isSubmitting },
  } = form;

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const getMachinesCrane = async () => {
    try {
      const { data } = await getListCraneMachinesUnUsed({
        page: 1,
        limit: 999999,
      });
      if (data?.success) {
        const list = data?.data;
        if (list && list?.length > 0) {
          const listOptions = list?.map((item) => {
            return {
              label: item?.mac,
              value: item?.id,
            };
          });
          setMachinesOptions(listOptions);
          if (dataDetail?.crane_machine_id) {
            setValue("machine_id", dataDetail?.crane_machine_id);
          } else {
            setValue(
              "machine_id",
              listOptions?.length > 0 ? listOptions[0].value : null
            );
          }
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleListModeByClaw = () => {
    const modesByClaw = gamesModeOptions?.filter((mode) => mode?.machine_type === "ALL" || mode?.machine_type === watch("machine_type"))
    setListModeByClaw(modesByClaw)
    if (dataDetail) {
      handleChangeGameMode(dataDetail?.game_mode_id)
      setValue("game_mode_id", dataDetail?.game_mode_id);
    } else {
      setValue("game_mode_id", modesByClaw[0]?.value);
      handleChangeGameMode(modesByClaw[0]?.value)
    }
  }

  useEffect(() => {
    getMachinesCrane();
    if (dataDetail) {
      reset({ ...dataDetail, machine_id: dataDetail?.crane_machine_id });
      handleListModeByClaw()
    } else {
      reset();
      if (gamesModeOptions?.length > 0) {
        handleListModeByClaw()
      }
    }
  }, [dataDetail, gamesModeOptions]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const dataSubmit = {
      crane_machine_id: data?.machine_id,
      status: data?.status,
      machine_type: data?.machine_type,
      gameplay_type_id: data?.gameplay_type_id,
      game_mode_id: data?.game_mode_id,
      position_id: "1",
      icon_url: data?.icon_url,
    };
    try {
      if (idEdit) {
        const res = await updateMachineSetting(dataDetail?.id, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Machine updated.",
            description: "Machine updated.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        }
      } else {
        const res = await createMachineSetting(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Machine created.",
            description: "Machine created.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

   const handleImageChange = async (event) => {
    const image = event[0];
    await uploadImageToServe(image);
  };

   const handleDragImage = async (file) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: "The number of uploaded images is greater than one.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    const image = file && file[0];

    await uploadImageToServe(image);
  };

   const uploadImageToServe = async (image) => {
    if (image && !image.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      toast({
        description: "The image format is incorrect.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    } else {
      if (checkMaxImage(image)) {
        toast({
          description: "Image must be smaller than 5MB.",
          status: "error",
          position: "bottom-right",
        });
        return false;
      }
    }
    try {
      const request = new FormData();
      request.append("file", image);
      request.append("name", image?.name || "image");
      const { data } = await postUploadImage(request);
      if (data?.code === 200) {
        setNameImage(data?.data?.name);
        setValue("icon_url", data?.data?.url);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const deleteImage = () => {
    setValue('icon_url', null)
  }

  const handleChangeGameMode = (data) => {
    const dataMode = listModeByClaw?.filter((mode) => mode?.value === data)
    setValue('mode_type', dataMode[0]?.mode_type)
    setValue('gameplay_type_id', dataMode[0]?.gameplay_type_id)
    setValue('game_mode_id', data)
  }

  const handleBoothType = (data) => {
    setValue('machine_type', data)
    handleListModeByClaw()
  }

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            {idEdit ? (
              <div>
                <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                  {"Machine ID "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Text
                  ml={2}
                  mt={4}
                  fontSize="16px"
                  fontWeight="400"
                  color={textColor}
                >
                  {dataDetail?.crane_machine?.mac}
                </Text>
              </div>
            ) : (
              <FormSelect
                label="Machine ID"
                name="machine_id"
                isRequired
                defaultValue={
                  machinesOptions?.length > 0 ? machinesOptions[0].value : null
                }
                options={machinesOptions}
              />
            )}
            {idEdit ? (
              <div>
                <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                  {"Booth Type "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Text
                  ml={2}
                  mt={4}
                  fontSize="16px"
                  fontWeight="400"
                  color={textColor}
                >
                  {MACHINE_TYPE_OPTIONS?.filter((option) => option?.value === dataDetail?.machine_type)[0]?.label}
                </Text>
              </div>
            ) : (
              <FormSelect
                name="machine_type"
                label="Booth Type"
                defaultValue={MACHINE_TYPE_OPTIONS[0].value}
                isRequired
                options={MACHINE_TYPE_OPTIONS}
                onChange={(e) => handleBoothType(e.target.value)}
              />
            )}
            <FormSelect
              name="game_mode_id"
              label="Game Mode"
              defaultValue={listModeByClaw?.[0]?.value}
              isRequired
              options={listModeByClaw}
              onChange={(e) => handleChangeGameMode(e.target.value)}
            />
            <FormSelect
              name="status"
              label="Machine State"
              defaultValue={STATE_OPTIONS[0].value}
              isRequired
              options={STATE_OPTIONS}
            />
            <FormSelect
              name="gameplay_type_id"
              label="Gameplay Type"
              defaultValue={
                listGamePlayType?.length > 0 ? listGamePlayType[0].value : null
              }
              options={listGamePlayType}
              isDisabled
            />
            <FormInput
              label="Mode Type"
              name="mode_type"
              isDisabled
            />
            <FormUpload
              label="Select image file to upload"
              title="Image"
              name="icon_url"
              type='IMAGE'
              accept={"image/*"}
              isRequired
              textButton={nameImage}
              textDescription={t("note_upload_image")}
              handleOnChange={(e) =>
                handleImageChange(e, t("label_background"))
              }
              handleDragImage={(val) =>
                handleDragImage(val, t("label_background"))
              }
              deleteImage={deleteImage}
            />
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="1000px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "EDIT MACHINE" : "NEW MACHINE SETUP"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        >
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="120px"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
            // onClick={() => {
            //   handleSubmit(onSubmit);
            // }}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
