import { Flex } from "@chakra-ui/react";
import FormSelect from "components/form/FormSelect";
import { FormProvider, useForm } from "react-hook-form";
import { RESULT_OPTION } from "./constants";

export default function ConditionPlayerManagement({
  isLoading,
  params,
  setParams,
  events,
}) {
  const form = useForm();
  const { handleSubmit, setValue } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const newParams = {
        ...params,
        ...data,
        page: 1,
      };
      setParams(newParams);
    }
  };

  return (
    <Flex
      gap={4}
      flexWrap="wrap"
      justifyContent="space-between"
      w="100%"
      alignItems="flex-end"
    >
      <FormProvider {...form}>
        <form
          style={{
            maxWidth: "100%",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex w="100%" flexWrap="wrap" alignItems="flex-end" gap={4}>
            <FormSelect
              w="180px"
              name="event-id"
              label="Event Id"
              options={[{ label: "All", value: "" }, ...events]}
              onChange={(e) => {
                setValue("event-id", e.target.value);
                handleSubmit(onSubmit)();
              }}
            />
            <FormSelect
              name="result"
              label="Result"
              w="180px"
              options={[{ label: "All", value: "" }, ...RESULT_OPTION]}
              onChange={(e) => {
                setValue("result", e.target.value);
                handleSubmit(onSubmit)();
              }}
            />
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
}
