import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getContractsApi = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.NFT_CONTRACT}?${params}`);
};

export const createContractApi = (data) => {
  return http.post(`${END_POINT.NFT_CONTRACT}`, data);
};
