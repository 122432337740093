import END_POINT from './constants'
import http from './http'

export function getProjectCategory(params) {
  return http.get(`${END_POINT.GET_PROJECT_CATEGORY}?${params}`)
}

export function postProjectCategory(data) {
  return http.post(END_POINT.POST_PROJECT_CATEGORY, data)
}

export function getProjectCategoryById(id) {
  return http.get(`${END_POINT.GET_PROJECT_CATEGORY_BY_ID}/${id}`)
}

export function putProjectCategory(data) {
  return http.put(`${END_POINT.PUT_PROJECT_CATEGORY_BY_ID}/${data?.id}`, data)
}

export function deleteProjectCategory(id) {
  return http.delete(`${END_POINT.DELETE_PROJECT_CATEGORY_BY_ID}/${id}`)
}
