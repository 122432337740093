import { Flex } from "@chakra-ui/react";
import ButtonType from "components/Button";

export default function ActionGameMode({ handleOpenCreateOrUpdate }) {
  // const deleteItem = async (id) => {
  //   return deleteGameMode(id);
  // };

  // const handleDelete = async () => {
  //   try {
  //     const promise = checkedIds?.map((game) => {
  //       return deleteItem(game?.id);
  //     });
  //     const res = await Promise.all(promise);
  //     if (res && res[0]?.data?.success) {
  //       setCheckedIds([]);
  //       toast({
  //         title: "Delete success.",
  //         description: "Delete success.",
  //         status: "success",
  //         duration: 3000,
  //         isClosable: true,
  //         position: "top",
  //       });
  //       const newParams = {
  //         ...params,
  //         page: 1,
  //       };
  //       setParams(newParams);
  //       onClose();
  //     }
  //   } catch (error) {
  //     onClose();
  //     toast({
  //       description: error?.message || error?.messages[0],
  //       status: "error",
  //       position: "bottom-right",
  //     });
  //   }
  // };

  return (
    <Flex
      gap={4}
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="flex-end"
    >
      <Flex alignItems="flex-end" gap={6} mb="10px">
        <ButtonType
          text="Create"
          fontSize="16px"
          w="120px"
          onClick={() => handleOpenCreateOrUpdate()}
        />
      </Flex>
    </Flex>
  );
}
