import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { deleteEvent } from "api/event.api";
import ButtonType from "components/Button";
import ModalConfirm from "components/Modal/ModalConfirm";
import { useMemo } from "react";
import { STATUS_ONGOING } from "../constant";

export default function ActionEventManagement({
  checkedIds,
  setCheckedIds,
  params,
  setParams,
  handleOpenCreate,
}) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isHaveCheckedIds = useMemo(() => {
    return checkedIds?.length > 0;
  }, [checkedIds]);

  const isItemOnGoing = useMemo(() => {
    if (checkedIds && checkedIds?.length > 0) {
      const itemOnGoing = checkedIds?.find((item) => {
        if (STATUS_ONGOING?.includes(item?.status)) {
          return item;
        }
      });
      if (itemOnGoing) {
        return true;
      }
      return false;
    }
    return false;
  }, [checkedIds]);

  const deleteItem = async (id) => {
    return deleteEvent(id);
  };

  const handleDelete = async () => {
    try {
      if (isItemOnGoing) {
        toast({
          description: "Can't delete item ongoing",
          status: "warning",
          position: "bottom-right",
        });
        onClose();
        return;
      }
      if (isHaveCheckedIds) {
        const promise = checkedIds?.map((machine) => {
          return deleteItem(machine?.id);
        });
        const res = await Promise.all(promise);
        if (res && res[0]?.data?.success) {
          setCheckedIds([]);
          toast({
            title: "Delete success.",
            description: "Delete success.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          const newParams = {
            ...params,
            page: 1,
          };
          setParams(newParams);
          onClose();
        }
      }
    } catch (error) {
      onClose();
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex gap={4} flexWrap="wrap" alignItems="flex-end">
      <Flex alignItems="flex-end" gap={6} mb="10px">
        <ButtonType
          text="NEW"
          fontSize="12px"
          iconType="plus"
          sizeIcon="8px"
          onClick={() => {
            handleOpenCreate();
          }}
        />
        <ButtonType
          text="REMOVE"
          fontSize="12px"
          sizeIcon="8px"
          btnType="dark-outline"
          disabled={!isHaveCheckedIds}
          onClick={onOpen}
        />
      </Flex>
      <ModalConfirm isOpen={isOpen} onClose={onClose} onSubmit={handleDelete} />
    </Flex>
  );
}
