/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from "@chakra-ui/react";
import { FormProvider } from "react-hook-form";

import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import FormLabelComponent from "components/form/FormLabel";
import { useSelector } from "react-redux";
import { maintenanceSelector } from "stores/mantenance/selector";
import { getDayLLLL } from "utils/time";

export default function MaintenanceDetail({ isOpen, onClose }) {
  const textColor = useColorModeValue("#000000", "white");
  const { MaintenanceById, loadingMaintenance } = useSelector(
    maintenanceSelector
  );

  const renderForm = () => {
    return (
      <FormProvider>
        <Flex
          flexDirection="column"
          gap="24px"
        >
          <Grid templateColumns='repeat(3, 1fr)' gap="auto">
            <GridItem w='100%'>
              <FormLabelComponent mr={6} title={"Maintenance code"} />
              <Box color={textColor}>{MaintenanceById?.code || "---"}</Box>
            </GridItem>
            <GridItem w='100%' >
              <FormLabelComponent mr={6} title={"Maintenance title"} />
              <Box color={textColor}>{MaintenanceById?.title || "---"}</Box>
            </GridItem>
          </Grid>
          <Grid templateColumns='repeat(3, 1fr)' gap="auto">
            <GridItem w='100%'>
              <FormLabelComponent mr={6} title="Start time" />
              <Box color={textColor}>{MaintenanceById?.start_time
                ? getDayLLLL(MaintenanceById?.start_time * 1000)
                : "---"|| "---"}
              </Box>
            </GridItem>
            <GridItem w='100%' >
              <FormLabelComponent mr={6} title="End time" />
              <Box color={textColor}>{MaintenanceById?.end_time
                ? getDayLLLL(MaintenanceById?.end_time * 1000)
                : "---"|| "---"}
              </Box>
            </GridItem>
            <GridItem w='100%' >
              <FormLabelComponent mr={6} title="Notify" />
              <Box color={textColor}>{MaintenanceById?.notification_flg ? "TRUE" : "FALSE" || "---"}</Box>
            </GridItem>
          </Grid>
          <Grid templateColumns='repeat(3, 1fr)' gap="auto">
            <GridItem w='100%'>
              <FormLabelComponent mr={6} title="Send notification before" />
              <Box color={textColor}>{MaintenanceById?.name  || "---"}</Box>
            </GridItem>
            <GridItem w='100%' >
              <FormLabelComponent mr={6} title="Status" />
              <Box color={textColor}>{MaintenanceById?.status || "---"}</Box>
            </GridItem>
            <GridItem w='100%' >
              <FormLabelComponent mr={6} title="Last Modified At" />
              <Box color={textColor}>{MaintenanceById?.last_modified_by
                ? getDayLLLL(MaintenanceById?.last_modified_by * 1000)
                : "---"|| "---"}
              </Box>
            </GridItem>
          </Grid>
          <Grid templateColumns='repeat(3, 1fr)' gap="auto">
            <GridItem w='100%'>
              <FormLabelComponent mr={6} title="Last Modified By" />
              <Box color={textColor}>{MaintenanceById?.last_modified_by || "ADMIN"}</Box>
            </GridItem>
            <GridItem w='100%' >
              <FormLabelComponent mr={6} title="Created At" />
              <Box color={textColor}>{MaintenanceById?.create_time  
                ? getDayLLLL(MaintenanceById?.create_time * 1000)
                : "---"|| "---"}
              </Box>
            </GridItem>
            <GridItem w='100%' >
              <FormLabelComponent mr={6} title="Created By" />
              <Box color={textColor}>{MaintenanceById?.create_by || "ADMIN"}</Box>
            </GridItem>
          </Grid>
        </Flex>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent position="relative" minH="284px" maxW="700px">
        <ModalHeader
          margin="auto"
          color={textColor}
        >View Maintenance</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          padding="12px 24px 24px"
        >
          {loadingMaintenance ? (
            <Box position="absolute" top={0} left={0} w="100%" h="284px">
              <LoadingSpinner />
            </Box>
          ) : (
            renderForm()
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
