import * as yup from "yup";

const schemaSell = () =>
  yup.object().shape({
    price: yup
      .number()
      .nullable(true)
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .required("Required")
      .positive("Price must be greater than 0"),
  });

export default schemaSell;
