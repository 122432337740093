import { containSpecialRegex } from "utils/regex";
import * as yup from "yup";

const rewardItemSchema = yup.object().shape({
  item: yup.string().required("Required"),
  type: yup.string().when("item", {
    is: (val) => val.includes("TICKET"),
    then: () => yup.string().required("Required"),
    otherwise: () => yup.string(),
  }),
  amount: yup
    .number()
    .nullable(true)
    .when("item", {
      is: (val) => val.includes("TICKET"),
      then: () =>
        yup
          .number()
          .nullable(true)
          .transform((_, val) => {
            if (val) {
              return Number(val) || Number(val) === 0 ? Number(val) : null;
            }
            return null;
          })
          .integer("Must be an integer")
          .required("Required")
          .positive("Amount must be greater than 0"),
      otherwise: () =>
        yup
          .number()
          .nullable(true)
          .transform((_, val) => {
            if (val) {
              return Number(val) || Number(val) === 0 ? Number(val) : null;
            }
            return null;
          })
          .required("Required")
          .positive("Amount must be greater than 0"),
    }),
});

const rewardSchema = yup.object().shape({
  ranking_from: yup
    .number()
    .nullable(true)
    .transform((_, val) => {
      if (val) {
        return Number(val) || Number(val) === 0 ? Number(val) : null;
      }
      return null;
    })
    .integer("Must be an integer")
    .required("Required")
    .positive("Ranking from must be greater than 0"),
  ranking_to: yup
    .number()
    .nullable(true)
    .transform((_, val) => {
      if (val) {
        return Number(val) || Number(val) === 0 ? Number(val) : null;
      }
      return null;
    })
    .integer("Must be an integer")
    .required("Required")
    .positive("Ranking to be greater than 0")
    .min(
      yup.ref("ranking_from"),
      "Ranking to must be greater than or equal to ranking from"
    ),

  rewards: yup.array().of(rewardItemSchema),
});

const schemaCreateEvent = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required("Required")
      .max(30, "Name must be at most 30 characters")
      .matches(containSpecialRegex, "Name must not contain special characters"),
    name_jp: yup
      .string()
      .trim()
      .required("Required")
      .max(30, "Name must be at most 30 characters")
      .matches(containSpecialRegex, "Name must not contain special characters"),
    description: yup
      .string()
      .trim()
      .notRequired()
      .max(300, "Description must be at most 300 characters"),
    description_jp: yup
      .string()
      .trim()
      .notRequired()
      .max(300, "Description must be at most 300 characters"),
    rule_id: yup.string().trim().required("Required"),
    pre_register_start_time: yup
      .date()
      .test(
        "is-future-minute",
        "Pre-register end time must be in the future",
        function (value) {
          const now = new Date();
          now.setSeconds(0, 0); // Ignoring seconds
          const inputTime = new Date(value);
          inputTime.setSeconds(0, 0); // Ignoring seconds
          return inputTime.getTime() >= now.getTime();
        }
      )
      .required(),
    pre_register_end_time: yup
      .date()
      .test(
        "is-greater-pre_register_start_time",
        "Pre Register end time must be after pre register start time",
        function (value) {
          const { pre_register_start_time } = this.parent;
          return (
            pre_register_start_time &&
            value &&
            new Date(value) > new Date(pre_register_start_time)
          );
        }
      )
      .required(),
    register_end_time: yup
      .date()
      .test(
        "is-greater-pre_register_end_time",
        "Register end time must be after pre register end time",
        function (value) {
          const { pre_register_end_time } = this.parent;
          return (
            pre_register_end_time &&
            value &&
            new Date(value) > new Date(pre_register_end_time)
          );
        }
      )
      .required(),
    whitelist_end_time: yup
      .date()
      .test(
        "is-greater-register_end_time",
        "Whitelist end time must be after register end time",
        function (value) {
          const { register_end_time } = this.parent;
          return (
            register_end_time &&
            value &&
            new Date(value) > new Date(register_end_time)
          );
        }
      )
      .required(),
    event_end_time: yup
      .date()
      .test(
        "is-greater-whitelist_end_time",
        "Event end time must be after whitelist end time",
        function (value) {
          const { whitelist_end_time } = this.parent;
          return (
            whitelist_end_time &&
            value &&
            new Date(value) > new Date(whitelist_end_time)
          );
        }
      )
      .required(),
    result_end_time: yup
      .date()
      .test(
        "is-greater-event_end_time",
        "Result end time must be after event end time",
        function (value) {
          const { event_end_time } = this.parent;
          return (
            event_end_time &&
            value &&
            new Date(value) > new Date(event_end_time)
          );
        }
      )
      .required(),
    ranking_from_to: yup.string().nullable().required(),
    finalPrizes: yup.array().of(rewardSchema),
  });

export default schemaCreateEvent;
