/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { PROJECT_CATEGORY_NAMESPACE } from 'stores/constant'
import {
  addProjectCategoryApi,
  deleteProjectCategoryByIdApi,
  editProjectCategoryByIdApi,
  getProjectCategoryApi,
  getProjectCategoryByIdApi,
} from './action'

const name = PROJECT_CATEGORY_NAMESPACE
const initialState = createInitialState()
const reducers = createReducers()
const extraReducers = createExtraReducers()
const projectCategorySlice = createSlice({ name, initialState, reducers, extraReducers })

export const projectCategorySliceReducer = projectCategorySlice.reducer

function createInitialState() {
  return {
    loadingProjectCategory: false,
    errorProjectCategory: '',
    listProjectCategories: [],
    projectCategoryById: null,
    statusDelete: false,
  }
}

function createReducers() {
  return {}
}

function createExtraReducers() {
  return (builder) => {
    listProjectCategorys()
    addProjectCategory()
    getProjectCategoryById()
    editProjectCategory()
    deleteProjectCategoryById()

    function listProjectCategorys() {
      builder
        .addCase(getProjectCategoryApi.pending, (state) => {
          state.loadingProjectCategory = true
        })
        .addCase(getProjectCategoryApi.fulfilled, (state, action) => {
          state.loadingProjectCategory = false
          state.errorProjectCategory = ''
          if (action.payload) {
            state.listProjectCategories = action.payload
          }
        })
        .addCase(getProjectCategoryApi.rejected, (state, action) => {
          state.loadingProjectCategory = false
          state.errorProjectCategory = action.payload
        })
    }

    function addProjectCategory() {
      builder
        .addCase(addProjectCategoryApi.pending, (state) => {
          state.loadingProjectCategory = true
        })
        .addCase(addProjectCategoryApi.fulfilled, (state, action) => {
          state.loadingProjectCategory = false
          state.errorProjectCategory = ''
        })
        .addCase(addProjectCategoryApi.rejected, (state, action) => {
          state.loadingProjectCategory = false
          state.errorProjectCategory = action.payload
        })
    }

    function getProjectCategoryById() {
      builder
        .addCase(getProjectCategoryByIdApi.pending, (state) => {
          state.loadingProjectCategory = true
          state.projectCategoryById = null
        })
        .addCase(getProjectCategoryByIdApi.fulfilled, (state, action) => {
          state.loadingProjectCategory = false
          state.errorProjectCategory = ''
          if (action.payload) {
            state.projectCategoryById = action.payload?.data
          }
        })
        .addCase(getProjectCategoryByIdApi.rejected, (state, action) => {
          state.loadingProjectCategory = false
          state.errorProjectCategory = action.payload
        })
    }

    function editProjectCategory() {
      builder
        .addCase(editProjectCategoryByIdApi.pending, (state) => {
          state.loadingProjectCategory = true
        })
        .addCase(editProjectCategoryByIdApi.fulfilled, (state, action) => {
          state.loadingProjectCategory = false
          state.errorProjectCategory = ''
        })
        .addCase(editProjectCategoryByIdApi.rejected, (state, action) => {
          state.loadingProjectCategory = false
          state.errorProjectCategory = action.payload
        })
    }

    function deleteProjectCategoryById() {
      builder
        .addCase(deleteProjectCategoryByIdApi.pending, (state, action) => {
          state.statusDelete = true
        })
        .addCase(deleteProjectCategoryByIdApi.fulfilled, (state, action) => {
          state.statusDelete = false
          state.errorProjectCategory = ''
        })
        .addCase(deleteProjectCategoryByIdApi.rejected, (state, action) => {
          state.statusDelete = false
          state.errorProjectCategory = action.payload
        })
    }
  }
}
