import * as yup from "yup";

const ratesSchema = () =>
  yup.object().shape({
    rate: yup
      .number()
      .min(0, "Must be greater than or equal to 0")
      .nullable(true)
      .required("Required"),
  });

export default ratesSchema;
