import {
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getGauntletNFTs } from "api/gauntlet.api";
import ButtonType from "components/Button";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ModalSellNFTGauntlet from "../modalSell";
import ListBatchSellGauntlet from "./listBatchSell";

export default function ModalSellBatchNFTGauntlet({
  isOpen,
  onClose,
  handleSellSuccess,
}) {
  const [nftsChecked, setNFTsChecked] = useState([]);
  const toast = useToast();
  const form = useForm();

  const {
    isOpen: isOpenSell,
    onOpen: onOpenSell,
    onClose: onCloseSell,
  } = useDisclosure();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const LIMIT = 6;
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [totalPage, setTotalPage] = useState(1);

  const [gauntletNFTs, setGauntletNFTs] = useState([]);

  const [params, setParams] = useState({
    limit: LIMIT,
    page: 1,
    "sale-status": "NEW",
  });

  const getListGauntletNFTs = useCallback(async () => {
    try {
      setIsLoadingMore(true);
      const { data } = await getGauntletNFTs(params);
      const list = data?.data?.records;
      if (list && list?.length > 0) {
        setGauntletNFTs((prev) => {
          return prev?.concat(list);
        });
      }
      setTotalPage(data?.data?.total_page);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoadingMore(false);
      setIsLoading(false);
    }
  }, [params]);

  useEffect(() => {
    if (isOpen) {
      getListGauntletNFTs();
    } else {
      setGauntletNFTs([]);
    }
  }, [getListGauntletNFTs, isOpen]);

  const handleClose = () => {
    setNFTsChecked([]);
    onClose();
  };

  const onSubmit = async () => {
    onOpenSell();
  };

  const renderForm = () => {
    return (
      <FormProvider {...form} w="100%">
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="list-sell-batch-nfts-gauntlet-form-id"
          style={{
            width: "100%",
            overflowX: "auto",
          }}
        >
          <ListBatchSellGauntlet
            data={gauntletNFTs}
            nftsChecked={nftsChecked}
            setNFTsChecked={setNFTsChecked}
          />
        </form>
      </FormProvider>
    );
  };

  return (
    <>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={() => {
          handleClose();
        }}
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent maxW="685px" p="28px 24px">
          <ModalHeader
            w="100%"
            textAlign="center"
            margin="auto"
            p="16px 24px 0px 24px"
          >
            <Text fontSize="24px" fontWeight="700">
              Sell batch NFTs
            </Text>
            <Text fontSize="16px" fontWeight="500" mt="16px">
              Choose Sell Batch NFTs ({nftsChecked?.length})
            </Text>
          </ModalHeader>
          <ModalCloseButton top="30px" />
          <ModalBody p="0px">
            {isLoading ? (
              <Center minH="200px" m="auto">
                <LoadingSpinner />
              </Center>
            ) : (
              renderForm()
            )}
          </ModalBody>
          <ModalFooter w="100%" textAlign="center" margin="auto">
            <Flex
              w="100%"
              direction="column"
              alignItems="center"
              gap={4}
              pb="4px"
            >
              {totalPage > params?.page && (
                <ButtonType
                  fontSize="16px"
                  fontWeight="500"
                  h="44px"
                  btnType="primary-new-outline"
                  borderRadius="30px"
                  boxShadow="none"
                  color="#000000"
                  p="12px 40px"
                  isLoading={isLoadingMore}
                  onClick={() => {
                    setParams((prev) => ({
                      ...prev,
                      limit: LIMIT,
                      page: prev?.page + 1,
                    }));
                  }}
                >
                  Load More
                </ButtonType>
              )}

              <Flex gap="16px">
                <ButtonType
                  mt={4}
                  m="auto"
                  w="220px"
                  h="46px"
                  borderRadius="8px"
                  btnType="primary-new-outline"
                  fontSize="16px"
                  fontWeight="500"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </ButtonType>
                <ButtonType
                  mt={4}
                  m="auto"
                  w="220px"
                  h="46px"
                  borderRadius="8px"
                  type="submit"
                  form="list-sell-batch-nfts-gauntlet-form-id"
                  fontSize="16px"
                  fontWeight="500"
                  disabled={nftsChecked?.length <= 0}
                  isLoading={isSubmitting}
                >
                  Sell batch NFTs
                </ButtonType>
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isOpenSell && (
        <ModalSellNFTGauntlet
          isOpen={isOpenSell}
          itemSell={nftsChecked}
          onClose={onCloseSell}
          type="batch"
          handleSellSuccess={handleSellSuccess}
        />
      )}
    </>
  );
}
