import { Center, Flex, useDisclosure } from "@chakra-ui/react";
import MachineMapItem from "components/Map/ItemMachine";
import { useEffect, useState } from "react";
import DetailCameraModal from "./modalDetail";

export default function MainMap({ machines, isLoading, handleSwitchStatus, gamesModeOptions, getListMachine }) {
  const [dataDetail, setDataDetail] = useState(null);
  const [idDetail, setIdDetail] = useState(null);
  const [cameraType, setCameraType] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (machines?.length > 0 && idDetail) {
      const detail = machines?.find((item) => item?.id === idDetail);
      if (detail) {
        setDataDetail(detail);
      }
    }
  }, [machines, idDetail]);

  const handleOpenDetail = (item, type) => {
    setCameraType(type);
    setDataDetail(item);
    setIdDetail(item?.id);
    onOpen();
  };

  const handleCloseDetail = () => {
    setDataDetail(null);
    setIdDetail(null);
    onClose();
  };

  return (
    <>
      <Flex w="100%" gap={4} flexWrap="wrap">
        {machines?.length > 0
          ? machines?.map((item, index) => {
              return (
                <MachineMapItem
                  key={item?.id}
                  item={item}
                  index={index}
                  machine={item?.machine}
                  gamesModeOptions={gamesModeOptions}
                  getListMachine={getListMachine}
                  handleOpenDetail={(type) => {
                    handleOpenDetail(item, type);
                  }}
                  handleSwitchStatus={() => {
                    handleSwitchStatus(item?.machine);
                  }}
                />
              );
            })
          : !isLoading && (
              <Center w="100%" minH="100px">
                No Data
              </Center>
            )}
      </Flex>

      {isOpen && (
        <DetailCameraModal
          dataDetail={dataDetail}
          cameraType={cameraType}
          isOpen={isOpen}
          onClose={handleCloseDetail}
        />
      )}
    </>
  );
}
