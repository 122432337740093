import {
  Flex,
  Icon,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { getRateApi } from "api/rate.api.js";
import BigNumber from "bignumber.js";
import ButtonType from "components/Button";
import { BCoinIcon } from "components/Icons/currencies/BCoinIcon.js";
import FormInput from "components/form/FormInput";
import useWithToast from "hooks/useWithToast.js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import schemaSell from "./schema.js";
import {
  sellMultipleProductNFTs,
  sellSingleProductNFTs,
} from "api/products.api.js";

export default function ModalSellNFTBountyBall({
  isOpen,
  onClose,
  itemSell,
  handleSellSuccess,
  handleSellDetailSuccess,
  type = "single",
}) {
  const toast = useToast();
  const { showToastSuccess } = useWithToast();

  const [rateBCoinToBTH, setRateBCoinToBTH] = useState(1);
  const [rateBCoinToUSD, setRateBCoinToUSD] = useState(1);

  const getRate = async () => {
    try {
      const { data } = await getRateApi();
      const list = data?.data?.records;
      if (list && list?.length > 0) {
        list?.map((item) => {
          if (item?.id === "BCOIN_TO_BTH") {
            setRateBCoinToBTH(Number(item?.exchange_rate));
          } else if (item?.id === "BCOIN_TO_USD") {
            setRateBCoinToUSD(Number(item?.exchange_rate));
          }
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      getRate();
    }
  }, [getRate, isOpen]);

  const defaultValues = {
    price: null,
    bth: 0,
    usd: 0,
    fee: 0,
  };
  const form = useForm({
    resolver: yupResolver(schemaSell()),
    defaultValues,
  });

  const {
    reset,
    setValue,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const handleClose = () => {
    onClose();
    reset(defaultValues);
  };

  const updateCoin = useCallback(
    (priceWatch) => {
      if (priceWatch && Number(priceWatch) > 0 && rateBCoinToBTH) {
        const price = new BigNumber(priceWatch);
        const dataBTH = price.multipliedBy(rateBCoinToBTH)?.toString();
        const dataUSD = price.multipliedBy(rateBCoinToUSD)?.toString();
        if (dataBTH && dataUSD) {
          setValue("bth", dataBTH);
          setValue("usd", dataUSD);
        }
      } else {
        setValue("bth", 0);
        setValue("usd", 0);
      }
    },
    [rateBCoinToBTH, rateBCoinToUSD]
  );

  const receivedAmount = useMemo(() => {
    const price = watch("price");
    const fee = watch("fee");
    if (price) {
      return new BigNumber(price).minus(fee).toNumber();
    }
    return 0;
  }, [watch("price"), watch("fee")]);

  const onSubmit = async (data) => {
    try {
      if (type === "single") {
        const res = await sellSingleProductNFTs({
          product_id: itemSell?.product_model?.id,
          price: data?.price,
        });
        if (res) {
          handleSellSuccess && handleSellSuccess([itemSell?.product_model?.id]);
          handleSellDetailSuccess && handleSellDetailSuccess(data?.price);
          showToastSuccess({
            title: "Sell successfully!",
          });
          handleClose();
        }
      } else {
        const NFTsSell = itemSell?.map((item) => {
          return {
            product_id: item?.product_model?.id,
            price: data?.price,
          };
        });
        const res = await sellMultipleProductNFTs(NFTsSell);
        if (res) {
          handleSellSuccess &&
            handleSellSuccess(
              itemSell?.map((item) => {
                const productModel = { ...item?.product_model };
                return {
                  ...item,
                  product_model: {
                    ...productModel,
                    price: data?.price,
                  },
                };
              })
            );
          showToastSuccess({
            title: "Sell successfully!",
          });
          handleClose();
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const ChoiceCurrency = () => {
    return (
      <Flex direction="column" gap="16px" alignItems="flex-start" mb="16px">
        <Text fontSize="16px" fontWeight="500">
          Sale Currency
        </Text>
        <Flex
          p="8px 16px"
          borderRadius="10px"
          border="1px solid #FFCF3E"
          bg="#FFCF3E66"
          alignItems="center"
          gap="4px"
        >
          <Icon as={BCoinIcon} fontSize="24px" />
          <Text fontSize="16px" fontWeight="500">
            Bcoin
          </Text>
        </Flex>
      </Flex>
    );
  };

  const renderIconLeftInput = () => {
    return (
      <InputLeftElement pointerEvents="none" w="auto" mt="6px">
        <Flex alignItems="center" gap="8px" p="16px 20px">
          <Icon as={BCoinIcon} fontSize="24px" />
          <Text fontSize="14px" fontWeight="600">
            Bcoin
          </Text>
        </Flex>
      </InputLeftElement>
    );
  };

  const InformationSell = () => {
    return (
      <Flex
        borderRadius="10px"
        border="1px solid #FFCF3E"
        direction="column"
        w="100%"
        p="10px 16px"
        mt="16px"
        bg="#FFCF3E33"
        gap="8px"
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontSize="14px" fontWeight="400" color="#969696">
            Convert Bcoin to BTH
          </Text>
          <Text fontSize="14px" fontWeight="400" color="#000000">
            {watch("bth")} BTH
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontSize="14px" fontWeight="400" color="#969696">
            Convert Bcoin to USD
          </Text>
          <Text fontSize="14px" fontWeight="400" color="#000000">
            {watch("usd")} USD
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontSize="14px" fontWeight="400" color="#969696">
            Resale type
          </Text>
          <Text fontSize="14px" fontWeight="400" color="#000000">
            Bcoin
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontSize="14px" fontWeight="400" color="#969696">
            Input price
          </Text>
          <Text fontSize="14px" fontWeight="400" color="#000000">
            {watch("price")} Bcoin
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontSize="14px" fontWeight="400" color="#969696">
            Transaction fee
          </Text>
          <Text fontSize="14px" fontWeight="400" color="#000000">
            0 BTH
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontSize="14px" fontWeight="400" color="#969696">
            Received amount
          </Text>
          <Text fontSize="14px" fontWeight="700" color="#000000">
            {receivedAmount} BTH
          </Text>
        </Flex>
      </Flex>
    );
  };

  const renderForm = () => {
    return (
      <FormProvider {...form} w="100%">
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="list-sell-nft-bounty-ball-form-id"
          style={{
            width: "100%",
            overflowX: "auto",
            padding: "2px",
          }}
        >
          <ChoiceCurrency />
          <FormInput
            label="Input Price"
            isRequired
            name="price"
            type="number"
            borderRadius="100px"
            h="56px"
            placeholder="Price"
            renderLeft={renderIconLeftInput}
            textAlign="end"
            fontSize="14px"
            fontWeight="400"
            onChange={(e) => {
              const value = e.target.value;
              setValue("price", value);
              updateCoin(value);
            }}
          />
          <InformationSell />
        </form>
      </FormProvider>
    );
  };
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => {
        handleClose();
      }}
      size="xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent maxW="685px" p="24px">
        <ModalHeader w="100%" textAlign="center" margin="auto">
          <Text fontSize="24px" fontWeight="700">
            Sell NFT
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="0px">{renderForm()}</ModalBody>
        <ModalFooter w="100%" textAlign="center" margin="auto" p="16px 0px">
          <Flex
            w="100%"
            alignItems="center"
            justifyContent="space-between"
            gap="16px"
            pb="4px"
          >
            <ButtonType
              mt={4}
              m="auto"
              w="100%"
              h="46px"
              borderRadius="8px"
              btnType="primary-new-outline"
              fontSize="16px"
              fontWeight="500"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </ButtonType>
            <ButtonType
              mt={4}
              m="auto"
              w="100%"
              h="46px"
              borderRadius="8px"
              type="submit"
              form="list-sell-nft-bounty-ball-form-id"
              fontSize="16px"
              fontWeight="500"
              isLoading={isSubmitting}
              // disabled={receivedAmount <= 0}
            >
              Sell
            </ButtonType>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
