import {
  Box,
  Flex,
  NumberInput,
  NumberInputField,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

const PaginationStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  padding: 20px 20px 0px;
`;

const WrapperReactPaginateStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const ItemRel = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  outline: 2px solid transparent;
  border: 1px solid lightgray;
  border-radius: 100%;
  position: relative;
  font-weight: 400;
  font-size: 1rem;
  color: #8392ab;
  cursor: pointer;
  &:hover {
    background: #e9ecef;
  }
  &.disabled {
    cursor: default;
    pointer-events: none;
    color: lightgray;
  }
`;

const ReactPaginateStyled = styled(ReactPaginate)`
  display: flex;
  gap: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  li {
    width: 36px;
    height: 36px;
    outline: 2px solid transparent;
    border: 1px solid lightgray;
    border-radius: 100%;
    position: relative;
    font-weight: 400;
    font-size: 1rem;
    color: #8392ab;
    &:hover {
      background: #e9ecef;
    }
    &.selected {
      background-image: linear-gradient(90deg, #FFC515 0%, #F79325 100%);
      border: none;
      color: white;
    }
    a {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    &.disabled {
      cursor: default;
      pointer-events: none;
      color: lightgray;
    }
  }
`;

const GoToStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const OPTION_LIMIT = [
  {
    label: "10",
    value: 10,
  },
  {
    label: "20",
    value: 20,
  },
  {
    label: "50",
    value: 50,
  },
];

export default function Paginate({
  page = 1,
  limit,
  onChangeLimit,
  pageCount = 10,
  handleChangePage,
  pageRangeDisplayed = 5,
}) {
  const textColor = useColorModeValue("#000000", "black");
  const [pageGoto, setPageGoto] = useState(null);

  const handleChangeClick = (e) => {
    const newOffset = e.selected;
    handleChangePage && handleChangePage(newOffset + 1);
  };

  const handleChange = (newValue) => {
    if (+newValue === 0) {
      return setPageGoto(() => null);
    }
    if (+newValue > +pageCount) {
      setPageGoto(() => pageCount);
    } else {
      setPageGoto(() => newValue);
    }
  };

  return (
    <PaginationStyled>
      {limit && (
        <Select
          maxW="70px"
          defaultValue={+limit}
          value={+limit}
          fontSize="sm"
          color={textColor}
          onChange={(e) => {
            onChangeLimit && onChangeLimit(e.target.value);
          }}
        >
          {OPTION_LIMIT.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </Select>
      )}
      <Flex alignItems="center" gap={4}>
        <WrapperReactPaginateStyled>
          <ItemRel
            onClick={() => {
              handleChangePage && handleChangePage(1);
            }}
            className={`${page === 1 && "disabled"}`}
          >
            {"<<"}
          </ItemRel>
          <ReactPaginateStyled
            breakLabel="..."
            nextLabel=">"
            onPageChange={handleChangeClick}
            pageRangeDisplayed={pageRangeDisplayed}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            forcePage={page - 1}
          />
          <ItemRel
            onClick={() => {
              handleChangePage && handleChangePage(+pageCount);
            }}
            className={`${
              (+page === +pageCount || +pageCount === 0) && "disabled"
            }`}
          >
            {">>"}
          </ItemRel>
        </WrapperReactPaginateStyled>

        <GoToStyled>
          <Text color={textColor} fontSize="sm">
            Go to
          </Text>
          <NumberInput
            fontSize="sm"
            value={pageGoto || ""}
            onKeyDown={(e) => {
              if (e?.key === "-" || e?.key === "+" || e?.key === "e") {
                e.preventDefault();
              }
              if (e.key === "Enter") {
                handleChangePage && handleChangePage(pageGoto);
              }
            }}
            onChange={handleChange}
          >
            <NumberInputField maxW="60px" p="0px 12px" />
          </NumberInput>
        </GoToStyled>
      </Flex>
    </PaginationStyled>
  );
}
