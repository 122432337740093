import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getMachineSettings = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.GET_MACHINE_SETTINGS}?${params}`);
};

export const getDetailMachineSettings = (id) => {
  return http.get(`${END_POINT.GET_MACHINE_SETTINGS}/${id}`);
};

export const createMachineSetting = (data, option = {}) => {
  return http.post(END_POINT.GET_MACHINE_SETTINGS, data, option);
};

export const updateMachineSetting = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.GET_MACHINE_SETTINGS}/${idEdit}`, data, option);
};

export const updateGameMode = ( data) => {
  return http.put(`${END_POINT.GET_MACHINE_SETTINGS}/change-game-mode`, data);
};

export const changeStatusMachineSetting = (data, option = {}) => {
  return http.put(
    `${END_POINT.GET_MACHINE_SETTINGS}/change-status`,
    data,
    option
  );
};

export const deleteMachinesSettings = (params, option = {}) => {
  return http.delete(END_POINT.GET_MACHINE_SETTINGS, params, option);
};

export const deleteMachineSettings = (id, option = {}) => {
  return http.delete(`${END_POINT.GET_MACHINE_SETTINGS}/${id}`, option);
};

export const getListCraneMachines = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.CRANE_MACHINES}?${params}`);
};

export const getListRealCraneMachines = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.CRANE_MACHINES}/real-machines-state?${params}`);
};

export const getListCraneMachinesUnUsed = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.CRANE_MACHINES}/unused?${params}`);
};

export const changeDirectionMachine = (data, option = {}) => {
  return http.put(`${END_POINT.CRANE_MACHINES}/change-direction`, data, option);
};

export const changeChromaKeyMachine = (data, option = {}) => {
  return http.put(`${END_POINT.CRANE_MACHINES}/change-chromakey`, data, option);
};
