export const ViewIcon = (props) => (
  <svg
    width={30}
    height={31}
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.5" width={30} height={30} rx={8} fill="#FEB72B" />
    <path
      d="M15.0001 16.8307C15.7365 16.8307 16.3334 16.2338 16.3334 15.4974C16.3334 14.761 15.7365 14.1641 15.0001 14.1641C14.2637 14.1641 13.6667 14.761 13.6667 15.4974C13.6667 16.2338 14.2637 16.8307 15.0001 16.8307Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.6667 15.4987C19.8887 18.61 17.6667 20.1654 15 20.1654C12.3334 20.1654 10.1114 18.61 8.33337 15.4987C10.1114 12.3874 12.3334 10.832 15 10.832C17.6667 10.832 19.8887 12.3874 21.6667 15.4987Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
