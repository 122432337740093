import { Flex, Text, useColorModeValue, useDisclosure, useToast } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import MaintenanceRow from "components/Tables/maintenance/maintenance";
import { ACTION_PAGE, FIRST_PAGE, LIMIT_PAGE } from "constants/constants";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MantenanceCreateAndUpdate from "./components/createAndUpdate";
import MaintenanceDetail from "./components/detail";
import ActionMaintenance from "./action";
import { deleteMaintenance, getMaintenance } from "api/maintenance.api";
import { getMaintenanceByIdApi } from "stores/mantenance/action";

export default function Maintenance() {
  const { t } = useTranslation("mantenance");
  const dispatch = useDispatch();
  const textColor = useColorModeValue("#000000", "white");
  const [isLoading, setIsLoading] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const actionPageRef = useRef(ACTION_PAGE.VIEW);
  const [params, setParams] = useState({
    page: FIRST_PAGE,
    limit: LIMIT_PAGE,
  });
  const toast = useToast();

  const [dataTable, setDataTable] = useState([]);
  const [totalPage, setTotalPage] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();

  const handleClose = () => {
    onClose();
    setDataDetail(null);
  };

  const handleDetailClose = () => {
    onCloseDetail();
  };

  const getListMaintenance = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getMaintenance({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setDataTable(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getListMaintenance();
  }, [getListMaintenance]);

  const handleOpenCreateOrUpdate = (data = null) => {
    setDataDetail(data);
    onOpen();
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    handleClose();
    setDataDetail(null);
    setParams({
      ...params,
      page: FIRST_PAGE,
    });
  }, [params]);

  const labels = [
    t("label_maintenance_code"),
    t("label_maintenance_title"),
    t("label_start_time"),
    t("label_end_time"),
    t("label_notify"),
    t("label_send_notify"),
    t("label_status"),
    t("label_last_modified_at"),
    t("label_last_modified_by"),
    t("label_create_at"),
    t("label_ceateed_by"),
    "",
  ];

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const handleSetDataDetail = (id = "") => {
    dispatch(getMaintenanceByIdApi(id));
    onOpenDetail();
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: FIRST_PAGE,
      limit,
    });
  };

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(dataTable);
    } else {
      setCheckedIds([]);
    }
  };

  const handleCancelMaintenance = async (id = "") => {
    const data = await deleteMaintenance(id);
    if(data.status === 200) {
      getListMaintenance()
    }
  }

  return (
    <>
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <Card px="0px">
          <CardHeader px="22px" mb="12px">
            <Flex
              w="100%"
              gap={4}
              direction={{
                base: "column",
                md: "row",
              }}
              justifyContent={{
                base: "flex-start",
                md: "space-between",
              }}
              alignItems="flex-start"
            >
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                {t("maintenance_setting_title")}
              </Text>
              <ActionMaintenance
                handleOpenCreateOrUpdate={() => handleOpenCreateOrUpdate()}
                checkedIds={checkedIds}
                setCheckedIds={setCheckedIds}
                setParams={setParams}
                params={params}
              />
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <TableCustom
              labels={labels}
              isLoading={isLoading}
              isCheck
              isChecked={
                dataTable?.length > 0 && checkedIds.length === dataTable?.length
              }
              onChangeChecked={onChangeChecked}
              dataRow={dataTable}
            >
              <>
                {dataTable?.map((item) => {
                  return (
                    <MaintenanceRow
                      key={item?.id}
                      setCheckedIds={setCheckedIds}
                      checkedIds={checkedIds}
                      handleSetDataDetail={handleSetDataDetail}
                      handleCancelMaintenance={handleCancelMaintenance}
                      handleOpenCreateOrUpdate={() => {
                        handleOpenCreateOrUpdate(item);
                      }}
                      item={item}
                    />
                  );
                })}
              </>
            </TableCustom>
          </CardBody>
          {(totalPage && (
            <Paginate
              page={params?.page}
              pageCount={totalPage}
              limit={params.limit}
              onChangeLimit={onChangeLimit}
              handleChangePage={handleChangePage}
            />
          )) ||
            ""}
        </Card>
        {isOpenDetail && (
          <MaintenanceDetail
            isOpen={isOpenDetail}
            onClose={handleDetailClose}
          />
        )}
        {isOpen && (
          <MantenanceCreateAndUpdate
            isOpen={isOpen}
            onClose={handleClose}
            dataDetail={dataDetail}
            handle={actionPageRef.current}
            handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
            handleViewClose={handleClose}
          />
        )}
      </Flex>
    </>
  );
}
