export const DeleteIcon = (props) => (
  <svg
    width={30}
    height={31}
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.5" width={30} height={30} rx={8} fill="#E50000" />
    <path
      d="M12.75 11.75V9.5C12.75 9.30109 12.829 9.11032 12.9697 8.96967C13.1103 8.82902 13.3011 8.75 13.5 8.75H16.5C16.6989 8.75 16.8897 8.82902 17.0303 8.96967C17.171 9.11032 17.25 9.30109 17.25 9.5V11.75M9 11.75H21H9ZM13.5 14.75V19.25V14.75ZM16.5 14.75V19.25V14.75ZM9.75 11.75L10.5 20.75C10.5 21.1478 10.658 21.5294 10.9393 21.8107C11.2206 22.092 11.6022 22.25 12 22.25H18C18.3978 22.25 18.7794 22.092 19.0607 21.8107C19.342 21.5294 19.5 21.1478 19.5 20.75L20.25 11.75H9.75Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
