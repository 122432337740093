import { ViewIcon } from "@chakra-ui/icons";
import {
  Flex,
  Icon,
  Image,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import SwitchType from "components/Switch";
import { STATUS } from "constants/constants";
import moment from "moment";
import { useMemo } from "react";
import { MdEdit } from "react-icons/md";
import { splitEclipseCharacter } from "utils/string";
import {
  DIRECTION,
  MACHINE_TYPE_OPTIONS,
} from "views/Machine/MachineSettings/constants";

const MachineRow = ({
  machine,
  checkedIds,
  setCheckedIds,
  handleSetDataDetail,
  handleOpenCreateOrUpdate,
  listGamePlayType,
  handleSwitchStatus,
  handleSwitchDirection,
  gamesModeOptions,
}) => {
  const textColor = useColorModeValue("#000000", "white");
  const isMachineChecked = useMemo(() => {
    return checkedIds.some((item) => item?.id === machine?.id);
  }, [machine?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isMachineChecked) {
      setCheckedIds(checkedIds.filter((item) => item?.id !== machine?.id));
    } else {
      setCheckedIds([...checkedIds, machine]);
    }
  };

  const renderState = () => {
    return machine?.status === STATUS.ACTIVE
      ? STATUS.AVAILABLE
      : STATUS.MAINTENANCE;
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isMachineChecked}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.crane_machine?.mac}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Image
            src={machine?.icon_url}
            w="100%"
            h="100%"
            alt={machine?.icon_url}
          />
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400} minW="90px">
            {renderState()}
          </Text>
          <SwitchType
            isChecked={machine?.status === STATUS.ACTIVE}
            onChange={handleSwitchStatus}
          />
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400} minW="40px">
            {DIRECTION[machine?.crane_machine?.direction]}
          </Text>
          <SwitchType
            isChecked={machine?.crane_machine?.direction === STATUS.LEFT}
            onChange={handleSwitchDirection}
          />
        </Stack>
      </Td>
      <Td minW="180px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {
            MACHINE_TYPE_OPTIONS.find(
              (item) => item.value === machine?.machine_type
            )?.label
          }
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {splitEclipseCharacter(
            listGamePlayType?.find(
              (item) => item?.value === machine?.gameplay_type_id
            )?.label
          )}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {machine?.game_mode_id &&
            gamesModeOptions?.find(
              (item) => item?.value === machine?.game_mode_id
            )?.label}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {moment(machine?.update_time * 1000)
            .utc()
            .format("DD MMM YYYY HH:mm:ss")}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {machine?.update_by || "Admin"}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {moment(machine?.create_time * 1000)
            .utc()
            .format("DD MMM YYYY HH:mm:ss")}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {machine?.create_by || "Admin"}
        </Text>
      </Td>
      <Td>
        <Flex gap={6} align="center">
          <Icon
            onClick={() => handleSetDataDetail()}
            fontSize="16px"
            cursor="pointer"
            color={textColor}
            as={ViewIcon}
          />
          <Icon
            onClick={() => handleOpenCreateOrUpdate()}
            fontSize="16px"
            cursor="pointer"
            color={textColor}
            as={MdEdit}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default MachineRow;
