import { containSpecialRegex } from "utils/regex";
import * as yup from "yup";

const schemaRuleEvent = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required("Required")
      .max(30, "Name must be at most 30 characters")
      .matches(containSpecialRegex, "Name must not contain special characters"),
    win_point: yup
      .number()
      .nullable(true)
      .max(99999999, "Win point must be at most 8 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer")
      .required("Required")
      .positive("Win point must be greater than 0"),
    turn_duration: yup
      .number()
      .min(1)
      .nullable(true)
      .max(9999, "Turn duration must be at most 4 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer")
      .required("Required")
      .positive("Turn duration must be greater than 0"),
  });

export default schemaRuleEvent;
