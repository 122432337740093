import * as yup from 'yup'
import i18next from 'I18n'

export function loginValidate() {
  return yup.object().shape({
    email: yup
      .string()
      .trim()
      .email(i18next.t('auth:email_NotMatch'))
      .required(i18next.t('auth:email_required'))
      .matches(/^[a-zA-Z0-9.(@\-_)]*$/, i18next.t('auth:email_NotFormat'))
      .max(255),
    password: yup.string().required(i18next.t('auth:input_password_required')),
    // .min(8, i18next.t('auth:input_password_required'))
  })
}
