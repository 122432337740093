export const inputStyles = {
  components: {
    Input: {
      baseStyle: {
        field: {
          _disabled: {
            backgroundColor: "#DBDBDB",
            cursor: "not-allowed",
            color: "#969696",
          },
        },
      },
    },
  },
};
