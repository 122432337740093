import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getAdminsApi = (p = {}) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.GET_ADMIN}?${params}`);
};

export const createAdminApi = (data) => {
  return http.post(`${END_POINT.CREATE_ADMIN}`, data);
};

export const updateAdminApi = (data) => {
  return http.put(`${END_POINT.UPDATE_ADMIN}`, data);
};

export const updateBlockAdminApi = (id, data) => {
  return http.put(`${END_POINT.CREATE_ADMIN}/${id}`, data);
};
