import {
  Box,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import { useCallback, useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { MdClose, MdOutlineAdd } from "react-icons/md";
import { OPTIONS_REWARDS } from "views/Event/constant";
import NFTListEvent from "../../NFTList";

export default function BoxFinalPrize({
  indexItem,
  control,
  watch,
  defaultDataReward,
  removeFinalPrize,
  tickets,
  transLabel,
  disableWhenJP,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const textColor = useColorModeValue("#000000", "white");
  const useSubItemFields = (indexItem) => {
    return useFieldArray({
      control,
      name: `finalPrizes[${indexItem}].rewards`,
    });
  };

  const ItemReward = ({ indexSub, removeReward }) => {
    const watchItem = watch(
      `finalPrizes[${indexItem}].rewards[${indexSub}].item`
    );

    const isItemNFT = useMemo(() => {
      return watchItem === "NFT";
    }, [watchItem]);

    const isItemTicket = useMemo(() => {
      return watchItem === "TICKET";
    }, [watchItem]);

    const handleAddNFT = (data) => {
      console.log("ccc data", data);
    };

    const renderNFT = () => {
      return (
        <>
          <Text fontWeight="400" fontSize="16px" mt="12px" color="#000000">
            NFT
          </Text>
          <FormInput
            name={`finalPrizes[${indexItem}].rewards.${indexSub}.amount`}
            w="200px"
            isDisabled={disableWhenJP}
            placeholder="Search for NFT"
            onWheel={() => undefined}
            border="1px solid #EEEFF2"
            background="#F9FAFB"
            cursor="pointer"
            onClick={() => {
              onOpen();
            }}
          />
          <NFTListEvent
            isOpen={isOpen}
            onClose={onClose}
            handleAddNFT={handleAddNFT}
          />
        </>
      );
    };

    return (
      <Flex align="flex-start" gap={4} ml={8} flexWrap="wrap">
        <Flex gap={4}>
          <Text
            fontWeight="400"
            fontSize="16px"
            mt="8px"
            color="#000000"
            minW={{
              base: "60px",
              lg: "auto",
            }}
          >
            {transLabel("item")}
          </Text>
          <FormSelect
            name={`finalPrizes[${indexItem}].rewards[${indexSub}].item`}
            isDisabled={disableWhenJP}
            options={OPTIONS_REWARDS}
            defaultValue={OPTIONS_REWARDS[0].value}
            w={{
              base: "206px",
              lg: "166px",
            }}
          />
        </Flex>

        {isItemNFT ? (
          renderNFT()
        ) : (
          <>
            {isItemTicket && (
              <Flex gap={4}>
                <Text
                  fontWeight="400"
                  fontSize="16px"
                  mt="8px"
                  color="#000000"
                  minW={{
                    base: "60px",
                    lg: "auto",
                  }}
                >
                  {transLabel("type")}
                </Text>
                <FormSelect
                  name={`finalPrizes[${indexItem}].rewards.${indexSub}.type`}
                  isDisabled={disableWhenJP}
                  options={tickets}
                  defaultValue={
                    tickets && tickets?.length > 0 && tickets[0].value
                  }
                  w="206px"
                />
              </Flex>
            )}
            <Flex gap={4}>
              <Text
                fontWeight="400"
                fontSize="16px"
                mt="8px"
                color="#000000"
                minW={{
                  base: "60px",
                  lg: "auto",
                }}
              >
                {transLabel("amount")}
              </Text>
              <FormInput
                name={`finalPrizes[${indexItem}].rewards.${indexSub}.amount`}
                isDisabled={disableWhenJP}
                w="200px"
                onWheel={() => undefined}
                min={0}
                type="number"
                step="0.01"
              />
            </Flex>

            <Icon
              fontSize="26px"
              cursor={disableWhenJP ? "not-allowed" : "pointer"}
              onClick={() => {
                if (!disableWhenJP) {
                  removeReward(indexSub);
                }
              }}
              mt="8px"
              color={textColor}
              key={"add"}
              as={MdClose}
            />
          </>
        )}
      </Flex>
    );
  };

  const ListItemReward = ({ indexItem }) => {
    const {
      fields: fieldRewards,
      append: appendRewards,
      remove: removeReward,
    } = useSubItemFields(indexItem);

    return (
      <Flex gap={4} direction="column">
        <Flex gap={4} align="center">
          <Text fontWeight="400" fontSize="16px" color="#000000">
            {transLabel("rewards")}
          </Text>
          <Icon
            fontSize="26px"
            cursor={disableWhenJP ? "not-allowed" : "pointer"}
            onClick={() => {
              if (!disableWhenJP) {
                appendRewards(defaultDataReward);
              }
            }}
            color={textColor}
            key={"add"}
            as={MdOutlineAdd}
          />
        </Flex>
        {fieldRewards?.map((item, index) => {
          return (
            <ItemReward
              key={item?.id}
              indexSub={index}
              removeReward={removeReward}
            />
          );
        })}
      </Flex>
    );
  };

  const { getValues, setValue, trigger } = useFormContext();
  const listFinalPrize = getValues("finalPrizes");
  const finalPrizes = getValues("finalPrizes")?.[indexItem];

  const messageCheckTime = useCallback(
    (value) => {
      if (
        indexItem === 0 ||
        !finalPrizes?.ranking_from ||
        !finalPrizes?.ranking_to
      ) {
        setValue("ranking_from_to", "ranking");
        trigger("ranking_from_to");
        return null;
      } else {
        if (listFinalPrize?.length > 1) {
          for (let i = 0; i < indexItem; i++) {
            const previousTo = listFinalPrize[i]?.ranking_to;
            const currentFrom = value || finalPrizes.ranking_from;
            if (previousTo >= currentFrom) {
              setValue("ranking_from_to", null);
              trigger("ranking_from_to");
              return "Ranking from must be after previous Ranking to";
            }
          }
        }
      }
      setValue("ranking_from_to", "ranking");
      trigger("ranking_from_to");
      return null;
    },
    [finalPrizes, indexItem, listFinalPrize]
  );

  return (
    <Box
      position="relative"
      border="1px solid #EEEFF2"
      borderRadius="3px"
      p="22px 36px 36px 36px"
    >
      <Icon
        fontSize="26px"
        cursor={disableWhenJP ? "not-allowed" : "pointer"}
        position="absolute"
        top="2px"
        right="2px"
        onClick={() => {
          if (!disableWhenJP) {
            removeFinalPrize(indexItem);
          }
        }}
        color={textColor}
        key={"add"}
        as={MdClose}
      />
      <Flex align="flex-start" direction="column" gap={4}>
        <Flex direction="column" gap={4}>
          <Text fontWeight="400" fontSize="16px" color="#000000">
            {transLabel("ranking")}
          </Text>
          <Flex align="flex-start" gap={4} ml={8}>
            <Text fontWeight="400" fontSize="16px" mt="12px" color="#000000">
              {transLabel("from")}
            </Text>
            <FormInput
              type="number"
              isDisabled={disableWhenJP}
              min={1}
              onWheel={() => undefined}
              name={`finalPrizes.${indexItem}.ranking_from`}
              onChange={(e) => {
                setValue(
                  `finalPrizes.${indexItem}.ranking_from`,
                  e.target.value
                );
                messageCheckTime(e.target.value);
              }}
              w="100px"
            />
            <Text fontWeight="400" fontSize="16px" mt="12px" color="#000000">
              {transLabel("to")}
            </Text>
            <FormInput
              type="number"
              isDisabled={disableWhenJP}
              min={1}
              onWheel={() => undefined}
              name={`finalPrizes.${indexItem}.ranking_to`}
              w="100px"
            />
          </Flex>
        </Flex>
        {messageCheckTime() && (
          <span
            style={{ color: "#E53E3E", fontWeight: "400", fontSize: "14px" }}
          >
            {messageCheckTime()}
          </span>
        )}
        <ListItemReward indexItem={indexItem} />
      </Flex>
    </Box>
  );
}
