/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { REVENUE_SHARING_NAMESPACE } from 'stores/constant'
import {
  addRevenueSharingApi,
  deleteRevenueSharingByIdApi,
  editRevenueSharingByIdApi,
  getRevenueSharingApi,
  getRevenueSharingByIdApi,
} from './action'

const name = REVENUE_SHARING_NAMESPACE
const initialState = createInitialState()
const reducers = createReducers()
const extraReducers = createExtraReducers()
const revenueSharingSlice = createSlice({ name, initialState, reducers, extraReducers })

export const revenueSharingSliceReducer = revenueSharingSlice.reducer

function createInitialState() {
  return {
    loadingRevenueSharing: false,
    errorRevenueSharing: '',
    listRevenueSharings: [],
    revenueSharingById: null,
    statusDelete: false,
  }
}

function createReducers() {
  return {}
}

function createExtraReducers() {
  return (builder) => {
    listRevenueSharings()
    addRevenueSharing()
    getRevenueSharingById()
    editRevenueSharing()
    deleteRevenueSharingById()

    function listRevenueSharings() {
      builder
        .addCase(getRevenueSharingApi.pending, (state) => {
          state.loadingRevenueSharing = true
        })
        .addCase(getRevenueSharingApi.fulfilled, (state, action) => {
          state.loadingRevenueSharing = false
          state.errorRevenueSharing = ''
          if (action.payload) {
            state.listRevenueSharings = action.payload
          }
        })
        .addCase(getRevenueSharingApi.rejected, (state, action) => {
          state.loadingRevenueSharing = false
          state.errorRevenueSharing = action.payload
        })
    }

    function addRevenueSharing() {
      builder
        .addCase(addRevenueSharingApi.pending, (state) => {
          state.loadingRevenueSharing = true
        })
        .addCase(addRevenueSharingApi.fulfilled, (state, action) => {
          state.loadingRevenueSharing = false
          state.errorRevenueSharing = ''
        })
        .addCase(addRevenueSharingApi.rejected, (state, action) => {
          state.loadingRevenueSharing = false
          state.errorRevenueSharing = action.payload
        })
    }

    function getRevenueSharingById() {
      builder
        .addCase(getRevenueSharingByIdApi.pending, (state) => {
          state.loadingRevenueSharing = true
          state.RevenueSharing = null
        })
        .addCase(getRevenueSharingByIdApi.fulfilled, (state, action) => {
          state.loadingRevenueSharing = false
          state.errorRevenueSharing = ''
          if (action.payload) {
            state.RevenueSharingById = action.payload?.data
          }
        })
        .addCase(getRevenueSharingByIdApi.rejected, (state, action) => {
          state.loadingRevenueSharing = false
          state.errorRevenueSharing = action.payload
        })
    }

    function editRevenueSharing() {
      builder
        .addCase(editRevenueSharingByIdApi.pending, (state) => {
          state.loadingRevenueSharing = true
        })
        .addCase(editRevenueSharingByIdApi.fulfilled, (state, action) => {
          state.loadingRevenueSharing = false
          state.errorRevenueSharing = ''
        })
        .addCase(editRevenueSharingByIdApi.rejected, (state, action) => {
          state.loadingRevenueSharing = false
          state.errorRevenueSharing = action.payload
        })
    }

    function deleteRevenueSharingById() {
      builder
        .addCase(deleteRevenueSharingByIdApi.pending, (state, action) => {
          state.statusDelete = true
        })
        .addCase(deleteRevenueSharingByIdApi.fulfilled, (state, action) => {
          state.statusDelete = false
          state.errorRevenueSharing = ''
        })
        .addCase(deleteRevenueSharingByIdApi.rejected, (state, action) => {
          state.statusDelete = false
          state.errorRevenueSharing = action.payload
        })
    }
  }
}
