import React from 'react'
import { Box, useStyleConfig } from '@chakra-ui/react'

const MainPanel = React.forwardRef((props, ref) => {
  const { variant, children, ...rest } = props
  const styles = useStyleConfig('MainPanel', { variant })
  return (
    <Box __css={styles} {...rest} ref={ref}>
      {children}
    </Box>
  )
})

export default MainPanel
