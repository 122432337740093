import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getGamePlayType = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.GAMEPLAY_TYPE}?${params}`);
};

export const deleteGamePlayType = (id, option = {}) => {
  return http.delete(`${END_POINT.GAMEPLAY_TYPE}/${id}`, option);
};

export const createGamePlayType = (data, option = {}) => {
  return http.post(END_POINT.GAMEPLAY_TYPE, data, option);
};

export const updateGamePlayType = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.GAMEPLAY_TYPE}/${idEdit}`, data, option);
};
