import { Flex, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import { getEventManagements } from "api/event.api";
import { getPlayersApi } from "api/userManagement";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { useCallback, useEffect, useState } from "react";
import ActionPlayerManagement from "./action";
import ConditionPlayerManagement from "./condition";
import PlayerManagementRow from "./row";

export default function PlayerManagement() {
  const labels = [
    "User Name",
    "Booth Mac Address",
    "Result",
    "Booth Type",
    "Start Play Time",
    "Success time",
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [players, setPlayers] = useState([]);
  const [events, setEvents] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();

  const getAllEvent = async () => {
    try {
      const { data } = await getEventManagements({});
      const list = data?.data?.records;
      if (list && list?.length > 0) {
        const options = list?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));
        setEvents(options);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const getPlayers = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getPlayersApi({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setPlayers(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  useEffect(() => {
    getAllEvent();
  }, []);

  useEffect(() => {
    getPlayers();
  }, [getPlayers]);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex
            w="100%"
            gap={4}
            direction={{
              base: "column",
              md: "row",
            }}
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignItems="flex-start"
          >
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Player Management
            </Text>
            <ActionPlayerManagement params={params} />
          </Flex>
        </CardHeader>
        <CardHeader px="42px" mb="32px">
          <ConditionPlayerManagement
            isLoading={isLoading}
            events={events}
            setParams={setParams}
            params={params}
          />
        </CardHeader>
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck={false}
            dataRow={players}
          >
            <>
              {players?.map((item, index) => {
                return <PlayerManagementRow key={index} item={item} />;
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
    </Flex>
  );
}
