import { containSpecialRegex } from "utils/regex";
import * as yup from "yup";

const schemaMachine = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .matches(containSpecialRegex, "Name should not contain special characters")
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
  });

export default schemaMachine;
