import { Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { TYPE_PRIZE } from "constants/constants";
import { useMemo } from "react";

export default function SelectPrizeRow({
  item,
  fontSize = "sm",
  setCheckedIds,
  checkedIds,
}) {
  const textColor = useColorModeValue("#000000", "white");

  const isChecked = useMemo(() => {
    return checkedIds.some((itemCheck) => itemCheck?.id === item?.id);
  }, [item?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isChecked) {
      setCheckedIds(
        checkedIds.filter((itemCheck) => itemCheck?.id !== item?.id)
      );
    } else {
      setCheckedIds([...checkedIds, item]);
    }
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isChecked}
          onChange={() => handleCheckboxChange()}
        />
      </Td>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
          {item?.name}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400} whiteSpace="nowrap">
          {item?.prize_type && TYPE_PRIZE[item?.prize_type]}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize={fontSize}
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {item?.play_fee?.value &&
            item?.play_fee?.system_coin &&
            `${item?.play_fee?.value} ${item?.play_fee?.system_coin}`}
        </Text>
      </Td>
      {/* <Td>
        <Text color={textColor} fontSize={fontSize} fontWeight={400}>
        </Text>
      </Td> */}
    </Tr>
  );
}
