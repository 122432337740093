import { Flex } from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import { FormProvider, useForm } from "react-hook-form";
import { MACHINE_TYPE_OPTIONS, STATE_OPTIONS } from "./constants";

export default function ConditionMachine({
  isLoading,
  setParams,
  params,
  listGamePlayType,
}) {
  const form = useForm();

  const { setValue, handleSubmit } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const newParams = {
        ...params,
        ...data,
        page: 1,
      };
      setParams(newParams);
    }
  };

  return (
    <Flex
      gap={4}
      flexWrap="wrap"
      justifyContent="flex-start"
      w="100%"
      alignItems="flex-end"
    >
      <FormProvider {...form}>
        <form
          style={{
            maxWidth: "100%",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex w="100%" flexWrap="wrap" alignItems="flex-end" gap={4}>
            <FormInput
              w="349px"
              maxW="100%"
              name="keyword"
              label="Search"
              placeholder="Enter keywords"
            />
            <FormSelect
              name="machine_type"
              label="Filter by Booth Type"
              defaultValue={""}
              w="156px"
              options={[{ label: "All", value: "" }, ...MACHINE_TYPE_OPTIONS]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("machine_type", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
            <FormSelect
              w="240px"
              name="gameplay_type_id"
              label="Filter by Gameplay Type"
              defaultValue={""}
              options={[{ label: "All", value: "" }, ...listGamePlayType]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("gameplay_type_id", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
            <FormSelect
              w="240px"
              name="status"
              label="Filter by State"
              defaultValue={""}
              options={[{ label: "All", value: "" }, ...STATE_OPTIONS]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("status", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
}
