import { Flex, useToast } from "@chakra-ui/react";
import ButtonType from "components/Button";
import { downloadCSV } from "utils/downloadCSV";
import { convertParams, omitParams } from "utils/object";

export default function ActionPlayerManagement({ params }) {
  const toast = useToast();

  const downloadPlayer = async () => {
    try {
      const param = convertParams(omitParams(params));
      const baseUrl = `users/player-list-csv?${param}`;
      await downloadCSV(baseUrl);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex gap={4} flexWrap="wrap" alignItems="flex-end">
      <Flex alignItems="flex-end" gap={6} mb="10px">
        <ButtonType
          text="Download CSV"
          fontSize="12px"
          sizeIcon="8px"
          btnType="dark"
          onClick={downloadPlayer}
        />
      </Flex>
    </Flex>
  );
}
