import END_POINT from "./constants";
import http from "./http";

export function getPrize(params) {
  return http.get(`${END_POINT.GET_PRIZE}?${params}`);
}

export function postPrize(data) {
  return http.post(END_POINT.POST_PRIZE, data);
}

export function getPrizeById(id) {
  return http.get(`${END_POINT.GET_PRIZE_BY_ID}/${id}`);
}

export function putPrize(data) {
  return http.put(`${END_POINT.PUT_PRIZE_BY_ID}/${data?.id}`, data);
}

export function deletePrize(id) {
  return http.delete(`${END_POINT.DELETE_PRIZE_BY_ID}/${id}`);
}

export function deletePrizeAll(arrId) {
  return http.delete(`${(END_POINT.DELETE_PRIZE_BY_ID, arrId)}`);
}

export function typePrize() {
  return http.get(`${END_POINT.TYPES_PRIZE}`);
}

export function postUploadImage(data) {
  return http.post(`${END_POINT.POST_UPLOAD_IMAGE_PRIZE}`, data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
}
