import { AttachmentIcon } from "@chakra-ui/icons";
import { Flex, Icon, Text } from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";

export default function ItemCSV({ name, isError, indexItem, removeCSV }) {
  return (
    <Flex
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      w="400px"
    >
      <Flex
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        gap={4}
      >
        <Icon
          as={AttachmentIcon}
          color={isError && "#E50000"}
          fontSize="18px"
        />
        <Text
          fontSize="14px"
          fontWeight="400"
          color={isError ? "#E50000" : "#1480FF"}
        >
          {name}
        </Text>
      </Flex>

      <Icon
        as={MdDelete}
        color={isError && "#E50000"}
        fontSize="18px"
        cursor="pointer"
        onClick={() => {
          removeCSV(indexItem);
        }}
      />
    </Flex>
  );
}
