export const ManagementGenesis = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M7.137 5.45v1.995c0 .012.004.02.011.028a.035.035 0 0 0 .028.011c.187 0 .375-.004.558-.004.317 0 .637.004.961.008a.055.055 0 0 0 .024-.008 2.307 2.307 0 0 0 .86-1.793v-.234c0-.02.015-.035.034-.035h.48c.02 0 .036.016.036.035v.234a2.86 2.86 0 0 1-.715 1.887c-.008.008-.012.02-.008.031.004.012.008.02.02.028.265.129.765.398.844.508l1.703 2.359c.375.52-.118.965-.395.793a.307.307 0 0 1-.09-.086L9.926 9.238c-.164-.203-.492-.086-.492.172v1.82c0 .02-.016.036-.036.036H6.082a.035.035 0 0 1-.035-.036V9.406a.278.278 0 0 0-.504-.16L3.993 11.2c-.18.227-.434.106-.552-.078-.12-.195-.082-.426.063-.629l1.703-2.36c.102-.14 1.05-.648 1.207-.648h.137c.02 0 .035-.015.035-.035v-.27c0-.015-.008-.027-.024-.034C4.93 6.44 4.5 4.293 5.773 3.02a2.546 2.546 0 0 1 2.848-.516.03.03 0 0 1 .02.027.063.063 0 0 1-.012.035L5.8 4.902a.277.277 0 0 0 .156.504h1.148c.016.004.032.02.032.043Zm-.313-.59h4.047c.012 0 .02-.005.027-.012a.035.035 0 0 0 .012-.028 3.55 3.55 0 0 0-1.062-2.5c-.012-.011-.036-.015-.051-.004L6.8 4.79c-.012.012-.016.027-.012.04.008.019.02.03.035.03Zm.68 8.097a.277.277 0 0 1 .465-.004c.578.871 1.054 3.11 1.46 4.07.008.016.02.024.036.024h.676c.011 0 .02-.004.027-.012.008-.008.012-.02.008-.031l-.692-5.156c-.004-.02-.02-.032-.035-.032H6.031c-.02 0-.035.012-.035.032l-.89 5.136a.03.03 0 0 0 .007.032.036.036 0 0 0 .028.011h.586c.015 0 .027-.008.035-.023.633-1.66.648-2.395 1.742-4.047ZM9.484 17.219l-.011.738c0 .012.004.02.011.027a.035.035 0 0 0 .028.012h2.355a.118.118 0 0 0 .07-.215l-.609-.425a.898.898 0 0 0-.543-.172H9.523c-.02 0-.035.015-.039.035ZM5.66 17.184H4.398a.874.874 0 0 0-.543.171l-.609.426a.118.118 0 0 0 .07.215h2.356c.012 0 .02-.004.027-.012a.035.035 0 0 0 .012-.027l-.012-.738c-.004-.02-.02-.035-.039-.035Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      d="m12.988 15.539 2.278 2.086a.157.157 0 0 0 .214-.004l.309-.293a.156.156 0 0 0 .004-.223l-1.734-1.816a.16.16 0 0 1 .004-.223l.078-.074a.16.16 0 0 1 .222.004l2.145 2.246c.058.063.16.066.223.004l.64-.61a.156.156 0 0 0 .004-.222l-2.055-2.152a.16.16 0 0 1 .004-.223l.078-.074a.16.16 0 0 1 .223.004l1.75 1.832c.059.062.16.066.223.004l.379-.364a.156.156 0 0 0 .003-.222l-2.496-2.614a.16.16 0 0 1 .004-.222l.078-.074a.16.16 0 0 1 .223.003l1.227 1.286a.157.157 0 0 0 .172.039c.183-.079.355-.188.503-.329l.07-.066a.156.156 0 0 0 .005-.223l-1.868-1.953a.12.12 0 0 0-.078-.043l-1.472-.328a.153.153 0 0 0-.141.04.159.159 0 0 1-.23-.016l-1.149-1.446a.159.159 0 0 0-.23-.015l-2.528 2.414a.158.158 0 0 0 .004.23l1.43 1.258c.07.063.07.168.004.23l-.027.028a.156.156 0 0 0-.016.21l1.523 1.911Zm.098-3.293.105-.031a.154.154 0 0 1 .196.101l.039.13c.035.109.172.148.258.066l.062-.063a.156.156 0 0 1 .258.066l.242.782a.154.154 0 0 1-.101.195l-.106.031a.153.153 0 0 1-.195-.101l-.04-.13a.156.156 0 0 0-.257-.066l-.063.063a.157.157 0 0 1-.258-.066l-.242-.782a.155.155 0 0 1 .102-.195Z"
    />
  </svg>
);
  