import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { getPrize, typePrize } from "api/prize.api";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { convertParams, omitParams } from "utils/object";
import SelectPrizeTable from "./table";
import { TYPE_PRIZE } from "constants/constants";
import ButtonType from "components/Button";

export default function SelectPrize({ isOpen, onClose, onAddPrize }) {
  const [isLoading, setIsLoading] = useState(false);
  const [prizeTypes, setListPrizeTypes] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [checkedIds, setCheckedIds] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [prizes, setPrizes] = useState([]);

  const toast = useToast();

  const form = useForm();
  const { handleSubmit, setValue } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const newParams = {
        ...params,
        ...data,
        page: 1,
      };
      setParams(newParams);
    }
  };

  const getHistoryPrize = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const requestParams = convertParams(
          omitParams({
            ...params,
            ...newParams,
          })
        );
        const { data } = await getPrize(requestParams);
        if (data?.success) {
          const res = data?.data;
          setPrizes(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(prizes);
    } else {
      setCheckedIds([]);
    }
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  useEffect(() => {
    getHistoryPrize();
  }, [getHistoryPrize]);

  const getTypePrize = async () => {
    try {
      const { data } = await typePrize();
      if (data?.success) {
        const list = data?.data;
        if (list && list?.length > 0) {
          let arr = [{ label: "All", value: "" }];
          list?.map((item) => {
            return (arr = [...arr, { label: TYPE_PRIZE[item], value: item }]);
          });
          setListPrizeTypes(() => arr);
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    getTypePrize();
  }, []);

  const renderForm = () => {
    return (
      <Flex
        gap={4}
        flexWrap="wrap"
        justifyContent="space-between"
        w="100%"
        alignItems="flex-end"
      >
        <FormProvider {...form}>
          <form
            style={{
              maxWidth: "100%",
              width: "100%",
            }}
            // id="select-prize-event-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Flex w="100%" flexWrap="wrap" alignItems="flex-end" gap={4}>
              <Flex gap={8}>
                <FormInput
                  w="260px"
                  name="keyword"
                  label="Search"
                  defaultValue={""}
                  placeholder="Enter keywords"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(onSubmit)();
                    }
                  }}
                />
                <FormSelect
                  w="260px"
                  name="prize_type"
                  label="Filter by Prize type"
                  defaultValue={""}
                  options={prizeTypes}
                  onChange={(e) => {
                    if (!isLoading) {
                      setValue("prize_type", e.target.value);
                      handleSubmit(onSubmit)();
                    }
                  }}
                />
              </Flex>

              <SelectPrizeTable
                data={prizes}
                isLoading={isLoading}
                totalPage={totalPage}
                limit={params.limit}
                page={params?.page}
                checkedIds={checkedIds}
                setCheckedIds={setCheckedIds}
                onChangeChecked={onChangeChecked}
                onChangeLimit={onChangeLimit}
                handleChangePage={handleChangePage}
              />
            </Flex>
          </form>
        </FormProvider>
      </Flex>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        maxW={{
          base: "100%",
          md: "95%",
          xl: "1049px",
        }}
        overflowY="auto"
      >
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color="#000000"
        >{`SELECT PRIZE`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px 32px" minW="730px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        >
          <ButtonType
            mt={4}
            colorScheme="teal"
            m="auto"
            w="159px"
            h="46px"
            borderRadius="5px"
            onClick={() => {
              onAddPrize(checkedIds);
              setParams([]);
              setCheckedIds([]);
              setPrizes([]);
              onClose();
            }}
            text="ADD"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
