import { Flex, Icon, Td, Text, Tr } from "@chakra-ui/react";
import { EditIcon } from "components/Icons/EditIcon";
import { capitalize } from "lodash";

const rowHunter = ({
  skill,
  handleOpenCreateOrUpdate,
  index,
}) => {
  return (
    <Tr>
      <Td>
        <Text fontSize="sm" fontWeight={400} whiteSpace="nowrap">
          {capitalize(skill?.title_skill_rank)}
        </Text>
      </Td>
      <Td>
        <Text fontSize="sm" fontWeight={400} whiteSpace="nowrap">
          {skill?.rank_from}
        </Text>
      </Td>
      <Td>
        <Text fontSize="sm" fontWeight={400} whiteSpace="nowrap">
          {skill?.rank_to}
        </Text>
      </Td>
      <Td>
        <Text fontSize="sm" fontWeight={400} whiteSpace="nowrap">
          {skill?.rank_to}
        </Text>
      </Td>
      <Td>
        <Flex gap="10px">
          <Icon
            onClick={handleOpenCreateOrUpdate}
            fontSize="30px"
            borderRadius="8px"
            p="7px"
            cursor="pointer"
            bg="#1480FF"
            as={EditIcon}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default rowHunter;
