/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { Flex, Icon, useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DeleteModal from "components/Modal/deleteModal";
import { FIRST_PAGE } from "constants/constants";
import { deletePlayFeeByIdApi } from "stores/playFee/action";
import { playFeeSelector } from "stores/playFee/selector";
import ButtonType from "components/Button";

export default function ConditionPlayFee({
  handleOpenCreateOrUpdate,
  // checkedIds,
  // setCheckedIds,
  // setParams,
  // params,
}) {
  const { t } = useTranslation("playFee");
  // const dispatch = useDispatch()
  // const { isOpen, onOpen, onClose } = useDisclosure()
  // const { statusDelete } = useSelector(playFeeSelector)

  // useEffect(() => {
  //   if (statusDelete) {
  //     const newParams = {
  //       ...params,
  //       page: FIRST_PAGE,
  //     }
  //     setParams(newParams)
  //     setCheckedIds([])
  //     onClose()
  //   }
  // }, [statusDelete])

  // const handleDelete = async () => {
  //   try {
  //     if (checkedIds?.length) {
  //       await dispatch(deletePlayFeeByIdApi(checkedIds))
  //     }
  //   } catch (error) {
  //     console.log('ccc error', error)
  //   }
  // }

  return (
    <Flex gap={4} flexWrap="wrap" alignItems="flex-end">
      <FormProvider>
        <form
          style={{
            maxWidth: "100%",
          }}
        ></form>
      </FormProvider>
      <Flex alignItems="flex-end" gap={6} mb="10px">
        <ButtonType
          text="NEW"
          fontSize="12px"
          iconType="plus"
          sizeIcon="8px"
          onClick={() => {
            handleOpenCreateOrUpdate();
          }}
        />
        {/* <Icon
          fontSize='26px'
          padding='2px'
          border='1px solid #EEEFF2'
          cursor='pointer'
          onClick={() => {
            if (checkedIds?.length > 0) {
              onOpen()
            }
          }}
          as={DeleteIcon}
        /> */}
      </Flex>
      {/* <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        handleDelete={handleDelete}
        title={t('delete_play_fee_title')}
        content={t('content_delete')}
        btnYes='OK'
        btnNo='Cancel'
      /> */}
    </Flex>
  );
}
