import {
  Box,
  Center,
  Flex,
  FormLabel,
  Grid,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getDetailEventManagement } from "api/event.api";
import ModalBase from "components/Modal/ModalBase";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import EventFinalPrize from "./EventFinalPrize/eventFinalPrize";
import EventFinalPrizeTable from "./EventFinalPrize/table";
import EventPrize from "./EventPrize/eventPrize";
import PrizeHistoryTable from "./EventPrize/table";
import { convertDateLocalLLLL } from "utils/time";

export default function EventDetailComponent({ isOpen, onClose, dataDetail }) {
  const [detailEvent, setDetailEvent] = useState();
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const {
    isOpen: isOpenEventFinal,
    onOpen: onOpenEventFinal,
    onClose: onCloseEventFinal,
  } = useDisclosure();

  const {
    isOpen: isOpenEvent,
    onOpen: onOpenEvent,
    onClose: onCloseEvent,
  } = useDisclosure();

  const getDetailEvent = useCallback(async () => {
    try {
      if (dataDetail?.id) {
        const { data } = await getDetailEventManagement(dataDetail?.id);
        if (data?.success) {
          const event = data?.data;
          setDetailEvent(event);
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }, [dataDetail?.id]);

  useEffect(() => {
    getDetailEvent();
  }, [getDetailEvent]);

  const convertTime = (time) => {
    if (time) {
      return convertDateLocalLLLL(time * 1000)
    }
    return "";
  };

  const renderBody = () => {
    return (
      <Flex p="40px 20px 60px 20px" w="100%" direction="column" gap={4}>
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            xl: "repeat(2, 1fr)",
          }}
          columnGap={16}
          rowGap={4}
          w="100%"
        >
          <Flex direction="column" gap={4}>
            <Flex gap={4} align="flex-start">
              <FormLabel
                fontWeight="700"
                fontSize="16px"
                m={0}
                mt="-2px"
                minW="100px"
                color={textColor}
              >
                {"Event Name"}
              </FormLabel>
              <Text fontWeight="400" fontSize="16px" color={textColor}>
                {detailEvent?.name}
              </Text>
            </Flex>
            <Flex gap={4} align="flex-start">
              <FormLabel
                fontWeight="700"
                fontSize="16px"
                m={0}
                mt="-2px"
                minW="100px"
                color={textColor}
              >
                {"Description "}
              </FormLabel>
              <Text fontWeight="400" fontSize="16px" color={textColor}>
                {detailEvent?.description}
              </Text>
            </Flex>
            <Flex gap={4} align="flex-start">
              <FormLabel
                fontWeight="700"
                fontSize="16px"
                m={0}
                mt="-2px"
                minW="154px"
                whiteSpace="nowrap"
                color={textColor}
              >
                {"Pre-registration Start"}
              </FormLabel>
              <Text
                fontWeight="400"
                minW="160px"
                fontSize="16px"
                color={textColor}
              >
                {convertTime(detailEvent?.pre_register_start_time)}
              </Text>
              <FormLabel
                fontWeight="700"
                fontSize="16px"
                m={0}
                mt="-2px"
                minW="154px"
                whiteSpace="nowrap"
                color={textColor}
              >
                {"Pre-registration End"}
              </FormLabel>
              <Text
                fontWeight="400"
                minW="160px"
                fontSize="16px"
                color={textColor}
              >
                {convertTime(detailEvent?.pre_register_end_time)}
              </Text>
            </Flex>
            <Flex gap={4} align="flex-start">
              <FormLabel
                fontWeight="700"
                fontSize="16px"
                m={0}
                mt="-2px"
                minW="154px"
                whiteSpace="nowrap"
                color={textColor}
              >
                {"Registration Start"}
              </FormLabel>
              <Text
                fontWeight="400"
                minW="160px"
                fontSize="16px"
                color={textColor}
              >
                {convertTime(detailEvent?.register_start_time)}
              </Text>
              <FormLabel
                fontWeight="700"
                fontSize="16px"
                m={0}
                minW="154px"
                mt="-2px"
                whiteSpace="nowrap"
                color={textColor}
              >
                {"Registration End"}
              </FormLabel>
              <Text
                fontWeight="400"
                minW="160px"
                fontSize="16px"
                color={textColor}
              >
                {convertTime(detailEvent?.register_end_time)}
              </Text>
            </Flex>
            <Flex gap={4} align="flex-start">
              <FormLabel
                fontWeight="700"
                fontSize="16px"
                m={0}
                mt="-2px"
                minW="154px"
                whiteSpace="nowrap"
                color={textColor}
              >
                {"Whitelist Start"}
              </FormLabel>
              <Text
                fontWeight="400"
                minW="160px"
                fontSize="16px"
                color={textColor}
              >
                {convertTime(detailEvent?.whitelist_start_time)}
              </Text>
              <FormLabel
                fontWeight="700"
                fontSize="16px"
                m={0}
                minW="154px"
                mt="-2px"
                whiteSpace="nowrap"
                color={textColor}
              >
                {"Whitelist End"}
              </FormLabel>
              <Text
                fontWeight="400"
                minW="160px"
                fontSize="16px"
                color={textColor}
              >
                {convertTime(detailEvent?.whitelist_end_time)}
              </Text>
            </Flex>
            <Flex gap={4} align="flex-start">
              <FormLabel
                fontWeight="700"
                fontSize="16px"
                m={0}
                minW="154px"
                mt="-2px"
                whiteSpace="nowrap"
                color={textColor}
              >
                {"Event Start"}
              </FormLabel>
              <Text
                fontWeight="400"
                minW="160px"
                fontSize="16px"
                color={textColor}
              >
                {convertTime(detailEvent?.event_start_time)}
              </Text>
              <FormLabel
                fontWeight="700"
                fontSize="16px"
                m={0}
                minW="154px"
                mt="-2px"
                whiteSpace="nowrap"
                color={textColor}
              >
                {"Event End"}
              </FormLabel>
              <Text
                fontWeight="400"
                minW="160px"
                fontSize="16px"
                color={textColor}
              >
                {convertTime(detailEvent?.event_end_time)}
              </Text>
            </Flex>
            <Flex gap={4} align="flex-start">
              <FormLabel
                fontWeight="700"
                fontSize="16px"
                m={0}
                minW="154px"
                mt="-2px"
                whiteSpace="nowrap"
                color={textColor}
              >
                {"Result Start"}
              </FormLabel>
              <Text
                fontWeight="400"
                minW="160px"
                fontSize="16px"
                color={textColor}
              >
                {convertTime(detailEvent?.result_start_time)}
              </Text>
              <FormLabel
                fontWeight="700"
                fontSize="16px"
                m={0}
                minW="154px"
                mt="-2px"
                whiteSpace="nowrap"
                color={textColor}
              >
                {"Result End"}
              </FormLabel>
              <Text
                fontWeight="400"
                minW="160px"
                fontSize="16px"
                color={textColor}
              >
                {convertTime(detailEvent?.result_end_time)}
              </Text>
            </Flex>
          </Flex>
          <Flex direction="column" gap={4}>
            <Flex gap={4} align="flex-start">
              <FormLabel
                fontWeight="700"
                fontSize="16px"
                m={0}
                mt="-2px"
                w="80px"
                color={textColor}
              >
                {"Rule"}
              </FormLabel>
              <Text fontWeight="400" fontSize="16px" color={textColor}>
                {detailEvent?.rule?.name}
              </Text>
            </Flex>
            <Flex gap={4} align="flex-start" direction="column">
              <FormLabel
                fontWeight="700"
                fontSize="16px"
                m={0}
                mt="-2px"
                color={textColor}
              >
                {"Event Prize"}
              </FormLabel>
              {detailEvent?.event_prizes &&
              detailEvent?.event_prizes?.length > 0 ? (
                <Box w="100%" m="auto">
                  <PrizeHistoryTable
                    isShowPagination={false}
                    isShowBtnShowFull={
                      detailEvent?.event_prizes &&
                      detailEvent?.event_prizes?.length > 5
                    }
                    handleShowMore={() => {
                      onOpenEvent();
                    }}
                    minH="auto"
                    maxW="100%"
                    fontSize="14px"
                    data={detailEvent?.event_prizes?.slice(0, 5)}
                    styleCard={{
                      p: 0,
                      boxShadow: "none",
                    }}
                  />
                </Box>
              ) : (
                <Center
                  fontWeight="400"
                  fontSize="16px"
                  w="100%"
                  color="#000000"
                >
                  No data to display
                </Center>
              )}
            </Flex>
          </Flex>
        </Grid>
        <Flex
          gap={4}
          direction="column"
          w={{ base: "100%", xl: "72%" }}
          m={{
            xl: "auto"
          }}
          mt="28px !important"
        >
          <FormLabel
            fontWeight="700"
            fontSize="16px"
            m={0}
            whiteSpace="nowrap"
            color={textColor}
          >
            {"Final Prize"}
          </FormLabel>
          {detailEvent?.event_rewards &&
          detailEvent?.event_rewards?.length > 0 ? (
            <Box w="100%" m="auto">
              <EventFinalPrizeTable
                isShowPagination={false}
                isShowBtnShowFull={
                  detailEvent?.event_rewards &&
                  detailEvent?.event_rewards?.length > 5
                }
                minH="auto"
                maxW="100%"
                fontSize="14px"
                data={detailEvent?.event_rewards?.slice(0, 5)}
                handleShowMore={() => {
                  onOpenEventFinal();
                }}
                styleCard={{
                  p: 0,
                  boxShadow: "none",
                }}
              />
            </Box>
          ) : (
            <Center fontWeight="400" fontSize="16px" color="#000000">
              No data to display
            </Center>
          )}
        </Flex>
      </Flex>
    );
  };
  return (
    <ModalBase
      maxWContent={{
        base: "100%",
        md: "95%",
        xl: "1520px",
      }}
      isOpen={isOpen}
      onClose={onClose}
      showBtn={false}
      titleHeader={dataDetail?.name}
    >
      {renderBody()}
      {isOpenEventFinal && (
        <EventFinalPrize
          data={detailEvent?.event_rewards}
          isOpen={isOpenEventFinal}
          onClose={onCloseEventFinal}
        />
      )}
      {isOpenEvent && (
        <EventPrize
          data={detailEvent?.event_prizes}
          isOpen={isOpenEvent}
          onClose={onCloseEvent}
        />
      )}
    </ModalBase>
  );
}
