/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from "@chakra-ui/react";
import { FormProvider } from "react-hook-form";

import FormLabelComponent from "components/form/FormLabel";
import { useSelector } from "react-redux";
import { prizeSelector } from "stores/prize/selector";
import { PRIZE_TYPE_OPTIONS } from "constants/constants";
import { getDateLocalLLLL } from "utils/time";
import ImageDefault from "assets/img/empty-image.png";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

const arrPrizeType = [...PRIZE_TYPE_OPTIONS];

export default function PrizeDetail({ isOpen, onClose }) {
  const textColor = useColorModeValue("#000000", "white");
  const { prizeById, loadingPrize } = useSelector(prizeSelector);

  const findPrizeType = (id) => {
    return arrPrizeType?.find((item) => item?.value === id);
  };

  const renderForm = () => {
    return (
      <FormProvider>
        <Grid
          templateColumns={{
            base: "repeat(2, 1fr)",
            xl: "repeat(4, 1fr)",
            "2xl": "repeat(6, 1fr)",
          }}
          gap={3}
        >
          <GridItem colSpan={1}>
            <Box w="150px" h="150px" backgroundSize="cover">
              <Image src={prizeById?.image || ImageDefault} w="100%" h="100%" />
            </Box>
          </GridItem>

          <GridItem colSpan={2}>
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <GridItem colSpan={1}>
                <FormLabelComponent mr={6} title="Name" />
              </GridItem>
              <GridItem colSpan={1} color={textColor}>
                {prizeById?.name || "---"}
              </GridItem>
            </Grid>

            <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={2}>
              <GridItem colSpan={1}>
                <FormLabelComponent mr={6} title="Slug" />
              </GridItem>
              <GridItem colSpan={1} color={textColor}>
                {prizeById?.slug || "---"}
              </GridItem>
            </Grid>

            <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={2}>
              <GridItem colSpan={1}>
                <FormLabelComponent mr={6} title="Genre tags" />
              </GridItem>
              <GridItem colSpan={1} color={textColor}>
                {prizeById?.genre_tags || "---"}
              </GridItem>
            </Grid>

            <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={2}>
              <GridItem colSpan={1}>
                <FormLabelComponent mr={6} title="Inventory Count" />
              </GridItem>
              <GridItem colSpan={1} color={textColor}>
                {prizeById?.inventory_count || "---"}
              </GridItem>
            </Grid>
          </GridItem>

          <GridItem colSpan={3}>
            <Grid templateColumns="repeat(3, 1fr)" gap={4}>
              <GridItem colSpan={1}>
                <FormLabelComponent m="0px" mr={6} title="Description" />
              </GridItem>
              <GridItem colSpan={2} color={textColor}>
                {prizeById?.description || "---"}
              </GridItem>
            </Grid>
            <Grid templateColumns="repeat(3, 1fr)" gap={4} mt={3}>
              <GridItem colSpan={1}>
                <FormLabelComponent m="0px" mr={6} title="Play Fee" />
              </GridItem>
              <GridItem colSpan={2} color={textColor}>
                {prizeById?.play_fee?.value || "---"}
              </GridItem>
            </Grid>
            <Grid templateColumns="repeat(3, 1fr)" gap={4} mt={3}>
              <GridItem colSpan={1}>
                <FormLabelComponent m="0px" mr={6} title="Gameplay Type" />
              </GridItem>
              <GridItem colSpan={2} color={textColor}>
                {prizeById?.gameplay_type?.name || "---"}
              </GridItem>
            </Grid>
            <Grid templateColumns="repeat(3, 1fr)" gap={4} mt={3}>
              <GridItem colSpan={1}>
                <FormLabelComponent m="0px" mr={6} title="Cabinet Type" />
              </GridItem>
              <GridItem colSpan={2} color={textColor}>
                {prizeById?.cabinet_type?.name || "---"}
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>

        <Grid
          templateColumns={{
            base: "repeat(2, 1fr)",
            xl: "repeat(4, 1fr)",
            "2xl": "repeat(6, 1fr)",
          }}
          gap={3}
          mt={6}
        >
          <GridItem colSpan={2}>
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <GridItem colSpan={1}>
                <FormLabelComponent mr={6} title="Prize Type" />
              </GridItem>
              <GridItem colSpan={1} color={textColor}>
                {prizeById?.prize_type_id
                  ? findPrizeType(prizeById?.prize_type_id)?.label
                  : "---"}
              </GridItem>
            </Grid>
          </GridItem>

          <GridItem colSpan={2}>
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <GridItem colSpan={1}>
                <FormLabelComponent mr={6} title="Bounty Ball NFT" />
              </GridItem>
              <GridItem colSpan={1} color={textColor}>
                {prizeById?.bounty_ball_nft || "---"}
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>

        <Grid
          templateColumns={{
            base: "repeat(2, 1fr)",
            xl: "repeat(4, 1fr)",
            "2xl": "repeat(6, 1fr)",
          }}
          gap={4}
          mt={4}
        >
          <GridItem
            rowSpan={2}
            colSpan={2}
            display="flex"
            flexDirection="column"
          >
            <FormLabelComponent title="NFT Information" />
            <Box
              border="1px solid #EEEFF2"
              borderRadius="3px"
              p="12px 8px"
              flex={1}
            >
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem colSpan={1}>
                  <FormLabelComponent m="0px" mr={6} title="Chain ID" />
                </GridItem>
                <GridItem colSpan={1} color={textColor}>
                  {"N/A"}
                </GridItem>
              </Grid>

              <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={3}>
                <GridItem colSpan={1}>
                  <FormLabelComponent m="0px" mr={6} title="Token ID" />
                </GridItem>
                <GridItem colSpan={1} color={textColor}>
                  {"N/A"}
                </GridItem>
              </Grid>

              <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={3}>
                <GridItem colSpan={1}>
                  <FormLabelComponent m="0px" mr={6} title="Contract" />
                </GridItem>
                <GridItem colSpan={1} color={textColor}>
                  {"N/A"}
                </GridItem>
              </Grid>

              <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={3}>
                <GridItem colSpan={1}>
                  <FormLabelComponent m="0px" mr={6} title="Lock Status" />
                </GridItem>
                <GridItem colSpan={1} color={textColor}>
                  {"N/A"}
                </GridItem>
              </Grid>
            </Box>
          </GridItem>

          <GridItem
            rowSpan={2}
            colSpan={2}
            display="flex"
            flexDirection="column"
          >
            <FormLabelComponent title="Project Category" />
            <Box
              border="1px solid #EEEFF2"
              borderRadius="3px"
              p="12px 8px"
              flex={1}
            >
              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                <GridItem colSpan={1}>
                  <FormLabelComponent m="0px" mr={6} title="Name" />
                </GridItem>
                <GridItem colSpan={2} color={textColor}>
                  {prizeById?.project_category?.name || "---"}
                </GridItem>
              </Grid>
              <Grid templateColumns="repeat(3, 1fr)" gap={4} mt={3}>
                <GridItem colSpan={1}>
                  <FormLabelComponent m="0px" mr={6} title="Dessciption" />
                </GridItem>
                <GridItem colSpan={2} color={textColor}>
                  {prizeById?.project_category?.description || "---"}
                </GridItem>
              </Grid>
            </Box>
          </GridItem>

          <GridItem
            rowSpan={2}
            colSpan={2}
            display="flex"
            flexDirection="column"
          >
            <FormLabelComponent title="Revenue sharing" />
            <Box
              border="1px solid #EEEFF2"
              borderRadius="3px"
              p="12px 8px"
              flex={1}
            >
              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                <GridItem colSpan={1}>
                  <FormLabelComponent m="0px" mr={6} title="Payee" />
                </GridItem>
                <GridItem colSpan={2} color={textColor}>
                  {prizeById?.revenue_sharing?.payee}
                </GridItem>
              </Grid>

              <Grid templateColumns="repeat(3, 1fr)" gap={4} mt={3}>
                <GridItem colSpan={1}>
                  <FormLabelComponent m="0px" mr={6} title="Sharing (%)" />
                </GridItem>
                <GridItem colSpan={2} color={textColor}>
                  {prizeById?.revenue_sharing?.sharing_percentage}
                </GridItem>
              </Grid>
            </Box>
          </GridItem>
        </Grid>

        <Grid
          templateColumns={{
            base: "repeat(2, 1fr)",
            xl: "repeat(4, 1fr)",
            "2xl": "repeat(6, 1fr)",
          }}
          gap={4}
          mt={4}
          mb={4}
        >
          <GridItem rowSpan={2} colSpan={2}>
            <FormLabelComponent title="Compatible" />
            <Grid templateColumns="repeat(1, 1fr)" gap={4}>
              <Box border="1px solid #EEEFF2" borderRadius="3px" p="12px 8px">
                <Flex
                  align="center"
                  justifyContent="space-between"
                  // w='calc(100% - 28px)'
                >
                  <Grid templateColumns="repeat(2, 1fr)" gap={4} mr={4}>
                    <GridItem colSpan={1}>
                      <FormLabelComponent mr={6} title="AR" />
                    </GridItem>
                    <GridItem colSpan={1} color={textColor}>
                      {prizeById?.ar || "None"}
                    </GridItem>
                  </Grid>

                  <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <GridItem colSpan={1}>
                      <FormLabelComponent mr={6} title="Merchandising" />
                    </GridItem>
                    <GridItem colSpan={1} color={textColor}>
                      {prizeById?.ar || "None"}
                    </GridItem>
                  </Grid>
                </Flex>
              </Box>
              <Box p="0px 8px">
                <Grid templateColumns="repeat(4, 1fr)" gap={4}>
                  <GridItem colSpan={1}>
                    <FormLabelComponent mr={6} title="Eligibility" />
                  </GridItem>
                  <GridItem colSpan={3} color={textColor}>
                    {prizeById?.eligibility || "None"}
                  </GridItem>
                </Grid>
              </Box>

              <Box p="0px 8px">
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                  <GridItem colSpan={1}>
                    <FormLabelComponent mr={6} title="Background Image" />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Box w="150px" h="150px" backgroundSize="cover">
                      <Image
                        src={prizeById?.background_image || ImageDefault}
                        w="100%"
                        h="100%"
                        loading="lazy"
                      />
                    </Box>
                  </GridItem>
                </Grid>
              </Box>
            </Grid>
          </GridItem>

          {/* Effects */}
          <GridItem
            rowSpan={2}
            colSpan={2}
            display="flex"
            flexDirection="column"
          >
            <FormLabelComponent title="Effects" />
            <Box border="1px solid #EEEFF2" borderRadius="3px" p="12px 8px">
              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                <GridItem colSpan={1}>
                  <FormLabelComponent mr={6} title="VFX" />
                </GridItem>
                <GridItem colSpan={2} color={textColor}>
                  {"N/A"}
                </GridItem>
              </Grid>

              <Grid templateColumns="repeat(3, 1fr)" gap={4} mt={2}>
                <GridItem colSpan={1}>
                  <FormLabelComponent mr={6} title="Music" />
                </GridItem>
                <GridItem colSpan={2} color={textColor}>
                  {"N/A"}
                </GridItem>
              </Grid>
            </Box>
          </GridItem>

          {/* Sale periods */}
          <GridItem
            rowSpan={2}
            colSpan={2}
            display="flex"
            flexDirection="column"
          >
            <FormLabelComponent title="Sale periods" />
            <Box border="1px solid #EEEFF2" borderRadius="3px" p="12px 8px">
              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                <GridItem colSpan={1}>
                  <FormLabelComponent mr={6} title="Sale" />
                </GridItem>
                <GridItem colSpan={2} color={textColor}>
                  {prizeById?.start_sale_period ? "Yes" : "None"}
                </GridItem>
              </Grid>

              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                <GridItem colSpan={1}>
                  <FormLabelComponent mr={6} title="Start" />
                </GridItem>
                <GridItem colSpan={2} color={textColor}>
                  {prizeById?.start_sale_period
                    ? getDateLocalLLLL(prizeById?.start_sale_period * 1000)
                    : "---"}
                </GridItem>
              </Grid>

              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                <GridItem colSpan={1}>
                  <FormLabelComponent mr={6} title="End" />
                </GridItem>
                <GridItem colSpan={2} color={textColor}>
                  {prizeById?.start_sale_period
                    ? getDateLocalLLLL(prizeById?.start_sale_period * 1000)
                    : "---"}
                </GridItem>
              </Grid>

              {/* <Flex
                align='center'
                justifyContent='space-between'
                w='calc(100% - 28px)'
              >
                <Grid
                  templateColumns='repeat(3, 1fr)'
                  gap={4}
                >
                  <GridItem colSpan={1}>
                    <FormLabelComponent mr={6} title='Start' />
                  </GridItem>
                  <GridItem colSpan={2}>
                    { prizeById?.start_sale_period ? getDateLocalLLLL(prizeById?.start_sale_period * 1000) : '---' }
                  </GridItem>
                </Grid>

                <Grid
                  templateColumns='repeat(3, 1fr)'
                  gap={4}
                >
                  <GridItem colSpan={1}>
                    <FormLabelComponent mr={6} title='End' />
                  </GridItem>
                  <GridItem colSpan={2}>
                    { prizeById?.start_sale_period ? convertDateLocal(prizeById?.start_sale_period * 1000) : '---' }
                  </GridItem>
                </Grid>
              </Flex> */}
            </Box>
          </GridItem>
        </Grid>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        position="relative"
        minH="828px"
        minW="500px"
        maxW={{
          base: "100%",
          md: "95%",
          xl: "1519px",
        }}
        overflowY="auto"
      >
        {loadingPrize ? (
          <Box
            position="absolute"
            top={0}
            left={0}
            bottom={0}
            w="100%"
            minH="828px"
          >
            <LoadingSpinner />
          </Box>
        ) : (
          <>
            <ModalHeader
              margin="auto"
              color={textColor}
            >{`${prizeById?.id} - ${prizeById?.name}`}</ModalHeader>
            <ModalCloseButton />
            <ModalBody minW="500px">{renderForm()}</ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
