import { Flex, Icon, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import ButtonType from "components/Button";
import CheckBoxType from "components/Checkbox";
import { useMemo } from "react";
import { MdEdit } from "react-icons/md";
import { getDayLLLL } from "utils/time";
import { EditIcon } from "components/Icons/EditIcon";
import { ViewIcon } from "components/Icons/ViewIcon";

const MaintenanceRow = ({
  checkedIds,
  setCheckedIds,
  handleSetDataDetail,
  handleCancelMaintenance,
  handleOpenCreateOrUpdate,
  item,
}) => {
  const textColor = useColorModeValue("#000000", "white");

  const isChecked = useMemo(() => {
    return checkedIds.some(
      (itemCheck) => itemCheck?.id === item?.id
    );
  }, [item?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isChecked) {
      setCheckedIds(
        checkedIds.filter((checkedId) => checkedId.id !== item?.id)
      );
    } else {
      setCheckedIds([...checkedIds, item]);
    }
  };

  return (
    <Tr key={item?.id} whiteSpace="break-spaces">
      <Td width="10px">
        <CheckBoxType
          isChecked={isChecked}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td p="2" minW="200px" maxW="200px" padding={"0 28px"}>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.code || "---"}
        </Text>
      </Td>
      <Td minW="250px" maxW="250px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.title || "---"}
        </Text>
      </Td>
      <Td minW="200px" maxW="200px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.start_time
            ? getDayLLLL(item?.start_time * 1000)
            : "---"}
        </Text>
      </Td>
      <Td minW="200px" maxW="200px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.end_time
            ? getDayLLLL(item?.end_time * 1000)
            : "---"}
        </Text>
      </Td>
      <Td p="2" minW="150px" maxW="150px" padding={"0 28px"}>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.notification_flg ? "TRUE" : "FALSE"}
        </Text>
      </Td>
      <Td p="2" minW="250px" maxW="250px" padding={"0 28px"}>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.name || "---"}
        </Text>
      </Td>
      <Td p="2" minW="200px" maxW="200px" padding={"0 28px"}>
        {item?.status === "UP_COMING" ? (
          <Text 
            fontSize="sm" 
            fontWeight={400}
            padding="4px 24px"
            color="#F79325"
            background="#FFF2E4"
            borderRadius="20px"
            width="max-content"
          >
            {'Upcoming' || "---"}
          </Text>
        ) : (
          <Text 
            fontSize="sm" 
            fontWeight={400}
            padding="4px 24px"
            color="#1768EC"
            background="#EAF3FC"
            borderRadius="20px"
            width="max-content"
          >
            {'Ongoing' || "---"}
          </Text>
        )}
      </Td>
      <Td minW="200px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.update_time
            ? getDayLLLL(item?.update_time * 1000)
            : "---"}
        </Text>
      </Td>
      <Td minW="150px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.last_modified_by || "Admin"}
        </Text>
      </Td>
      <Td minW="200px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.create_time
            ? getDayLLLL(item?.create_time * 1000)
            : "---"}
        </Text>
      </Td>
      <Td minW="150px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.create_modified_by || "Admin"}
        </Text>
      </Td>
      <Td>
        <Flex gap={6} align="center">
          {/* <ButtonType text="Cancel" fontSize="13px" h="26px" btnType="dark" /> */}
          {item?.status === "UP_COMING" ? (
            <ButtonType
              w="120px"
              text="RUN"
              fontSize="16px"
              fontWeight="700"
              disabled={true}
            />
          ) : (
            <ButtonType
              w="105px"
              text="Cancel"
              fontSize="16px"
              btnType="primary-new-outline"
              fontWeight="600"
              onClick={() => handleCancelMaintenance(item.id)}
            />
          )}
          <Icon
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={ViewIcon}
            onClick={() => handleSetDataDetail(item.id)}
          />
          <Icon
            onClick={() => handleOpenCreateOrUpdate()}
            fontSize="30px"
            borderRadius="8px"
            p="7px"
            cursor="pointer"
            bg="#1480FF"
            as={EditIcon}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default MaintenanceRow;
