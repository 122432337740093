import {
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createGameMode, updateGameMode } from "api/gameMode.api";
import { getGamePlayType } from "api/gameplayType.api";
import { getPlayFees } from "api/playFee.api";
import { typePrize } from "api/prize.api";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import {
  GAME_MODE_OPTIONS,
  LIMIT_FULL,
  MACHINE_TYPE_OPTIONS,
  OPTIONS_SKILL_RANK,
  TYPE_PRIZE,
} from "constants/constants";
import useWithToast from "hooks/useWithToast";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { convertParams } from "utils/object";
import { convertStringSnake } from "utils/string";
import schemaMachine from "./schema";
import FormLabelComponent from "components/form/FormLabel";

export default function GameModeCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const [playFees, setPlayFees] = useState([]);
  const [gamePlayTypes, setGamePlayTypes] = useState([]);
  const [prizeTypes, setListPrizeTypes] = useState([]);

  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const { showToastSuccess } = useWithToast();

  const OPTIONS_LEVEL = Array.from({ length: 31 }, (_, i) => ({
    label: `LEVEL_${i}`,
    value: `LEVEL_${i}`,
  }));

  const defaultValues = {
    mode_name: "",
    mode_type: "",
    play_fee_id: "",
    machine_type: "",
    gameplay_type_id: "",
    prize_type: "",
    life_gauge_consumption: null,
  };

  const form = useForm({
    resolver: yupResolver(schemaMachine()),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = form;

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const winPoint = useMemo(() => {
    if (dataDetail) {
      return dataDetail?.experience_point_rewards?.find(
        (item) => item?.status === "WIN"
      );
    }
  }, [dataDetail]);

  const losePoint = useMemo(() => {
    if (dataDetail) {
      return dataDetail?.experience_point_rewards?.find(
        (item) => item?.status === "LOSE"
      );
    }
  }, [dataDetail]);

  const levelEntry = useMemo(() => {
    if (dataDetail) {
      return dataDetail?.entry_restriction_values?.find(
        (item) => item?.entry_restriction === "LEVEL"
      );
    }
  }, [dataDetail]);

  const skillRankEntry = useMemo(() => {
    if (dataDetail) {
      return dataDetail?.entry_restriction_values?.find(
        (item) => item?.entry_restriction === "SKILL_RANK"
      );
    }
  }, [dataDetail]);

  const getFields = async () => {
    const paramsFull = {
      page: 1,
      limit: LIMIT_FULL,
    };
    try {
      const [dataPlayFee, dataGamePlay, prizesType] = await Promise.all([
        getPlayFees(convertParams(paramsFull)),
        getGamePlayType(paramsFull),
        typePrize(),
      ]);

      if (dataPlayFee) {
        const list = dataPlayFee?.data?.data?.records;
        if (list && list?.length > 0) {
          const listPlay = list?.map((item) => ({
            label: convertStringSnake(item?.system_ticket_id),
            value: item?.id,
          }));
          setPlayFees(listPlay);
        }
      }
      if (dataGamePlay) {
        const list = dataGamePlay?.data?.data?.records;
        if (list && list?.length > 0) {
          const listType = list?.map((item) => ({
            label: item?.name,
            value: item?.id,
          }));
          setGamePlayTypes(listType);
        }
      }
      if (prizesType?.data?.data && prizesType?.data?.data?.length > 0) {
        let arr = [];
        prizesType?.data?.data?.map((item) => {
          return (arr = [...arr, { label: TYPE_PRIZE[item], value: item }]);
        });
        setListPrizeTypes(() => arr);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    getFields();
  }, []);

  useEffect(() => {
    if (dataDetail) {
      reset({
        ...dataDetail,
        win: winPoint?.value,
        lose: losePoint?.value,
        level_min: levelEntry?.min_value ?? "",
        level_max: levelEntry?.max_value ?? "",
        skill_rank_min: skillRankEntry?.min_value ?? "",
        skill_rank_max: skillRankEntry?.max_value ?? "",
      });
    } else {
      reset();
    }
  }, [dataDetail]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const dataSubmit = {
      mode_name: data?.mode_name,
      mode_type: data?.mode_type,
      play_fee_id: data?.play_fee_id,
      gameplay_type_id: data?.gameplay_type_id,
      prize_type: data?.prize_type,
      life_gauge_consumption: data?.life_gauge_consumption,
      machine_type: data?.machine_type,
    };
    try {
      if (idEdit) {
        const res = await updateGameMode(dataDetail?.id, {
          ...dataSubmit,
          experience_point_rewards: [
            {
              ...winPoint,
              status: "WIN",
              value: data.win,
            },
            {
              ...losePoint,
              status: "LOSE",
              value: data?.lose,
            },
          ],
          entry_restriction_values: [
            {
              ...levelEntry,
              min_value: data?.level_min || null,
              max_value: data?.level_max || null,
            },
            {
              ...skillRankEntry,
              min_value: data?.skill_rank_min || null,
              max_value: data?.skill_rank_max || null,
            },
          ],
        });
        if (res?.data?.success) {
          showToastSuccess({
            title: "Game mode updated successfully.",
          });
          handleCreateOrUpdateSuccess();
        }
      } else {
        const res = await createGameMode({
          ...dataSubmit,
          experience_point_rewards: [
            {
              status: "WIN",
              value: data?.win,
            },
            {
              status: "LOSE",
              value: data?.lose,
            },
          ],
          entry_restriction_values: [
            {
              entry_restriction: "LEVEL",
              min_value: data?.level_min || null,
              max_value: data?.level_max || null,
            },
            {
              entry_restriction: "SKILL_RANK",
              min_value: data?.skill_rank_min || null,
              max_value: data?.skill_rank_max || null,
            },
          ],
        });
        if (res) {
          showToastSuccess({
            title: "Game mode created successfully.",
          });
          handleCreateOrUpdateSuccess();
          onClose();
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <FormInput label="Mode name" name="mode_name" />
            <FormSelect
              label="Mode type"
              name="mode_type"
              isHiddenOption
              defaultValue=""
              options={GAME_MODE_OPTIONS}
            />
            <FormSelect
              label="Play fee"
              name="play_fee_id"
              isHiddenOption
              defaultValue=""
              options={playFees}
            />
            <FormSelect
              label="Booth type"
              name="machine_type"
              isHiddenOption
              defaultValue=""
              options={[
                { label: "All", value: "ALL" },
                ...MACHINE_TYPE_OPTIONS,
              ]}
            />
            <FormSelect
              label="Gameplay type"
              name="gameplay_type_id"
              isHiddenOption
              defaultValue=""
              options={gamePlayTypes}
            />
            <FormSelect
              label="Prize type"
              name="prize_type"
              isHiddenOption
              defaultValue=""
              options={prizeTypes}
            />
            <FormInput
              type="number"
              label="Life gauge consumption per turn"
              name="life_gauge_consumption"
            />
            <Flex direction="column">
              <FormLabelComponent title="Experience point rewards" />
              <Flex alignItems="flex-start" gap="16px" w="100%">
                <FormInput
                  wrapperProps={{
                    w: "100%",
                  }}
                  w="100%"
                  name="win"
                  type="number"
                  label="Win"
                />
                <FormInput
                  wrapperProps={{
                    w: "100%",
                  }}
                  w="100%"
                  type="number"
                  name="lose"
                  label="Lose"
                />
              </Flex>
            </Flex>
            <Flex direction="column">
              <FormLabelComponent title="Entry restriction values" />
              <Flex alignItems="flex-start" gap="16px" w="100%">
                <FormSelect
                  wrapperProps={{
                    w: "100%",
                  }}
                  w="100%"
                  name="level_min"
                  label="Level min value"
                  defaultValue=""
                  isHiddenOption
                  options={OPTIONS_LEVEL}
                />
                <FormSelect
                  wrapperProps={{
                    w: "100%",
                  }}
                  w="100%"
                  name="level_max"
                  label="Level max value"
                  defaultValue=""
                  isHiddenOption
                  options={OPTIONS_LEVEL}
                />
              </Flex>
              <Flex alignItems="flex-start" gap="16px" w="100%" mt={4}>
                <FormSelect
                  wrapperProps={{
                    w: "100%",
                  }}
                  w="100%"
                  name="skill_rank_min"
                  type="number"
                  label="Skill rank min value"
                  defaultValue=""
                  isHiddenOption
                  options={OPTIONS_SKILL_RANK}
                />
                <FormSelect
                  wrapperProps={{
                    w: "100%",
                  }}
                  w="100%"
                  type="lose"
                  name="skill_rank_max"
                  label="Skill rank max value"
                  defaultValue=""
                  isHiddenOption
                  options={OPTIONS_SKILL_RANK}
                />
              </Flex>
            </Flex>
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="685px">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Update Game Mode" : "Create Game Mode"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          display="flex"
          alignItems="center"
          gap="16px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            Add
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
