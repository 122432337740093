import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getRulesEvent } from "api/event.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { useCallback, useEffect, useState } from "react";
import ConditionRuleEventManagement from "./condition";
import RuleEventCreateAndUpdate from "./createAndUpdate/createAndUpdateRule";
import RuleEventManagementRow from "./row";
import ActionRuleEventManagement from "./action";

export default function RulesManagement() {
  const labels = [
    "Event Rule Name",
    "Win Point",
    "Turn Duration",
    "Last Modified At",
    "Last Modified By",
    "Created At",
    "Created By",
    "",
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [rulesEvent, setRulesEvent] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [dataDetail, setDataDetail] = useState(null);
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();

  const {
    isOpen: isOpenCreateAndEdit,
    onOpen: onOpenCreateAndEdit,
    onClose: onCloseCreateAndEdit,
  } = useDisclosure();

  const getEvent = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        setCheckedIds([]);
        const { data } = await getRulesEvent({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setRulesEvent(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );
  // create and update modal

  const handleOpenEditModal = (item) => {
    setDataDetail(item);
    onOpenCreateAndEdit(true);
  };

  const handleCloseEditModal = () => {
    setDataDetail(null);
    onCloseCreateAndEdit();
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    handleCloseEditModal();
    setCheckedIds([]);
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  const handleChangePage = (page) => {
    setCheckedIds([]);
    setParams({
      ...params,
      page,
    });
  };

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(rulesEvent);
    } else {
      setCheckedIds([]);
    }
  };

  const onChangeLimit = (limit) => {
    setCheckedIds([]);
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex
            w="100%"
            gap={4}
            direction={{
              base: "column",
              md: "row",
            }}
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignItems="flex-start"
          >
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Events Rule
            </Text>
            <ActionRuleEventManagement
              handleOpenCreate={() => {
                setDataDetail(null);
                onOpenCreateAndEdit();
              }}
              checkedIds={checkedIds}
              setCheckedIds={setCheckedIds}
              setParams={setParams}
              params={params}
            />
          </Flex>
        </CardHeader>
        <CardHeader px="42px" mb="32px">
          <ConditionRuleEventManagement
            isLoading={isLoading}
            setParams={setParams}
            params={params}
          />
        </CardHeader>
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck
            isChecked={
              rulesEvent?.length > 0 && checkedIds.length === rulesEvent?.length
            }
            onChangeChecked={onChangeChecked}
            dataRow={rulesEvent}
          >
            <>
              {rulesEvent?.map((item) => {
                return (
                  <RuleEventManagementRow
                    key={item?.id}
                    setCheckedIds={setCheckedIds}
                    checkedIds={checkedIds}
                    handleOpenEditModal={handleOpenEditModal}
                    item={item}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
      {isOpenCreateAndEdit && (
        <RuleEventCreateAndUpdate
          isOpen={isOpenCreateAndEdit}
          onClose={onCloseCreateAndEdit}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
          dataDetail={dataDetail}
        />
      )}
    </Flex>
  );
}
