/* eslint-disable no-undef */
import * as yup from "yup";
import i18next from "I18n";

const schemaFee = () =>
  yup.object().shape({
    payee: yup
      .string()
      .trim()
      .required(i18next.t("revenueSharing:payee_required"))
      .max(30, i18next.t("revenueSharing:payee_max")),
    payee_jp: yup
      .string()
      .trim()
      .required(i18next.t("revenueSharing:payee_required"))
      .max(30, i18next.t("revenueSharing:payee_max")),
    sharing_percentage: yup
      .number()
      .nullable(true)
      .max(100, "Sharing percentage must be at most 100")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .required(i18next.t("revenueSharing:sharing_percentage_required")),
  });

export default schemaFee;
