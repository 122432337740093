export const BCoinIcon = (props) => (
  <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy="12.5" r={12} fill="url(#paint0_linear_372_120997)" />
    <g clipPath="url(#clip0_372_120997)">
      <path
        d="M18.7199 13.9245C18.7199 14.2183 18.6987 14.5073 18.6582 14.79C18.384 16.0412 17.5284 17.0736 16.3888 17.5893L16.3885 17.5897C15.8845 17.8182 15.3248 17.9452 14.7357 17.9452C14.0043 17.9452 13.3185 17.7493 12.7278 17.407C12.0034 16.9875 11.422 16.348 11.0751 15.5793C11.5791 15.8079 12.1387 15.9348 12.7278 15.9348C13.3169 15.9348 13.8766 15.8079 14.3806 15.5793C15.1484 15.232 15.7871 14.6497 16.2061 13.9245C16.5479 13.333 16.7436 12.6464 16.7436 11.9141C16.7436 11.3243 16.6168 10.7639 16.3885 10.2593C15.8734 9.11829 14.8419 8.26123 13.5923 7.98682C14.8802 8.173 16.0363 8.76786 16.9248 9.63627C16.9539 9.66443 16.9823 9.69323 17.0108 9.72235C17.8781 10.612 18.4722 11.7695 18.6582 13.059C18.6987 13.3417 18.7199 13.6307 18.7199 13.9245Z"
        fill="url(#paint1_linear_372_120997)"
      />
      <path
        d="M16.7435 11.9143C16.7435 12.6467 16.5478 13.3333 16.2059 13.9247C15.7869 14.65 15.1482 15.2322 14.3804 15.5795C14.6088 15.0749 14.7356 14.5145 14.7356 13.9247C14.7356 13.3349 14.6088 12.7745 14.3804 12.2699C14.0336 11.5012 13.4521 10.8617 12.7277 10.4422C12.1626 10.1148 11.5106 9.92143 10.8146 9.90526L10.7199 8.23653C11.057 8.12315 11.5023 8.039 11.8631 7.98707C12.1455 7.94649 12.4341 7.92529 12.7276 7.92529C13.021 7.92529 13.3097 7.94649 13.592 7.98707C14.8417 8.26159 15.8731 9.11854 16.3882 10.2595C16.6165 10.7641 16.7435 11.3245 16.7435 11.9143Z"
        fill="url(#paint2_linear_372_120997)"
      />
      <path
        d="M18.6581 14.7903C18.2702 17.4842 16.0998 19.6007 13.3826 19.9027C13.3819 19.9027 13.381 19.903 13.3803 19.903C13.1609 19.9277 12.9379 19.9401 12.7119 19.9401C12.5187 19.9401 12.3273 19.9309 12.1389 19.9129C12.1376 19.9129 12.1367 19.9125 12.1354 19.9125C10.7318 19.7789 9.46912 19.1616 8.51484 18.2289C8.48575 18.2005 8.45699 18.172 8.42886 18.1429C7.49672 17.1865 6.87982 15.9208 6.74701 14.5142C6.72894 14.3255 6.71985 14.134 6.71985 13.9405C6.71985 13.7142 6.76733 5.06006 6.76733 5.06006H7.32381C9.25183 5.06006 10.8147 6.62493 10.8147 8.55522C10.8147 10.163 10.8307 11.7604 10.745 13.3675C10.6583 14.9944 11.2742 16.5656 12.7277 17.4072C13.3184 17.7495 14.0042 17.9454 14.7356 17.9454C15.3247 17.9454 15.8844 17.8184 16.3884 17.5899L16.3887 17.5895C17.5283 17.0738 18.384 16.0414 18.658 14.7902L18.6581 14.7903Z"
        fill="url(#paint3_linear_372_120997)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_372_120997"
        x1={0}
        y1="12.5"
        x2={24}
        y2="12.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#605C3C" />
        <stop offset={1} stopColor="#3C3B3F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_372_120997"
        x1="17.6796"
        y1="8.43007"
        x2="11.1783"
        y2="21.3483"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F2CB05" />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        id="paint2_linear_372_120997"
        x1="11.819"
        y1="8.28182"
        x2="17.8386"
        y2="15.9337"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F2CB05" />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        id="paint3_linear_372_120997"
        x1="13.7234"
        y1="16.6544"
        x2="0.957588"
        y2="3.35803"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F2CB05" />
        <stop offset={1} stopColor="#F2CB05" />
      </linearGradient>
      <clipPath id="clip0_372_120997">
        <rect
          width={12}
          height="14.88"
          fill="white"
          transform="translate(6.71985 5.06006)"
        />
      </clipPath>
    </defs>
  </svg>
);
