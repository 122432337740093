import * as yup from "yup";

const schemaWallet = () =>
  yup.object().shape({
    public_key: yup
      .string()
      .trim()
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    private_key: yup.string().trim().required("Required"),
    chain_id: yup.string().trim().required("Required"),
  });

export default schemaWallet;
