import { Flex } from "@chakra-ui/react";
import ButtonType from "components/Button";

export default function ActionLevelRequired({ handleOpenCreateOrUpdate }) {
  return (
    <Flex
      gap={4}
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="flex-end"
    >
      <Flex alignItems="flex-end" gap={6} mb="10px">
        <ButtonType
          text="Import CSV"
          btnType="primary-new-outline"
          fontSize="16px"
          w="120px"
          onClick={() => handleOpenCreateOrUpdate()}
        />
        {/* <ButtonType
          text="Create"
          fontSize="16px"
          w="120px"
          onClick={() => handleOpenCreateOrUpdate()}
        /> */}
      </Flex>
    </Flex>
  );
}
