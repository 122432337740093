import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getUsersManagement = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.GET_USER}?${params}`);
};

export const getUserById = (id) => {
  return http.get(`${END_POINT.GET_USER}/${id}`);
};

export const updateUser = (id, data) => {
  return http.put(`${END_POINT.GET_USER}/${id}`, data);
};

export const deleteUser = (id) => {
  return http.delete(`${END_POINT.GET_USER}/${id}`);
};

export const getHistoryPlayGameUser = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.ROOM_HISTORIES}?${params}`);
};

export const getPlayersApi = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.GET_USER}/player-list?${params}`);
};
