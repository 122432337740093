import { Switch } from "@chakra-ui/react";
import styled, { css } from "styled-components";

const SwitchTypeStyled = styled(Switch)`
  --switch-track-width: 2.4rem;
  .chakra-switch__track {
    &[data-checked] {
      ${({ color }) =>
        color
          ? css`
              border-color: ${color};
              background-color: ${color};
            `
          : css`
              border-color: rgba(58, 65, 111, 0.95);
              background-color: rgba(58, 65, 111, 0.95);
            `};
    }
  }
  .chakra-switch__thumb {
    &[data-checked] {
      ${({ colorThumb }) =>
        colorThumb
          ? css`
              background-color: ${colorThumb};
            `
          : css`
              background-color: white;
            `};
    }
  }
`;

export default function SwitchType({ color, colorThumb, ...rest }) {
  return <SwitchTypeStyled color={color} colorThumb={colorThumb} {...rest} />;
}
