/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Flex, Text, useColorModeValue } from '@chakra-ui/react'
import { ProfileIcon } from 'components/Icons/Icons'
import { SidebarResponsive } from 'components/Sidebar/Sidebar'
import { StorageKeys } from 'constants/enum'
import PropTypes from 'prop-types'
import React from 'react'
import routes from 'routes.js'
import { clearLocalStorage } from 'utils/storage'

export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props

  // Chakra Color Mode
  let mainText = useColorModeValue('gray.700', 'gray.200')
  let navbarIcon = useColorModeValue('gray.500', 'gray.200')
  let searchIcon = useColorModeValue('gray.700', 'gray.200')

  if (secondary) {
    navbarIcon = 'white'
    mainText = 'white'
  }

  const handleSignOut = () => {
    clearLocalStorage(StorageKeys.TOKEN)
    return (window.location.href = '/#/auth/authentication/sign-in/cover')
  }
  return (
    <Flex
      pe={{ sm: '0px', md: '16px' }}
      w={{ sm: '100%', md: 'auto' }}
      alignItems='center'>
      <Button
        ms='0px'
        px='0px'
        me={{ sm: '2px', md: '16px' }}
        color={navbarIcon}
        variant='transparent-with-icon'
        onClick={handleSignOut}
        leftIcon={
          <ProfileIcon color={navbarIcon} w='22px' h='22px' me='0px' />
        }>
        <Text display={{ sm: 'none', md: 'flex' }}>Sign Out</Text>
      </Button>

      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        // logo={logo}
        {...rest}
      />
    </Flex>
  )
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
}
