import {
  containSpecialRegex,
  containSpecialUserNameRegex,
  emailRegex,
  passwordRegex,
} from "utils/regex";
import * as yup from "yup";

const schemaAdminManagement = () =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .required("Required")
      .max(30, "Email must be at most 30 characters")
      .matches(emailRegex, "Invalid email format"),
    username: yup
      .string()
      .trim()
      .required("Required")
      .max(30, "Username must be at most 30 characters")
      .matches(
        containSpecialUserNameRegex,
        "Username must not contain special characters"
      ),
    password: yup
      .string()
      .trim()
      .required("Required")
      .max(30, "Password must be at most 30 characters")
      .matches(passwordRegex, "Password must be at least 8 characters"),
    role: yup.string().trim().required("Required"),
  });

const schemaAdminManagementEdit = () =>
  yup.object().shape({
    username: yup
      .string()
      .trim()
      .required("Required")
      .max(30, "Username must be at most 30 characters")
      .matches(
        containSpecialUserNameRegex,
        "Username must not contain special characters"
      ),

    role: yup.string().trim().required("Required"),
  });

export { schemaAdminManagementEdit, schemaAdminManagement };
