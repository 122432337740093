/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { SidebarContext } from "context/SidebarContext";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { CgMenuRight } from "react-icons/cg";
import AdminNavbarLinks from "./AdminNavbarLinks";

export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);
  const { sidebarWidth, setSidebarWidth, toggleSidebar, setToggleSidebar } =
    useContext(SidebarContext);
  const {
    variant,
    children,
    fixed,
    secondary,
    brandText,
    textRoot,
    textParent,
    onOpen,
    ...rest
  } = props;
  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue("#000000", "gray.200");
  let secondaryText = useColorModeValue("#969696", "gray.200");
  let navbarPosition = "absolute";
  let navbarFilter = "none";
  let navbarBackdrop = "blur(21px)";
  let navbarShadow = "none";
  let navbarBg = "none";
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingS = "15px";
  let paddingX = "15px";
  if (props.fixed === true)
    if (scrolled === true) {
      navbarPosition = "fixed";
      navbarShadow = useColorModeValue(
        "0px 7px 23px rgba(0, 0, 0, 0.05)",
        "none"
      );
      navbarBg = useColorModeValue(
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
      );
      navbarBorder = useColorModeValue("#FFFFFF", "rgba(255, 255, 255, 0.31)");
      navbarFilter = useColorModeValue(
        "none",
        "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
      );
    }
  if (props.secondary) {
    navbarBackdrop = "none";
    navbarPosition = "absolute";
    mainText = "white";
    secondaryText = "white";
    secondaryMargin = "22px";
    paddingS = "40px";
    paddingX = "30px";
  }
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  window.addEventListener("scroll", changeNavbar);
  return (
    <Flex
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ lg: "center" }}
      borderRadius="16px"
      display="flex"
      minH="75px"
      left={document.documentElement.dir === "rtl" ? "30px" : ""}
      right={document.documentElement.dir === "rtl" ? "" : "30px"}
      justifyContent={{ lg: "center" }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      px={{
        sm: paddingX,
        md: "30px",
      }}
      ps={{
        sm: paddingS,
        md: "20px",
      }}
      pt="8px"
      top="18px"
      w={{
        sm: "calc(100vw - 30px)",
        lg: `calc(100vw - 75px - ${sidebarWidth}px)`,
      }}
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: "column",
          md: "row",
        }}
        alignItems={{ lg: "center" }}
      >
        <Flex direction="column">
          <Flex alignItems="center">
            <Box mb={{ sm: "8px", md: "0px" }}>
              <Breadcrumb>
                <BreadcrumbItem color={secondaryText}>
                  <Text color={secondaryText} fontSize="14px" fontWeight="400">
                    {textRoot}
                  </Text>
                </BreadcrumbItem>
                {textParent && (
                  <BreadcrumbItem color={mainText}>
                    <Text color={mainText} fontSize="14px" fontWeight="400">
                      {textParent}
                    </Text>
                  </BreadcrumbItem>
                )}
                {textRoot !== brandText && (
                  <BreadcrumbItem color={mainText}>
                    <Text color={mainText} fontSize="14px" fontWeight="400">
                      {brandText}
                    </Text>
                  </BreadcrumbItem>
                )}
              </Breadcrumb>
            </Box>
            {toggleSidebar ? (
              <Icon
                as={CgMenuRight}
                h="20px"
                ms="20px"
                cursor="pointer"
                display={{ sm: "none", lg: "block" }}
                onClick={() => {
                  setSidebarWidth(sidebarWidth === 275 ? 120 : 275);
                  setToggleSidebar(!toggleSidebar);
                }}
              />
            ) : (
              <HamburgerIcon
                h="20px"
                ms="20px"
                color={props.secondary ? "white" : mainText}
                cursor="pointer"
                display={{ sm: "none", lg: "block" }}
                onClick={() => {
                  setSidebarWidth(sidebarWidth === 275 ? 120 : 275);
                  setToggleSidebar(!toggleSidebar);
                }}
              />
            )}
          </Flex>
          <Text color={mainText} fontSize="16px" fontWeight="500">
            {brandText}
          </Text>
        </Flex>
        <Box ms="auto" w={{ sm: "100%", md: "unset" }}>
          <AdminNavbarLinks
            onOpen={props.onOpen}
            logoText={props.logoText}
            secondary={props.secondary}
            fixed={props.fixed}
          />
        </Box>
      </Flex>
    </Flex>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
