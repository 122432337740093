import { Flex, useDisclosure } from "@chakra-ui/react";
import ButtonType from "components/Button";
import DeleteModal from "components/Modal/deleteModal";
import { FIRST_PAGE } from "constants/constants";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { deleteRevenueSharingByIdApi } from "stores/revenueSharing/action";

export default function ActionRevenueSharing({
  handleOpenCreateOrUpdate,
  checkedIds,
  setCheckedIds,
  setParams,
  params,
}) {
  const { t } = useTranslation("revenueSharing");
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDelete = async () => {
    if (checkedIds?.length) {
      await dispatch(deleteRevenueSharingByIdApi(checkedIds));
      const newParams = {
        ...params,
        page: FIRST_PAGE,
      };
      setParams(newParams);
      setCheckedIds([]);
      onClose();
    }
  };

  return (
    <Flex gap={4} flexWrap="wrap" alignItems="flex-end">
      <Flex alignItems="flex-end" gap={6} mb="10px">
        <ButtonType
          text="NEW"
          fontSize="12px"
          iconType="plus"
          sizeIcon="8px"
          onClick={() => {
            handleOpenCreateOrUpdate();
          }}
        />
        <ButtonType
          text="REMOVE"
          fontSize="12px"
          sizeIcon="8px"
          btnType="dark-outline"
          disabled={checkedIds?.length === 0}
          onClick={onOpen}
        />
      </Flex>
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        handleDelete={handleDelete}
        title={t("delete_revenue_sharing_title")}
        content={t("content_delete")}
        btnYes="OK"
        btnNo="Cancel"
      />
    </Flex>
  );
}
