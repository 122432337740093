import END_POINT from './constants'
import http from './http'

export function getPlayFees(params) {
  return http.get(`${END_POINT.GET_PLAY_FEES}?${params}`)
}

export function postPlayFees(data) {
  return http.post(END_POINT.POST_PLAY_FEES, data)
}

export function getPlayFeesById(id) {
  return http.get(`${END_POINT.GET_PLAY_FEES_BY_ID}/${id}`)
}

export function putPlayFee(data) {
  return http.put(`${END_POINT.PUT_PLAY_FEES_BY_ID}/${data?.id}`, data)
}

export function deletePlayFee(id) {
  return http.delete(`${END_POINT.DELETE_PLAY_FEES_BY_ID}/${id}`)
}
