import {
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createWalletApi } from "api/wallet.api";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import useWithToast from "hooks/useWithToast";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import schemaWallet from "./schema";
import { NFT_CHAIN } from "constants/constants";

export default function WalletCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const toast = useToast();
  const { showToastSuccess } = useWithToast();
  const textColor = useColorModeValue("#000000", "white");
  const [machinesOptions, setMachinesOptions] = useState([]);
  
  const defaultValues = {
    public_key: "",
    private_key: "",
    chain_id: NFT_CHAIN[0].value,
  };

  const form = useForm({
    resolver: yupResolver(schemaWallet()),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting },
  } = form;

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  // const getChainList = async () => {
  //   try {
  //     const { data } = await getListCraneMachinesUnUsed({
  //       page: 1,
  //       limit: 999999,
  //     });
  //     if (data?.success) {
  //       const list = data?.data;
  //       if (list && list?.length > 0) {
  //         const listOptions = list?.map((item) => {
  //           return {
  //             label: item?.mac,
  //             value: item?.id,
  //           };
  //         });
  //         setMachinesOptions(listOptions);
  //       }
  //     }
  //   } catch (error) {
  //     toast({
  //       description: error?.message || error?.messages[0],
  //       status: "error",
  //       position: "bottom-right",
  //     });
  //   }
  // };

  // useEffect(() => {
  //   getChainList();
  // }, []);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      const res = await createWalletApi({
        public_key: data.public_key,
        private_key: data.private_key,
        key_type: "ADMIN",
        chain_id: NFT_CHAIN?.filter((nft) => nft.value === data.chain_id)[0].chain_id
      });
      if (res?.data?.success) {
        showToastSuccess({
          title: "The new wallet has been successfully created.",
        });
        handleCreateOrUpdateSuccess();
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <FormInput name="public_key" label="Public key" placeholder="Enter Public key"/>
            <FormInput name="private_key" label="Private key" placeholder="Enter Private key"/>
            <FormSelect
              name="chain_id"
              label="Chain ID"
              defaultValue={NFT_CHAIN[0]}
              options={NFT_CHAIN}
              onChange={(e) => {
                setValue("chain_id", e.target.value);
              }}
            />
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="685px" overflowY="auto">
        <ModalHeader
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit wallet" : "Add a new wallet"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter w="100%" textAlign="center" margin="auto">
          <Flex alignItems="center" gap={4} w="100%">
            <ButtonType
              mt={4}
              type="submit"
              m="auto"
              w="100%"
              h="46px"
              borderRadius="5px"
              isLoading={isSubmitting}
              btnType="primary-new-outline"
              onClick={onClose}
            >
              Cancel
            </ButtonType>
            <ButtonType
              w="100%"
              mt={4}
              type="submit"
              m="auto"
              h="46px"
              borderRadius="5px"
              form="machine-form"
              isLoading={isSubmitting}
            >
              Create
            </ButtonType>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
