import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
  //   useToast,
} from "@chakra-ui/react";
import FormSelect from "components/form/FormSelect";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { STATUS_USER_OPTIONS } from "views/Users/UserManagement/constant";
import FreePlayHistoryTable from "./table";

export default function FreePlayHistory({ isOpen, onClose, user }) {
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm();
  const { handleSubmit, setValue } = form;

  const onSubmit = (data) => {
    console.log("ccc data", data);
  };

  const getHistoryFreePlay = async (id) => {
    try {
      // call api
      setIsLoading(true);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user?.id) {
      getHistoryFreePlay(user?.id);
    }
  }, [user?.id]);

  const renderForm = () => {
    return (
      <Flex
        gap={4}
        flexWrap="wrap"
        justifyContent="space-between"
        w="100%"
        alignItems="flex-end"
      >
        <FormProvider {...form}>
          <form
            style={{
              maxWidth: "100%",
              width: "100%",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Flex w="100%" flexWrap="wrap" alignItems="flex-end" gap={4}>
              <Flex gap={8}>
                <FormSelect
                  w="260px"
                  name="game_mode"
                  label="Filter by Game mode"
                  defaultValue={""}
                  options={[
                    { label: "All", value: "" },
                    ...STATUS_USER_OPTIONS,
                  ]}
                  onChange={(e) => {
                    if (!isLoading) {
                      setValue("game_mode", e.target.value);
                      handleSubmit(onSubmit)();
                    }
                  }}
                />
                <FormSelect
                  w="260px"
                  name="prize_type"
                  label="Filter by Prize type"
                  defaultValue={""}
                  options={[
                    { label: "All", value: "" },
                    ...STATUS_USER_OPTIONS,
                  ]}
                  onChange={(e) => {
                    if (!isLoading) {
                      setValue("prize_type", e.target.value);
                      handleSubmit(onSubmit)();
                    }
                  }}
                />
              </Flex>

              <FreePlayHistoryTable />
            </Flex>
          </form>
        </FormProvider>
      </Flex>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="1519px">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >{`FREE PLAY HISTORY OF ${user?.name}`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px 32px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        ></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
