import { Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import FormLabelComponent from "components/form/FormLabel";
import { MdClose } from "react-icons/md";

export default function ItemEventPrize({
  item,
  indexItem,
  removeEventPrize,
  transLabel,
  disableWhenJP,
}) {
  const textColor = useColorModeValue("#000000", "white");
  return (
    <Flex
      align="flex-start"
      rowGap={4}
      columnGap={8}
      w="100%"
      justifyContent="space-between"
    >
      <Flex flex={1} direction="column" gap={4}>
        <FormLabelComponent
          fontWeight="700"
          fontSize="16px"
          m="0px"
          title={transLabel("eventPrize")}
        />
        <Text fontWeight="400" fontSize="16px" color="#000000">
          {item?.name}
        </Text>
      </Flex>
      <Flex flex={1} direction="column" gap={4}>
        <FormLabelComponent
          fontWeight="700"
          fontSize="16px"
          m="0px"
          title={transLabel("prizeType")}
        />
        <Text fontWeight="400" fontSize="16px" color="#000000">
          {item?.prize_type}
        </Text>
      </Flex>
      <Flex flex={1} direction="column" gap={4} color="#000000">
        <FormLabelComponent
          fontWeight="700"
          fontSize="16px"
          m="0px"
          title={transLabel("playFee")}
        />
        <Text fontWeight="400" fontSize="16px" color="#000000">
          {item?.play_fee?.value &&
            item?.play_fee?.system_coin &&
            `${item?.play_fee?.value} ${item?.play_fee?.system_coin}`}
        </Text>
      </Flex>
      {/* <Flex flex={1} direction="column" gap={4}>
        <FormLabelComponent
          fontWeight="700"
          fontSize="16px"
          m="0px"
          title={transLabel("estimatedValue")}
        />
        <Text fontWeight="400" fontSize="16px" color="#000000">
        </Text>
      </Flex> */}
      <Flex direction="column">
        <FormLabelComponent fontWeight="700" fontSize="16px" m="0px" title="" />
        <Icon
          fontSize="26px"
          cursor={disableWhenJP ? "not-allowed" : "pointer"}
          onClick={() => {
            if (!disableWhenJP) {
              removeEventPrize(indexItem);
            }
          }}
          color={textColor}
          key={"remove"}
          as={MdClose}
        />
      </Flex>
    </Flex>
  );
}
