import {
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {  updateHunterOffChainApi } from "api/hunterNFTs.api";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import useWithToast from "hooks/useWithToast";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import schemaSkillRank from "./schema";
import { NUMBER } from "constants/enum";
import { checkMaxImage } from "utils/uploadFile";
import { postUploadImage } from "api/prize.api";
import FormUpload from "components/form/FormUpload";
import ItemCSV from "./itemCSV";

export default function HunterOffChainCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const { showToastSuccess } = useWithToast();

  const defaultValues = {
    name: "",
    recovery_value: "",
    image_url: ""
  };

  const form = useForm({
    resolver: yupResolver(schemaSkillRank()),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
    setValue,
  } = form;

  const {
    fields: fieldCSV,
    append: appendCSV,
    remove: removeCSV,
  } = useFieldArray({
    control,
    name: "listCSV",
  });

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  useEffect(() => {
    if (dataDetail) {
      reset({
        name: dataDetail?.name,
        recovery_value: dataDetail?.recovery_value,
        image_url: dataDetail?.image_url,
      });
    } else {
      reset();
    }
  }, [dataDetail]);

  const onSubmit = async (data, e) => {
    try {
      if (idEdit) {
        const res = await updateHunterOffChainApi(idEdit, {
          id: idEdit,
          image_url: data?.image_url,
          name: data?.name,
          rarity: "OFF_CHAIN",
          recovery_value: data?.recovery_value
        });
        if (res?.data?.success) {
          showToastSuccess({
            title: "Hunter Off-chain saved successfully.",
          });
          handleCreateOrUpdateSuccess();
        }
      } else {
        // const res = await createSkillRankApi(data);
        // if (res) {
        //   showToastSuccess({
        //     title: "Skill rank added successfully.",
        //   });
        //   handleCreateOrUpdateSuccess();
        //   onClose();
        // }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleImageChange = async (event) => {
    appendCSV(event[0]);
    const image = event[0];
    await uploadImageToServe(image);
  };

  const uploadImageToServe = async (image) => {
    if (image && !image.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      toast({
        description: "The image format is incorrect.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    } else {
      if (checkMaxImage(image)) {
        toast({
          description: "Image must be smaller than 5MB.",
          status: "error",
          position: "bottom-right",
        });
        return false;
      }
    }
    try {
      const request = new FormData();
      request.append("file", image);
      request.append("name", image?.name || "image");
      const { data } = await postUploadImage(request);
      if (data?.code === 200) {
        setValue("image_url", data?.data?.url)
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <FormInput type="text" label="Name" name="name" />
            <FormInput type="number" label="Life gauge bonus" name="recovery_value" />
            <Flex direction="column" gap={4} w="100%">
              <FormUpload
                name="image"
                accept={"image/*"}
                label="Image file to upload"
                handleOnChange={(e) => handleImageChange(e)}
              />
            </Flex>
            <Flex
              direction="column"
              gap={2}
              w="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              {fieldCSV?.map((item, index) => {
                return (
                  <ItemCSV
                    key={item?.id}
                    name={item?.path}
                    isError={item?.isError}
                    indexItem={index}
                    removeCSV={removeCSV}
                  />
                );
              })}
            </Flex>
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="685px">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit Hunter Off-chain" : "Add Skill Rank"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          display="flex"
          alignItems="center"
          gap="16px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            {idEdit ? "Save" : "Add "}
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
