/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { GAME_PLAY_TYPE_NAMESPACE } from 'stores/constant'
import {
  getGamePlayTypeApi,
} from './action'

const name = GAME_PLAY_TYPE_NAMESPACE
const initialState = createInitialState()
const reducers = createReducers()
const extraReducers = createExtraReducers()
const gamePlaySlice = createSlice({ name, initialState, reducers, extraReducers })

export const gamePlaySliceReducer = gamePlaySlice.reducer

function createInitialState() {
  return {
    loadingGamePlay: false,
    errorGamePlay: '',
    listGamePlay: [],
  }
}

function createReducers() {
  return {}
}

function createExtraReducers() {
  return (builder) => {
    listGamePlayType()

    function listGamePlayType() {
      builder
        .addCase(getGamePlayTypeApi.pending, (state) => {
          state.loadingGamePlay = true
        })
        .addCase(getGamePlayTypeApi.fulfilled, (state, action) => {
          state.loadingGamePlay = false
          state.errorGamePlay = ''
          if (action.payload) {
            state.listGamePlay = action.payload
          }
        })
        .addCase(getGamePlayTypeApi.rejected, (state, action) => {
          state.loadingGamePlay = false
          state.errorGamePlay = action.payload
        })
    }
  }
}
