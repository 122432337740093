import { Box, Flex, Image, Tab, TabList, Tabs, Text } from "@chakra-ui/react";
import IconRemoteClaw from "../../../../assets/img/icon-remote-claw.png";
import { useMemo } from "react";
import BigNumber from "bignumber.js";
import { FormattedNumber } from "react-intl";

export default function InformationNFT({ item, rateBCoinToBTH }) {
  const LIST = [
    {
      id: 1,
      name: "Jane Cooper",
      date: "05/19/2024, 3:20 AM",
      price: "4.89 BTH",
      total: "= $12.246",
      image: IconRemoteClaw,
    },
    {
      id: 2,
      name: "Jane Cooper",
      date: "05/19/2024, 3:20 AM",
      price: "4.89 BTH",
      total: "= $12.246",
      image: IconRemoteClaw,
    },
    {
      id: 3,
      name: "Jane Cooper",
      date: "05/19/2024, 3:20 AM",
      price: "4.89 BTH",
      total: "= $12.246",
      image: IconRemoteClaw,
    },
    {
      id: 4,
      name: "Jane Cooper",
      date: "05/19/2024, 3:20 AM",
      price: "4.89 BTH",
      total: "= $12.246",
      image: IconRemoteClaw,
    },
  ];

  const ListHistory = () => {
    return (
      <Flex
        direction="column"
        gap="12px"
        maxH="200px"
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "3px",
          },
          "&::-webkit-scrollbar-track": {
            width: "3px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "rgba(138, 138, 160, 1)",
            borderRadius: "100px",
          },
        }}
      >
        {LIST.map((item, index) => {
          return (
            <Flex direction="column" gap="2px" key={item?.id} pr="6px">
              <Flex
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                pb="10px"
              >
                <Flex
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  gap="12px"
                >
                  <Image
                    borderRadius="100%"
                    src={item?.image}
                    w="44px"
                    h="44px"
                    alt={item?.name}
                  />
                  <Flex direction="column">
                    <Text
                      fontSize="14px"
                      fontWeight="600"
                      color="rgba(31, 31, 44, 1)"
                    >
                      {item?.name}
                    </Text>
                    <Text
                      fontSize="14px"
                      fontWeight="400"
                      color="rgba(150, 150, 150, 1)"
                    >
                      {item?.date}
                    </Text>
                  </Flex>
                </Flex>
                <Flex direction="column" alignItems="flex-end">
                  <Text
                    fontSize="14px"
                    fontWeight="600"
                    color="rgba(31, 31, 44, 1)"
                  >
                    {item?.price}
                  </Text>
                  <Text
                    fontSize="14px"
                    fontWeight="700"
                    color="rgba(150, 150, 150, 1)"
                  >
                    {item?.total}
                  </Text>
                </Flex>
              </Flex>
              {index < LIST?.length - 1 && (
                <Box
                  alignSelf="flex-end"
                  w="calc(100% - 56px)"
                  h="1px"
                  borderBottom="1px solid rgba(235, 235, 235, 1)"
                />
              )}
            </Flex>
          );
        })}
      </Flex>
    );
  };

  const usd = useMemo(() => {
    if (item?.product_model?.price && rateBCoinToBTH) {
      return new BigNumber(item?.product_model?.price)
        .multipliedBy(rateBCoinToBTH)
        .toNumber();
    }
    return 0;
  }, [rateBCoinToBTH, item?.product_model?.price]);

  return (
    <Flex direction="column">
      <Text fontSize="24px" fontWeight="700">
        {item?.nft_information?.name}
      </Text>
      <Flex direction="column" gap="20px" mt="28px">
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          gap="12px"
          p="12px"
          borderRadius="16px"
          bg="rgba(242, 242, 242, 1)"
        >
          <Image
            src={item?.user_model?.avatar_url || IconRemoteClaw}
            borderRadius="100%"
            w="44px"
            h="44px"
            alt="icon"
          />
          <Flex
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Text
              fontSize="14px"
              fontWeight="400"
              color="rgba(138, 138, 160, 1)"
            >
              Owned By
            </Text>
            <Text fontSize="14px" fontWeight="700" color="rgba(31, 31, 44, 1)">
              {item?.user_model?.name}
            </Text>
          </Flex>
        </Flex>
        <Flex
          direction="column"
          alignItems="flex-start"
          p="8px 16px"
          borderRadius="8px"
          border="1px dashed rgba(255, 207, 62, 1)"
        >
          <Text fontSize="14px" fontWeight="400" color="rgba(150, 150, 150, 1)">
            Price
          </Text>
          <Text fontSize="32px" fontWeight="700" color="rgba(0, 0, 0, 1)">
            {item?.product_model?.price} BTH
          </Text>
          <Text fontSize="14px" fontWeight="400" color="rgba(72, 72, 74, 1)">
            ={" "}
            <FormattedNumber
              value={usd}
              style="currency"
              currency="USD"
              minimumFractionDigits={0}
            />
          </Text>
        </Flex>
        <Flex direction="column">
          <Tabs mb="20px" variant="unstyled">
            <TabList borderBottom="1px solid rgba(219, 219, 219, 1)">
              <Tab
                fontSize="16px"
                p="8px 0px"
                fontWeight="700"
                borderBottom="2px solid rgba(0, 0, 0, 1)"
                boxShadow="none !important"
                _selected={{
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                History Order
              </Tab>
            </TabList>
          </Tabs>
          <ListHistory />
        </Flex>
      </Flex>
    </Flex>
  );
}
