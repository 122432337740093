import { Flex, Icon, Text, useToast } from "@chakra-ui/react";
import { useRef } from "react";
import { IoMdCheckmark } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { MdBlockFlipped } from "react-icons/md";

const useWithToast = () => {
  const toast = useToast();
  const toastIdRefSuccess = useRef();
  const toastIdRefFail = useRef();

  const showToastSuccess = ({
    title = "Success",
    description = "",
    position = "top",
  }) => {
    toastIdRefSuccess.current = toast({
      title,
      description,
      status: "success",
      duration: 5000,
      isClosable: true,
      position,
      render: () => (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          color="#28C76F"
          p="12px 14px"
          bg="#DDF6E8"
          borderRadius="6px"
        >
          <Flex alignItems="center" gap="10px">
            <Icon
              as={IoMdCheckmark}
              fontSize="26px"
              border="1px solid #FFFFFF"
              p="4px"
              borderRadius="6px"
              bg="#FFFFFF"
            />
            <Text>{title}</Text>
          </Flex>

          <Icon
            as={IoCloseSharp}
            fontSize="16px"
            cursor="pointer"
            onClick={() => {
              if (toastIdRefSuccess.current) {
                toast.close(toastIdRefSuccess.current);
              }
            }}
          />
        </Flex>
      ),
    });
  };

  const showToastFail = ({
    title = "Fail",
    description = "",
    position = "top",
  }) => {
    toastIdRefFail.current = toast({
      title,
      description,
      status: "success",
      duration: 5000,
      position,
      render: () => (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          color="#E50000"
          p="12px 14px"
          bg="#FCE4E4"
          borderRadius="6px"
          gap="20px"
        >
          <Flex alignItems="center" gap="10px">
            <Icon
              as={MdBlockFlipped}
              fontSize="26px"
              border="1px solid #FFFFFF"
              p="4px"
              borderRadius="6px"
              bg="#FFFFFF"
            />
            <Text>{title}</Text>
          </Flex>

          <Icon
            as={IoCloseSharp}
            fontSize="16px"
            cursor="pointer"
            onClick={() => {
              if (toastIdRefFail.current) {
                toast.close(toastIdRefFail.current);
              }
            }}
          />
        </Flex>
      ),
    });
  };

  return { showToastSuccess, showToastFail };
};

export default useWithToast;
