export function getLocalStorage(key) {
  return localStorage.getItem(key)
}

export function setLocalStorage(key, value) {
  return localStorage.setItem(
    key,
    typeof value === 'string' ? value : JSON.stringify(value),
  )
}

export function removeLocalStorage(key) {
  return localStorage.removeItem(key)
}

export function clearLocalStorage() {
  return localStorage.clear()
}
