/* eslint-disable no-useless-escape */
/* eslint-disable no-undef */
import * as yup from 'yup'
import i18next from 'I18n'

// const blockSpecialRegex = new RegExp("~`!@#$%^&()_={}\[\]\:;,.\/<>/-+/?")
const schemaPrize = () =>
  yup.object().shape({
  //   name: yup.string()
  //   .when([slug], {
  //     is: value => value,
  //     then: yup
  //       .string()
  //       .trim()
  //       .strict(true)
  //       .required(i18next.t('prize:name_required'))
  //       .max(30, i18next.t('prize:validate_name_max')),
  //     otherwise: yup.nullable().default(undefined).notRequired();
  // }),
    name: yup
      .string()
      .trim()
      .nullable(true)
      .transform((value, original) => {
        return original == null || original === "" ? undefined : value
      })
      .required(i18next.t('prize:name_required'))
      .max(30, i18next.t('prize:validate_name_max')),
    slug: yup
      .string()
      .trim()
      .required(i18next.t('prize:slug_required'))
      .min(3, i18next.t('prize:validate_slug_min'))
      .max(250, i18next.t('prize:validate_slug_max')),
    description: yup
      .string()
      .trim()
      // .required(i18next.t('prize:description_required'))
      // .matches(/^[aA-zZ0-9\s]+$/, i18next.t('prize:validate_description_string'))
      .max(300, i18next.t('prize:validate_description_max')),
    prize_type: yup.string().trim().required(i18next.t('prize:prize_type_required')),
    sale: yup.string(),
    start_sale_period: yup.date().transform((curr, orig) => orig === '' ? null : curr)
      .when('sale', (sale, schema) => {
        if (sale.includes('yes')) {
          return schema.required(i18next.t('prize:start_required'));
        } else {
          return schema.nullable().default(undefined).notRequired();
        }
      }),
    end_sale_period: yup.date().transform((curr, orig) => orig === '' ? null : curr)
      .when('sale', (sale, schema) => {
        if (sale.includes('yes')) {
          return schema.required(i18next.t('prize:end_required'))
                  .min(yup.ref('start_sale_period'), i18next.t('prize:end_before_start'));
        } else {
          return schema.nullable().default(undefined).notRequired();
        }
      }),
  })

export default schemaPrize
