/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Chakra imports
import { Flex, Icon, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { MdEdit } from "react-icons/md";
import { getDateUTCLLLL } from "utils/time";

const RevenueSharingRow = ({
  checkedIds,
  setCheckedIds,
  handleOpenCreateOrUpdate,
  listRevenueSharing,
}) => {
  const textColor = useColorModeValue("#000000", "white");

  const handleCheckboxChange = () => {
    if (checkedIds.includes(listRevenueSharing?.id)) {
      setCheckedIds(
        checkedIds.filter((checkedId) => checkedId !== listRevenueSharing?.id)
      );
    } else {
      setCheckedIds([...checkedIds, listRevenueSharing?.id]);
    }
  };

  return (
    <Tr key={listRevenueSharing?.id} whiteSpace="pre-wrap">
      <Td width="10px">
        <CheckBoxType
          isChecked={checkedIds.includes(listRevenueSharing?.id)}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td minW="150px" maxW="150px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listRevenueSharing?.payee}
        </Text>
      </Td>
      <Td minW="200px" maxW="200px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listRevenueSharing?.sharing_percentage}
        </Text>
      </Td>
      <Td minW="200px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listRevenueSharing?.update_time
            ? getDateUTCLLLL(listRevenueSharing?.update_time * 1000)
            : "---"}
        </Text>
      </Td>
      <Td minW="150px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listRevenueSharing?.update_by || "Admin"}
        </Text>
      </Td>
      <Td minW="200px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listRevenueSharing?.create_time
            ? getDateUTCLLLL(listRevenueSharing?.create_time * 1000)
            : "---"}
        </Text>
      </Td>
      <Td minW="150px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listRevenueSharing?.create_by || "Admin"}
        </Text>
      </Td>
      <Td>
        <Flex gap={6} align="center">
          <Icon
            onClick={() => handleOpenCreateOrUpdate()}
            fontSize="16px"
            cursor="pointer"
            color={textColor}
            as={MdEdit}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default RevenueSharingRow;
