export const LockIcon = (props) => (
  <svg
    width={18}
    height={19}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 6.5H6M12 6.5C13.6569 6.5 15 7.84315 15 9.5V14C15 15.6569 13.6569 17 12 17H6C4.34315 17 3 15.6569 3 14V9.5C3 7.84315 4.34315 6.5 6 6.5M12 6.5V5C12 3.34315 10.6569 2 9 2C7.34315 2 6 3.34315 6 5V6.5M9 12.5V11"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
