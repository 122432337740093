import { Flex, Icon, Image, Td, Text, Tr } from "@chakra-ui/react";
import { DeleteIcon } from "components/Icons/DeleteIcon";
import { EditIcon } from "components/Icons/EditIcon";
import { capitalize } from "lodash";

const HunterOffChainRow = ({
  skill,
  handleOpenCreateOrUpdate,
  handleOpenConfirmDelete,
  index,
}) => {
  return (
    <Tr>
      <Td width="10px">{index}</Td>
      <Td>
        <Text fontSize="sm" fontWeight={400} whiteSpace="nowrap">
          {capitalize(skill?.name)}
        </Text>
      </Td>
      <Td>
        <Image
          src={skill?.image_url}
          alt="image-item"
          w={{
            base: "44px",
          }}
          h={{
            base: "44px",
          }}
        />
      </Td>
      <Td>
        <Text fontSize="sm" fontWeight={400} whiteSpace="nowrap">
          {skill?.recovery_value}
        </Text>
      </Td>

      <Td>
        <Text fontSize="sm" fontWeight={400} whiteSpace="nowrap">
          {skill?.rarity}
        </Text>
      </Td>

      <Td>
        <Flex gap="10px">
          <Icon
            onClick={handleOpenCreateOrUpdate}
            fontSize="30px"
            borderRadius="8px"
            p="7px"
            cursor="pointer"
            bg="#1480FF"
            as={EditIcon}
          />
          {/* <Icon
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={DeleteIcon}
            onClick={handleOpenConfirmDelete}
          /> */}
        </Flex>
      </Td>
    </Tr>
  );
};

export default HunterOffChainRow;
