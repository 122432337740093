/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { USER_NAMESPACE } from 'stores/constant'
import { StorageKeys } from 'constants/enum'
import {
  loginReducerApi,
  userinfoReducerApi,
  userChangePasswordApi,
  listUsersApi,
  addUserApi,
  getUserByIdApi,
  editUserByIdApi,
  deleteUserByIdApi,
} from './action'
import {
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from 'utils/storage'

const initialState = {
  loading: false,
  error: '',
  token: getLocalStorage(StorageKeys.TOKEN) || '{}',
  userinfo: JSON.parse(getLocalStorage(StorageKeys.USERINFO) || '{}'),
  listUsers: [],
  userById: null,
}

const userSlice = createSlice({
  name: USER_NAMESPACE,
  initialState,
  reducers: {
    updateToken(state, { payload }) {
      state.token = payload
    },
    updateUserinfo(state, { payload }) {
      state.userinfo = { ...state.userinfo, ...payload }
    },
    resetLoginInfo(state) {
      state.userinfo = {}
      state.token = ''
      clearLocalStorage()
    },
  },
  extraReducers: {
    // login
    [loginReducerApi.pending.type]: (state) => {
      state.loading = true
    },

    [loginReducerApi.fulfilled.type]: (state, action) => {
      state.loading = false
      state.error = ''
      if (action.payload) {
        state.token = action.payload?.data?.access_token
        setLocalStorage(StorageKeys.TOKEN, action?.payload?.data?.access_token)
      }
    },

    [loginReducerApi.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    // user info
    [userinfoReducerApi.pending.type]: (state) => {
      state.loading = true
    },

    [userinfoReducerApi.fulfilled.type]: (state, action) => {
      state.loading = false
      state.error = ''
      if (action.payload) {
        let user = { ...action.payload }
        user.create_time = Number.parseFloat(user?.create_time || 0) || null
        user.update_time = Number.parseFloat(user?.update_time || 0) || null
        state.userinfo = user
        setLocalStorage(StorageKeys.USERINFO, JSON.stringify(user))
      }
    },

    [userinfoReducerApi.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    // user change password
    [userChangePasswordApi.pending.type]: (state) => {
      state.loading = true
    },

    [userChangePasswordApi.fulfilled.type]: (state, action) => {
      state.loading = false
      state.error = ''
    },

    [userChangePasswordApi.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    // list user
    [listUsersApi.pending.type]: (state) => {
      state.loading = true
    },

    [listUsersApi.fulfilled.type]: (state, action) => {
      state.loading = false
      state.error = ''
      if (action.payload) {
        state.listUsers = action.payload
      }
    },

    [listUsersApi.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    // add user
    [addUserApi.pending.type]: (state) => {
      state.loading = true
    },

    [addUserApi.fulfilled.type]: (state) => {
      state.loading = false
      state.error = ''
    },

    [addUserApi.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    // get user by id
    [getUserByIdApi.pending.type]: (state) => {
      state.loading = true
      state.userById = null
    },

    [getUserByIdApi.fulfilled.type]: (state, action) => {
      state.loading = false
      state.error = ''
      if (action.payload) {
        state.userById = action.payload
      }
    },

    [getUserByIdApi.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
      state.userById = null
    },

    // edit user by id
    [editUserByIdApi.pending.type]: (state) => {
      state.loading = true
      state.userById = null
    },

    [editUserByIdApi.fulfilled.type]: (state, action) => {
      state.loading = false
      state.error = ''
      if (action.payload) {
        state.userById = action.payload
      }
    },

    [editUserByIdApi.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
      state.userById = null
    },

    // delete user by id
    [deleteUserByIdApi.pending.type]: (state) => {
      state.loading = true
    },

    [deleteUserByIdApi.fulfilled.type]: (state) => {
      state.loading = false
      state.error = ''
    },

    [deleteUserByIdApi.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const { updateToken, updateUserinfo, resetLoginInfo } = userSlice.actions
export const userSliceReducer = userSlice.reducer
