import { Checkbox } from "@chakra-ui/react";
import styled from "styled-components";

const CheckBoxStyled = styled(Checkbox)`
  .chakra-checkbox__control {
    width: 20px;
    height: 20px;
    border-radius: 0.35rem;

    &[data-checked] {
      /* background-image: linear-gradient(310deg, #141727, #3a416f); */
      background-color: #ffcf3e;
      border: none;
      &:hover {
        background-color: #ffcf3e;
        /* background-image: linear-gradient(310deg, #141727, #3a416f); */
      }
      svg {
        color: #000000;
      }
    }
  }
`;

export default function CheckBoxType({ ...rest }) {
  return <CheckBoxStyled {...rest} />;
}
