import END_POINT from './constants'
import http from './http'

export function login(data) {
  return http.post(`${END_POINT.LOGIN}`, data)
}

export function logout() {
  return http.get(`${END_POINT.LOGOUT}`)
}

export function postChangePassword({ data }) {
  return http.post(`${END_POINT.CHANGE_PASS}`, data)
}

export function getUserInfo() {
  return http.get(`${END_POINT.USER_PROFILE}`)
}

export function getUsers() {
  return http.get(`${END_POINT.GET_USER}`)
}

export function postUser({ data }) {
  return http.post(END_POINT.POST_USER, data)
}

export function getUserById({ id }) {
  return http.get(`${END_POINT.GET_USER_BY_ID}/${id}`)
}

export function putUser({ id, data }) {
  return http.put(`${END_POINT.PUT_USER_BY_ID}/${id}`, data)
}

export function deleteUser({ id }) {
  return http.delete(`${END_POINT.DELETE_USER_BY_ID}/${id}`)
}
