import { Spinner, VStack } from '@chakra-ui/react'

const LoadingSpinner = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minHeigth: '300px',
        height: '100%',
        zIndex: 999999999,
        background: 'white',
        opacity: '0.4',
      }}
    >
      <VStack>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='#F79325'
          size='lg'
        />
      </VStack>
    </div>
  )
}

export default LoadingSpinner
