import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { getHistoryPlayGameUser } from "api/userManagement";
import FormSelect from "components/form/FormSelect";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  GAME_MODE_OPTIONS,
  STATUS_HISTORY_PLAY_GAME_OPTIONS,
} from "views/Users/UserManagement/constant";
import HistoryPlayGameTable from "./table";

export default function HistoryPlayGame({ isOpen, onClose, user }) {
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [playHistories, setPlayHistories] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });

  const form = useForm();
  const { handleSubmit, setValue } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const newParams = {
        ...params,
        ...data,
        page: 1,
      };
      setParams(newParams);
    }
  };

  const getHistoryPlayGame = useCallback(
    async (newParams) => {
      if (user?.id) {
        try {
          setIsLoading(true);
          const { data } = await getHistoryPlayGameUser({
            user_id: user?.id,
            ...params,
            ...newParams,
          });
          if (data?.success) {
            const res = data?.data;
            setPlayHistories(res?.records);
            setTotalPage(res?.total_page);
          }
        } catch (error) {
          toast({
            description: error?.message || error?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        } finally {
          setIsLoading(false);
        }
      }
    },
    [user?.id, params]
  );

  useEffect(() => {
    getHistoryPlayGame();
  }, [getHistoryPlayGame]);

  const renderForm = () => {
    return (
      <Flex
        gap={4}
        flexWrap="wrap"
        justifyContent="space-between"
        w="100%"
        alignItems="flex-end"
      >
        <FormProvider {...form}>
          <form
            style={{
              maxWidth: "100%",
              width: "100%",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Flex w="100%" flexWrap="wrap" alignItems="flex-end" gap={4}>
              <Flex gap={8}>
                <FormSelect
                  w="260px"
                  name="game_mode"
                  label="Filter by Game mode"
                  defaultValue={""}
                  options={[{ label: "All", value: "" }, ...GAME_MODE_OPTIONS]}
                  onChange={(e) => {
                    if (!isLoading) {
                      setValue("game_mode", e.target.value);
                      handleSubmit(onSubmit)();
                    }
                  }}
                />
                <FormSelect
                  w="260px"
                  name="status"
                  label="Filter by Status"
                  defaultValue={""}
                  options={[
                    { label: "All", value: "" },
                    ...STATUS_HISTORY_PLAY_GAME_OPTIONS,
                  ]}
                  onChange={(e) => {
                    if (!isLoading) {
                      setValue("status", e.target.value);
                      handleSubmit(onSubmit)();
                    }
                  }}
                />
              </Flex>

              <HistoryPlayGameTable
                params={params}
                isLoading={isLoading}
                setParams={setParams}
                totalPage={totalPage}
                data={playHistories}
              />
            </Flex>
          </form>
        </FormProvider>
      </Flex>
    );
  };

  const holderName = (item) => {
    const address = item?.public_address
      ? `_${item?.public_address?.slice(-2)}`
      : "";
    return `player_${item?.id?.slice(-4)}${address}`;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="1519px">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >{`${user?.username || holderName(user)} - PLAY HISTORY`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px 32px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        ></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
