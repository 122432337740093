import {
  Center,
  Flex,
  Grid,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getRoles, updateRoles } from "api/role.api";
import { getRules } from "api/rule.api";
import ButtonType from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import ModalConfirm from "components/Modal/ModalConfirm";
import FormCheckbox from "components/form/FormCheckbox";
import { ROLE_OPTIONS } from "constants/constants";
import useWithToast from "hooks/useWithToast";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { convertStringSnake } from "utils/string";

export default function RoleSystemPage() {
  const [typeSubmit, setTypeSubmit] = useState("");
  const [roles, setRoles] = useState([]);
  const [rules, setRules] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { showToastSuccess } = useWithToast();

  const defaultValues = useMemo(() => {
    if (rules?.length > 0 && roles?.length > 0) {
      let list = [];
      roles?.map((role) => {
        const dataFromRule = rules?.map((rule) => {
          return {
            [`${role?.id}-${rule?.id}`]: role?.rules?.some(
              (itemRuleSome) => itemRuleSome?.id === rule?.id
            ),
          };
        });
        list = list.concat(dataFromRule);
        return dataFromRule;
      });
      if (list?.length > 0) {
        const result = list.reduce((acc, obj) => {
          return { ...acc, ...obj };
        }, {});

        return result;
      }
    }
  }, [rules, roles]);

  const form = useForm({
    defaultValues,
  });

  const {
    getValues,
    reset,
    formState: { isDirty },
  } = form;

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  const textColor = useColorModeValue("#000000", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getRulesAndRoles = async () => {
    try {
      setIsLoading(true);
      const [dataRule, dataRole] = await Promise.all([getRules(), getRoles()]);
      if (dataRule?.data && dataRule?.data?.data?.length > 0) {
        setRules(dataRule.data?.data);
      }
      if (dataRole?.data && dataRole?.data?.data?.records?.length > 0) {
        setRoles(dataRole.data?.data?.records);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    getRulesAndRoles();
  }, []);

  const updateRole = (item) => {
    const data = {
      id: item?.id,
      name: item?.name,
      order_by: item?.order_by,
      rules: item?.rules?.map((item) => ({
        id: item,
      })),
    };
    return updateRoles(data);
  };

  const handleSubmitModal = useCallback(async () => {
    if (typeSubmit == "save") {
      setIsSubmitting(true);
      const data = getValues();
      if (roles?.length > 0) {
        const result = roles?.map((role) => {
          let resultRole = { ...role };
          let listRule = [];
          for (const [key, value] of Object.entries(data)) {
            const roleId = key?.split("-")?.[0];
            if (String(role?.id) === String(roleId) && value) {
              const ruleId = key?.split("-")?.[1];
              listRule.push(ruleId);
            }
          }
          return { ...resultRole, rules: listRule };
        });
        try {
          if (result?.length > 0) {
            const promise = result?.map((item) => {
              return updateRole(item);
            });
            const res = await Promise.all(promise);
            reset(data);
            if (res) {
              getRulesAndRoles();
              showToastSuccess({
                title: "Role saved successfully!",
              });
            }
          }
        } catch (error) {
          toast({
            description: error?.message || error?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        } finally {
          setIsSubmitting(false);
        }
      }
    } else {
      reset({ ...defaultValues });
    }
    onClose();
  }, [getValues, typeSubmit, roles]);

  const handleOpenModal = (type = "save") => {
    setTypeSubmit(type);
    onOpen();
  };

  const ItemRule = ({ item, lenRoles }) => {
    return (
      <>
        <Text
          key={item?.id}
          color={textColor}
          fontWeight="600"
          fontSize="14px"
          minW="150px"
        >
          {convertStringSnake(item?.name)}
        </Text>
        {lenRoles > 0 &&
          Array.from(Array(lenRoles).keys())?.map((_, index) => {
            return (
              <FormCheckbox
                key={index}
                name={`${roles[index]?.id}-${item?.id}`}
              />
            );
          })}
      </>
    );
  };

  const renderListRules = useCallback(() => {
    const lenRoles = roles?.length;
    return (
      <>
        {rules?.map((item) => {
          return <ItemRule key={item?.id} item={item} lenRoles={lenRoles} />;
        })}
      </>
    );
  }, [roles, rules]);

  const renderForm = () => {
    return (
      <FormProvider {...form} w="100%">
        <form
          id="role-manager-form-id"
          style={{
            width: "100%",
            minWidth: "600px",
            overflowX: "auto",
          }}
        >
          <Grid
            gap="20px"
            w="100%"
            templateColumns={`repeat(${roles?.length + 1}, 1fr)`}
          >
            <Text>&nbsp;</Text>
            {roles?.map((item) => {
              return (
                <Text
                  key={item?.id}
                  color={textColor}
                  fontWeight="600"
                  fontSize="14px"
                >
                  {ROLE_OPTIONS[item?.name]}
                </Text>
              );
            })}
            {renderListRules()}
          </Grid>
          <Flex mt="24px" gap="16px" pl="2px" pb="2px">
            <ButtonType
              fontSize="16px"
              fontWeight="500"
              w="140px"
              h="56px"
              btnType="primary-new-outline"
              disabled={!isDirty}
              onClick={() => {
                handleOpenModal("reset");
              }}
            >
              Reset
            </ButtonType>
            <ButtonType
              fontSize="16px"
              fontWeight="500"
              w="140px"
              h="56px"
              btnType="primary-new"
              disabled={!isDirty}
              onClick={() => {
                handleOpenModal("save");
              }}
            >
              Save
            </ButtonType>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        <CardHeader>
          <Flex
            w="100%"
            gap={4}
            direction={{
              base: "column",
              md: "row",
            }}
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignItems="flex-start"
          >
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Setting Permissions
            </Text>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto" pt="24px">
          {isLoading ? (
            <Center minH="200px" m="auto">
              <LoadingSpinner />
            </Center>
          ) : (
            renderForm()
          )}
        </CardBody>
      </Card>
      <ModalConfirm
        isOpen={isOpen}
        onClose={onClose}
        title={typeSubmit === "save" ? "Save Role" : "Reset Role"}
        description={
          typeSubmit === "save"
            ? "Are you sure you want to save these roles?"
            : "Are you sure you want to reset this role?"
        }
        isLoadingSubmit={isSubmitting}
        onSubmit={handleSubmitModal}
      />
    </Flex>
  );
}
