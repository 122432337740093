/* eslint-disable no-undef */
import * as yup from 'yup'
import i18next from 'I18n'

const schemaPrize = () =>
  yup.object().shape({
    code: yup.string().trim().required("Required"),
    title: yup.string().trim().required("Required"),
    start_time: yup.string().trim().required("Required"),
    end_time: yup.string().trim().required("Required"),
  })

export default schemaPrize
