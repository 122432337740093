import { containSpecialRegex } from "utils/regex";
import * as yup from "yup";

const schemaMachine = () =>
  yup.object().shape({
    mode_name: yup
      .string()
      .trim()
      .matches(
        containSpecialRegex,
        "Mode name should not contain special characters"
      )
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    mode_type: yup.string().trim().required("Required"),
    play_fee_id: yup.string().trim().required("Required"),
    machine_type: yup.string().trim().required("Required"),
    gameplay_type_id: yup.string().trim().required("Required"),
    prize_type: yup.string().trim().required("Required"),
    life_gauge_consumption: yup
      .number()
      .min(0, "Must be greater than or equal to 0")
      .nullable(true)
      .transform((_, val) => {
        if (val || val === 0) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer")
      .required("Required"),
    win: yup
      .number()
      .nullable(true)
      .transform((_, val) => {
        if (val || val === 0) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .required("Required")
      .positive("Must be greater than 0"),
    lose: yup
      .number()
      .nullable(true)
      .transform((_, val) => {
        if (val || val === 0) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .required("Required")
      .positive("Must be greater than 0"),
    level_min: yup.string().trim(),
    level_max: yup
      .string()
      .trim()
      .test(
        "is-greater-than-minValue",
        "The maxi value must be greater than the min value",
        function (value) {
          if (value) {
            const { level_min } = this.parent;
            const valueMax = value?.split("_")?.[1]
              ? +value?.split("_")?.[1]
              : -1;
            const valueMin = level_min?.split("_")?.[1]
              ? +level_min?.split("_")?.[1]
              : -2;
            return valueMax > valueMin;
          }
          return true;
        }
      ),
    skill_rank_min: yup.string().trim(),
    skill_rank_max: yup.string().trim(),
  });

export default schemaMachine;
