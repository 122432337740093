import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getGameMode = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.GAME_MODE}?${params}`);
};

export const getDetailGameMode = (id) => {
  return http.get(`${END_POINT.GAME_MODE}/${id}`);
};

export const createGameMode = (data) => {
  return http.post(`${END_POINT.GAME_MODE}`, data);
};

export const updateGameMode = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.GAME_MODE}/${idEdit}`, data, option);
};

export const deleteGameMode = (idEdit) => {
  return http.delete(`${END_POINT.GAME_MODE}/${idEdit}`);
};
