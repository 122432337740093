import styled from 'styled-components';

export const BoldTextWhiteWithShadow = styled.span`
  font-size: ${props => `${`${props.size}`}px`};
  font-weight: 900;
  color: #ffff;
  text-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 2px;
  @supports (-webkit-text-stroke: 2px #000000) {
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #000000;
    paint-order: stroke fill;
  }
`;
