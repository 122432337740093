/* eslint-disable react/prop-types */
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import { useController, useFormContext } from "react-hook-form";

const FormSlider = ({
  t,
  label,
  name,
  rules,
  defaultValue = "",
  wrapperProps,
  options = [],
  isRequired,
  lenSplitLabel,
  isHiddenOption = false,
  ...rest
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  return (
    <FormControl
      maxW="100%"
      w="auto"
      isInvalid={error?.message}
      {...wrapperProps}
    >
      {label && (
        <FormLabel whiteSpace="nowrap" color="#000000">
          {label} {isRequired && <span style={{ color: "red" }}>*</span>}
        </FormLabel>
      )}
      <Slider
        onChange={onChange}
        value={value}
        aria-label="slider-ex-1"
        {...rest}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
      {error?.message && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  );
};

export default FormSlider;
