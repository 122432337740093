import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import SelectPrizeRow from "./row";

export default function SelectPrizeTable({
  isLoading,
  totalPage,
  page,
  limit,
  data = [],
  fontSize = "sm",
  setCheckedIds,
  checkedIds = [],
  onChangeChecked,
  onChangeLimit,
  handleChangePage,
  ...rest
}) {
  const labels = [
    "Prize Name",
    "Prize Type",
    "Play fee",
    // "Estimated Value"
  ];

  return (
    <Card>
      <CardBody
        maxW={{
          base: "100%",
          lg: "80%",
        }}
        m="auto"
        minH="200px"
        display="flex"
        flexDirection="column"
        {...rest}
      >
        <TableCustom
          labels={labels}
          isLoading={isLoading}
          isCheck
          isChecked={data?.length > 0 && checkedIds.length === data?.length}
          onChangeChecked={onChangeChecked}
          dataRow={data}
        >
          <>
            {data?.map((item) => {
              return (
                <SelectPrizeRow
                  key={item?.id}
                  item={item}
                  fontSize={fontSize}
                  setCheckedIds={setCheckedIds}
                  checkedIds={checkedIds}
                />
              );
            })}
          </>
        </TableCustom>
        <Paginate
          page={page}
          pageCount={totalPage}
          limit={limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </CardBody>
    </Card>
  );
}
