import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import PrizeHistoryTable from "./table";

export default function EventPrize({ isOpen, onClose, data }) {
  const renderForm = () => {
    return (
      <Flex
        gap={4}
        flexWrap="wrap"
        justifyContent="space-between"
        w="100%"
        alignItems="flex-end"
      >
        <PrizeHistoryTable isShowPagination={false} data={data} />
      </Flex>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="1389px">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        >{`Event Prize`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px 32px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        ></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
