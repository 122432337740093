import {
  Box,
  Flex,
  Grid,
  Icon,
  Input,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { getExchangeRateCurrency, updateRateApi, updateExchangeRate } from "api/rate.api";
import ButtonType from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { BCoinIcon } from "components/Icons/currencies/BCoinIcon";
import { SwitchIcon } from "components/Icons/SwitchIcon";
import { SphereCoinIcon } from "components/Icons/currencies/SpheraCoinIcon";
import { USDIcon } from "components/Icons/currencies/USDIcon";
import { YenIcon } from "components/Icons/currencies/YenIcon";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import FormInput from "components/form/FormInput";
import FormTextarea from "components/form/FormTextAria";
import useWithToast from "hooks/useWithToast";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ratesSchema from "./schema";
import { Select } from 'chakra-react-select';
import CheckBoxType from "components/Checkbox";
import ModalConfirm from "components/Modal/ModalConfirm";

export default function ExchangeRateSetting() {
  const [isLoading, setIsLoading] = useState(false);
  const [listExchangeRate, setListExchangeRate] = useState([])
  const [valueFrom, setValueFrom] = useState();
  const [valueTo, setValueTo] = useState();
  
  const defaultValues = {
    wayExchange: false,
    rate: null,
    description: "",
  };
  const form = useForm({
    resolver: yupResolver(ratesSchema()),
    defaultValues,
  });

  const {
    isOpen: isOpenExchangeRate,
    onOpen: onOpenExchangeRate,
    onClose: onCloseExchangeRate,
  } = useDisclosure();

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = form;
  const toast = useToast();
  const { showToastSuccess } = useWithToast();
  const textColor = useColorModeValue("#000000", "white");

  const getListExchangeRate = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await getExchangeRateCurrency();
      if (data?.success) {
        const listRate = data?.data
          ?.map((item) => ({
            label: item?.currency_name,
            value: item?.currency_id,
            type: item?.currency_type
          }));
        setListExchangeRate(listRate)
        setValueFrom(listRate[0])
        setValueTo(listRate[1])
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getListExchangeRate();
  }, [getListExchangeRate]);

  const getFraction = (decimal) => {
    for(var denominator = 1; (decimal * denominator) % 1 !== 0; denominator++);
    return {numerator: decimal * denominator, denominator: denominator};
  }

  const handleExchange = async (data) => {
    onOpenExchangeRate()
  }

  const onSubmit = async (data) => {
    const frac = getFraction(data?.rate);
    const params = {
      "first_currency": valueTo.value,
      "first_currency_type": valueTo.type,
      "second_currency": valueFrom.value,
      "second_currency_type": valueFrom.type,
      "numerator": frac.numerator,
      "denominator": frac.denominator,
      "description": data.description,
      "exchange_type": "SYSTEM_EXCHANGE",
      "is_two_way_exchange": data.wayExchange
    }
    try {
      const res = await updateExchangeRate(params)
      if (res?.status === 200) {
        showToastSuccess({
          title: "Save exchange rate successfully",
        });
      } else {
        toast({
          description: res?.data?.message || res?.data?.messages[0],
          status: 'error',
          position: 'bottom-right',
        })
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleSwitch = () => {
    setValueFrom(valueTo)
    setValueTo(valueFrom)
  }

  const renderBody = () => {
    return (
      <Box mt="24px">
        <FormProvider {...form}>
          <form id="form-exchange-rate" onSubmit={handleSubmit(onSubmit)}>
            <Flex
              alignItems="flex-start"
              justifyContent="flex-start"
              direction="column"
              gap="24px"
            >
              {isLoading ? (
                <Box w="100%" h="100%">
                  <LoadingSpinner />
                </Box>
              ) : (
                <>
                  <Flex
                    w='100%'
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                    gap="24px"
                  >
                    <Flex
                      w='100%'
                      padding="16px"
                      alignItems="center"
                      justifyContent="space-between"
                      border="1.5px solid #DBDBDB"
                      borderRadius="8px"
                    >
                      <Text
                        fontSize="12px"
                        fontWeight="400"
                        color={textColor}
                        padding="13.5px 0 13.5px 8px"
                        borderLeft="4px solid #FFCF3E"
                      >
                        From
                      </Text>
                      <Box
                        w="250px"
                        color={textColor}
                        padding="13.5px 8px 13.5px 0"
                        borderRight="4px solid #FFCF3E"
                      >
                        <Select
                          useBasicStyles
                          variant='unstyled'
                          options={listExchangeRate?.filter(rate => rate.value !== valueTo.value)}
                          closeMenuOnSelect={true}
                          value={valueFrom}
                          getOptionLabel={(option) => (
                            <Box display="flex" alignItems="center">
                              {/* <Icon w="30px" h="30px" as={option.iconCoin} alt="coin" /> */}
                              <Text ml={2}>{option.label}</Text>
                            </Box>
                          )}
                          getOptionValue={(option) => option.value}
                          onChange={(e) => setValueFrom(e)}
                        />
                      </Box>
                    </Flex>
                    <Icon 
                      w="24px" 
                      h="24px" 
                      as={SwitchIcon} 
                      alt="switch" 
                      cursor="pointer"
                      onClick={handleSwitch}
                    />
                    <Flex
                      w="100%"
                      padding="16px"
                      alignItems="center"
                      justifyContent="space-between"
                      border="1.5px solid #DBDBDB"
                      borderRadius="8px"
                    >
                      <Text
                        fontSize="12px"
                        fontWeight="400"
                        color={textColor}
                        padding="13.5px 0 13.5px 8px"
                        borderLeft="4px solid #FFCF3E"
                      >
                        To
                      </Text>
                      <Box
                        w="250px"
                        color={textColor}
                        padding="13.5px 8px 13.5px 0"
                        borderRight="4px solid #FFCF3E"
                      >
                        <Select
                          useBasicStyles
                          variant='unstyled'
                          placeholder='Male'
                          options={listExchangeRate?.filter(rate => rate.value !== valueFrom.value)}
                          closeMenuOnSelect={true}
                          value={valueTo}
                          getOptionLabel={(option) => (
                            <Box display="flex" alignItems="center">
                              {/* <Icon w="30px" h="30px" as={option.iconCoin} alt="coin" /> */}
                              <Text ml={2}>{option.label}</Text>
                            </Box>
                          )}
                          getOptionValue={(option) => option.value}
                          onChange={(e) => setValueTo(e)}
                        />
                      </Box>
                    </Flex>
                  </Flex>
                  <Grid w='100%' templateColumns="repeat(1, 1fr)" gap={4}>
                    <FormInput type="number" label="Rate" name="rate" placeholder="Enter rate"/>
                  </Grid>
                  <Grid w='100%' templateColumns="repeat(1, 1fr)" gap={4}>
                    <FormTextarea
                      placeholder='Enter description'
                      minH='105px'
                      borderRadius='8px'
                      label="Description"
                      name="description"
                    />
                  </Grid>
                  <Flex
                    alignItems="center"
                    gap="6px"
                  >
                    <CheckBoxType
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setValue("wayExchange", isChecked)
                      }}
                    />
                    <Text
                      color={textColor}
                      fontSize="14"
                      fontWeight="400"
                      whiteSpace="nowrap"
                    >
                      Two-way exchange
                    </Text>
                  </Flex>
                </>
              )}

              <ButtonType
                w={{
                  base: "236px",
                  xl1: "281px",
                }}
                h="46px"
                type="submit"
                isLoading={isSubmitting}
              >
                Save
              </ButtonType>
            </Flex>
          </form>
        </FormProvider>
      </Box>
    );
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex
            w="100%"
            gap={4}
            direction={{
              base: "column",
              md: "row",
            }}
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignItems="flex-start"
          >
            <Text
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              whiteSpace="nowrap"
            >
              Exchange Rate Settings
            </Text>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto" p="24px" w="100%">
          <Box
            w="100%"
            p="24px 16px"
            border="1px solid #DBDBDB"
            borderRadius="16px"
          >
            <Text
              fontSize="18px"
              fontWeight="700"
              color={textColor}
              textAlign="center"
            >
              Exchange
              {renderBody()}
            </Text>
          </Box>
        </CardBody>
      </Card>
      <ModalConfirm
        isOpen={isOpenExchangeRate}
        onClose={onCloseExchangeRate}
        onSubmit={handleExchange}
        title="Save Exchange Rate "
        description={"Are you sure you want to save this exchange rate?"}
      />
    </Flex>
  );
}
